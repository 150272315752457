import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
// import * as io from 'socket.io-client';
import { io } from "socket.io-client";
import { PharmacyService } from "../pharmacy.service";
import { Gallery } from "angular-gallery";
import { GlobalConstants } from "src/app/common/global-constants";
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective,
} from "ngx-perfect-scrollbar";
import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  Timestamp,
} from "rxjs";
import { SharedServiceService } from "src/app/common/shared-service.service";
import Swal from "sweetalert2";
import { LanguagePharmacy } from "../interface-pharmacy/language-item-pharmacy";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-pharmacy-chat",
  templateUrl: "./pharmacy-chat.component.html",
  styleUrls: ["./pharmacy-chat.component.scss"],
})
export class PharmacyChatComponent implements OnInit {
  private socket: any;
  private socket_: any;
  searchText;
  hideRequiredControl = new FormControl(false);
  idPharmacy: string;
  namePharmacy: string;
  imgPharmacy: string;
  dataAppointmentsHistory: any = [];
  dataAppointmentsHistoryBackup: any = [];
  chatID: any;
  clickChat: boolean = false;
  ShowChat: boolean = false;
  chartForm: FormGroup;
  userId: any;
  userName: any;
  userImg: any;
  newMessageCount: any;
  sortedbackup: any;
  messageZero: boolean = false;
  chatStatus: boolean = false;

  chatIDbackup: string = "";
  chatIDbackup_: string = "";

  languagePharmacy: any;
  datatranslate: any;

  mymsg: any;

  dataHistoryChats: any = [];

  loadChat: boolean = false;
  timestampTop: string = "";

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  noImg: boolean = true;
  checkIsSelectImg: boolean = false;
  LoadingChatTOPhamacy: boolean = true;
  img: any = [];

  url = GlobalConstants.REST_API_TELE;
  urlPath2021 = GlobalConstants.URL_PART_2021;

  checkPageCurrent: boolean;

  constructor(
    private sharedServiceService: SharedServiceService,
    private gallery: Gallery,
    private fb: FormBuilder,
    private pharmacyService: PharmacyService,
    public translate: TranslateService
  ) {
    this.checkPageCurrent = true;
    var dataLocal = localStorage.getItem("currentUser");

    if (dataLocal != null) {
      var data_ = JSON.parse(dataLocal);
      this.idPharmacy = data_.id;
      this.namePharmacy = data_.username;
      this.imgPharmacy = data_.img;
    }
    this.chartForm = this.createContactForm();
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
    });
    this.loadUserChat();
    this.notiChatPharmacy();
  }

  createContactForm(): FormGroup {
    return this.fb.group({
      textChat: [""],
    });
  }

  onEnter() {
    this.sendMsgbtn();
  }

  async sendMsgbtn() {
    var type: string = "";
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append("FormFiles", this.selectedFiles[i]);
        formData.append("TypeUpload", "chatPhamacy");
        formData.append("ChatID", this.chatID);

        await this.pharmacyService
          .UploadIMGChatPharmacy_(formData)
          .then((resUploadIMG) => {
            this.img.push(resUploadIMG);
          });
      }
      type = "Images";
    }
    if (this.img.length <= 0) {
      this.addThing("", "");
    } else {
      this.addThing(this.img, type);
    }
    //เคลียร พรีวิว img
    this.onClickClearImg();
  }

  private addThing(imgPath: string, type: string) {
    if (this.chartForm.getRawValue().textChat == "") {
      if (imgPath != "") {
      } else if (imgPath == "") {
        return;
      } else {
        return;
      }
      //  return;
    }
    var jsonData = {
      chat_id: this.chatID,
      media_path: imgPath,
      media_type: type,
      profile_img_path: this.imgPharmacy,
      msg: this.chartForm.getRawValue().textChat,
      sender_id: this.idPharmacy.toString(),
      sender_name: this.namePharmacy,
      receiver_id: this.userId,
      timestamp: new Date(),
      status: "unread",
    };
    if (jsonData.msg != null || jsonData.media_path != null) {
      this.dataHistoryChats.concat(jsonData);
      var newChat = JSON.stringify(jsonData);
      this.socket.emit(this.chatID, newChat);
      this.chartForm = this.createContactForm();
      this.selectedFiles = null;
      this.getDataChat();
      this.loadUserChat();
    } else {
      return;
    }
    this.getDataChat();
    // var indexHis = this.dataAppointmentsHistory.findIndex((s: { sender_id: any; }) => s.sender_id == jsonData.receiver_id);
    // console.log("ssssssss -- ",indexHis);
    // console.log("sssssaaa -- ",this.dataAppointmentsHistory[indexHis]);

    // if (jsonData.media_type == "Images") {
    //   this.dataAppointmentsHistory[indexHis].lastHistory.last_msg = `${jsonData.msg},Images`;
    // } else if (jsonData.media_type == "Sticker") {
    //   this.dataAppointmentsHistory[indexHis].lastHistory.last_msg = `${jsonData.msg},Sticker`;
    // } else if (jsonData.media_type == "Video") {
    //   this.dataAppointmentsHistory[indexHis].lastHistory.last_msg = `${jsonData.msg},Video`;
    // } else if (jsonData.media_type == "File") {
    //   this.dataAppointmentsHistory[indexHis].lastHistory.last_msg = `${jsonData.msg},File`;
    // } else if (jsonData.media_type == "Audio") {
    //   this.dataAppointmentsHistory[indexHis].lastHistory.last_msg = `${jsonData.msg},Audio`;
    // } else {
    //   this.dataAppointmentsHistory[indexHis].lastHistory.last_msg = jsonData.msg;
    // }

    setTimeout(() => {
      this.scrollToBottom1();
    }, 500);
  }

  loadUserChat() {
    var dataGetLastHistory: any;
    var dataChatHistory: any = [];
    var countMSG = 0;
    this.pharmacyService
      .GetLastHistoryChatlist(this.idPharmacy)
      .then((resGetLastHistoryChatlist) => {
        dataGetLastHistory = resGetLastHistoryChatlist;

        for (let i = 0; i < dataGetLastHistory.length; i++) {
          if (dataGetLastHistory[i].amount_new_msg > 0) {
            countMSG++;
          }
          if (i == dataGetLastHistory.length - 1) {
            this.sharedServiceService.updateCountChat(countMSG);
          }
        }

        dataGetLastHistory.sort(function (a: any, b: any) {
          return +new Date(b.timestamp) - +new Date(a.timestamp);
        });
        var dataIdUser = [];
        if (dataGetLastHistory.length > 0) {
          for (let i = 0; i < dataGetLastHistory.length; i++) {
            dataIdUser.push(dataGetLastHistory[i].sender_id);
          }
          var dataIdUser_ = {
            iduser: dataIdUser,
          };
          let dataUserList: any = [];
          this.pharmacyService.GetUserList_(dataIdUser_).then((resUserList) => {
            dataUserList = resUserList;
            if (dataUserList.length > 0) {
              for (let i = 0; i < dataGetLastHistory.length; i++) {
                for (let j = 0; j < dataUserList.length; j++) {
                  if (
                    dataUserList[j].dataUser.user_ID ==
                    dataGetLastHistory[i].sender_id
                  ) {
                    dataChatHistory.push({
                      sender_id: dataGetLastHistory[i].sender_id,
                      lastHistory: dataGetLastHistory[i],
                      new_msg: dataGetLastHistory[i].amount_new_msg,
                      time: dataGetLastHistory[i].timestamp,
                      dataUser: dataUserList[j].dataUser,
                    });
                    break;
                  }
                }
                if (i == dataGetLastHistory.length - 1) {
                  this.dataAppointmentsHistory = dataChatHistory;
                  setTimeout(() => {
                    this.LoadingChatTOPhamacy = false;
                  }, 500);
                }
              }
            }
          });
        }
      });
  }

  onClickChatUser(
    idUser: string,
    imgUser: string,
    nameUser: string,
    newMsg: Number
  ): void {
    //     console.log("idUser", idUser, "imgUser", imgUser, "nameUser", nameUser, "newMsg", newMsg);
    this.socket_ = io(`${GlobalConstants.REST_API_CHAT}`, {
      transports: ["websocket", "polling"],
      withCredentials: true,
    });
    var index = this.dataAppointmentsHistory.findIndex(
      (d: { sender_id: any }) => d.sender_id == idUser
    );
    if (this.userId != idUser) {
      if (this.dataAppointmentsHistory[index].new_msg != 0) {
        this.dataAppointmentsHistory[index].new_msg = 0;
        this.notiChatPharmacy();
      } else {
        this.chatStatus = false;
        this.notiChatPharmacy();
      }

      this.clickChat = true;
      this.chatID = idUser + "|PHARMACY" + this.idPharmacy;
      this.userId = idUser;
      this.userName = nameUser;
      this.userImg = imgUser;
      this.newMessageCount = newMsg;

      this.chatIDbackup_ = idUser;

      this.socket_.emit("ClickRead-" + this.chatID, idUser);
      this.chartForm.reset();
      this.selectedFiles = null;

      this.messageShowChat(this.chatID);
      this.getDataChat();
      this.notiChatPharmacy();
      setTimeout(() => {
        this.loadCountChat();
      }, 800);
    }
  }

  loadCountChat() {
    var dataGetLastHistory: any;
    var countMSG = 0;
    this.pharmacyService
      .GetLastHistoryChatlist(this.idPharmacy)
      .then((resGetLastHistoryChatlist) => {
        dataGetLastHistory = resGetLastHistoryChatlist;
        for (let i = 0; i < dataGetLastHistory.length; i++) {
          if (dataGetLastHistory[i].amount_new_msg > 0) {
            countMSG++;
          }
          if (i == dataGetLastHistory.length - 1) {
            this.sharedServiceService.updateCountChat(countMSG);
          }
        }
      });
  }

  getDataChat() {
    var dataHistoryChat_: any;
    this.pharmacyService
      .GetHistoryChat(this.chatID, this.idPharmacy.toString(), this.userId)
      .then((resHistoryChat) => {
        // console.log("resHistoryChat ", resHistoryChat);
        dataHistoryChat_ = resHistoryChat;
        dataHistoryChat_.sort(function (a, b) {
          // convert date object into number to resolve issue in typescript
          return +new Date(a.timestamp) - +new Date(b.timestamp);
        });

        this.dataHistoryChats = dataHistoryChat_;
        // console.log("this.dataHistoryChats ", this.dataHistoryChats);
        if (this.dataHistoryChats.length > 0) {
          this.timestampTop = "";
          this.timestampTop = this.dataHistoryChats[0].timestamp;
        }
      });
    setTimeout(() => {
      this.scrollToBottom1();
    }, 500);
  }

  getDataChatOld(timestamp: string) {
    var dataOldHistoryChat_: any;
    this.pharmacyService
      .GetOldHistoryChat(this.chatID, timestamp)
      .then((resOldHistoryChat) => {
        dataOldHistoryChat_ = resOldHistoryChat;
        if (dataOldHistoryChat_.length > 0) {
          this.timestampTop = "";
          this.timestampTop =
            dataOldHistoryChat_[dataOldHistoryChat_.length - 1].timestamp;
          for (let i = 0; i < dataOldHistoryChat_.length; i++) {
            this.dataHistoryChats = [
              dataOldHistoryChat_[i],
              ...this.dataHistoryChats,
            ];
            if (i == dataOldHistoryChat_.length - 1) {
              // console.log(this.dataHistoryChats);
              if (dataOldHistoryChat_.length > 5) {
                this.scrollToXY(0, 700);
                this.config.scrollYMarginOffset = 1;
              } else {
                this.scrollToXY(0, 300);
                this.config.scrollYMarginOffset = 1;
              }
              setInterval(() => {
                this.loadChat = false;
              }, 1000);
            }
          }
        } else {
          this.loadChat = false;
        }
      });
  }

  ngOnDestroy() {
    this.checkPageCurrent = false;
  }

  messageShowChat(chatID) {
    // console.log("this.checkPageCurrent----", this.checkPageCurrent);
    this.socket = io(`${GlobalConstants.REST_API_CHAT}/`, {
      query: { chat_id: chatID },
      transports: ["websocket", "polling"],
      withCredentials: true,
    });

    this.socket.emit("Achat-Online", { user_id: "" + this.idPharmacy });

    this.socket.on("connect", () => {
      // console.log("chat_id " + chatID + " have connected");

      this.socket_.on("Notify", (mymsgNoti: any) => {
        // console.log("2222 ",this.checkPageCurrent);
        this.playAudioChat();
        if (this.checkPageCurrent) {
          this.loadUserChat();
          this.getDataChat();
        }
        this.loadUserChat();
      });

      this.socket.on(chatID, (mymsg: any) => {
        //console.log(mymsg);

        this.dataHistoryChats.push(mymsg);

        //console.log(this.dataHistoryChats[0].timestamp);

        var index = this.dataHistoryChats.findIndex(
          (d: { chat_id: any }) => d.chat_id == chatID
        );
        // console.log("> ", index);
        // console.log("> ", this.dataHistoryChats[index]);
        // console.log("this.dataAppointmentsHistory ", this.dataAppointmentsHistory);

        setTimeout(() => {
          this.scrollToBottom1();
        }, 500);

        //   this.notiChatPharmacy();
        var indexHis = this.dataAppointmentsHistory.findIndex(
          (s: { sender_id: any }) =>
            s.sender_id == this.dataHistoryChats[index].sender_id
        );
        var indexHis2 = this.dataAppointmentsHistory.findIndex(
          (s: { sender_id: any }) =>
            s.sender_id == this.dataHistoryChats[index].receiver_id
        );
        // console.log(indexHis);
        // console.log(indexHis2);
        if (indexHis != -1) {
          if (mymsg.media_type == "Images") {
            this.dataAppointmentsHistory[
              indexHis
            ].lastHistory.last_msg = `${mymsg.msg},Images`;
          } else if (mymsg.media_type == "Sticker") {
            this.dataAppointmentsHistory[
              indexHis
            ].lastHistory.last_msg = `${mymsg.msg},Sticker`;
          } else if (mymsg.media_type == "Video") {
            this.dataAppointmentsHistory[
              indexHis
            ].lastHistory.last_msg = `${mymsg.msg},Video`;
          } else if (mymsg.media_type == "File") {
            this.dataAppointmentsHistory[
              indexHis
            ].lastHistory.last_msg = `${mymsg.msg},File`;
          } else if (mymsg.media_type == "Audio") {
            this.dataAppointmentsHistory[
              indexHis
            ].lastHistory.last_msg = `${mymsg.msg},Audio`;
          } else {
            this.dataAppointmentsHistory[indexHis].lastHistory.last_msg =
              mymsg.msg;
          }
        } else {
          if (mymsg.media_type == "Images") {
            this.dataAppointmentsHistory[
              indexHis2
            ].lastHistory.last_msg = `${mymsg.msg},Images`;
          } else if (mymsg.media_type == "Sticker") {
            this.dataAppointmentsHistory[
              indexHis2
            ].lastHistory.last_msg = `${mymsg.msg},Sticker`;
          } else if (mymsg.media_type == "Video") {
            this.dataAppointmentsHistory[
              indexHis2
            ].lastHistory.last_msg = `${mymsg.msg},Video`;
          } else if (mymsg.media_type == "File") {
            this.dataAppointmentsHistory[
              indexHis2
            ].lastHistory.last_msg = `${mymsg.msg},File`;
          } else if (mymsg.media_type == "Audio") {
            this.dataAppointmentsHistory[
              indexHis2
            ].lastHistory.last_msg = `${mymsg.msg},Audio`;
          } else {
            this.dataAppointmentsHistory[indexHis2].lastHistory.last_msg =
              mymsg.msg;
          }
        }
      });
    });
  }

  notiChatPharmacy() {
    if (this.chatStatus == true) {
      return;
    } else {
      // this.loadUserChat();
      this.socket_ = io(`${GlobalConstants.REST_API_CHAT}`, {
        transports: ["websocket", "polling"],
        withCredentials: true,
      });

      this.socket_.on("connect", () => {
        // console.log("socket_ connect --");
        this.socket_.emit("Achat-Online", { user_id: "" + this.idPharmacy });
      });

      this.socket_.on("disconnect", (data) => {
        //   console.log("onDisconnect");
      });

      this.socket_.on("reconnect", (data) => {
        //   console.log("onReconnect");
        this.socket_.emit("Achat-Online", { user_id: "" + this.idPharmacy });
      });

      this.socket_.on("Notify", (mymsgNoti: any) => {
        // console.log("1111 ",this.checkPageCurrent);
        // console.log("mymsgNoti ",mymsgNoti);

        if (this.checkPageCurrent == false) {
          var msgchat: any;
          // console.log("mymsgNoti header : ",mymsgNoti);
          if (mymsgNoti.msg != "") {
            msgchat = mymsgNoti.msg;

            Swal.fire({
              toast: true,
              iconHtml: `<img src="${mymsgNoti.profile_img_path}" class="rounded-circle" width="32" height="32" alt="User"
              style="object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"> `,
              // icon:'error',
              // imageUrl: `${mymsg.profile_img_path}`,
              //instead of imageSize use imageWidth and imageHeight
              imageWidth: 35,
              html: `${mymsgNoti.msg}`,
              title: `${mymsgNoti.sender_name}`,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
          } else {
            if (mymsgNoti.media_type == "Sticker") {
              msgchat = "Sticker sent";
            } else {
              msgchat = "Photo sent";
            }
            Swal.fire({
              toast: true,
              iconHtml: `<img src="${mymsgNoti.profile_img_path}" class="rounded-circle" width="32" height="32" alt="User"
              style="object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"> `,
              // icon:'error',
              // imageUrl: `${mymsg.profile_img_path}`,
              //instead of imageSize use imageWidth and imageHeight
              imageWidth: 35,
              html: `<img src="${mymsgNoti.media_path}" class="img-circle doc-card-image"
              style="width: 32px;height: 32px;object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"> ${msgchat}`,
              title: `${mymsgNoti.sender_name}`,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
          }
          // console.log("mymsgNoti : ", mymsgNoti);
        } else {
          this.playAudioChat();
          this.loadUserChat();
          this.getDataChat();
        }
      });
    }
  }

  checkMSG(msg: string) {
    var dataSplit = msg.split(",");
    var newMsg = "";
    if (
      dataSplit[1] == "Images" ||
      dataSplit[1] == "Sticker" ||
      dataSplit[1] == "Video" ||
      dataSplit[1] == "File" ||
      dataSplit[1] == "Audio"
    ) {
      newMsg = `Send ${dataSplit[1]}`;
    } else {
      newMsg = dataSplit[0];
    }
    return newMsg;
  }

  showGallery(index: number, img: any, url: string) {
    var pathImg: any = [];
    if (url != "") {
      for (let i = 0; i < img.length; i++) {
        pathImg.push({ path: url + "/" + img[i] });
      }
      let prop = {
        images: pathImg,

        index,
      };
      this.gallery.load(prop);
    } else {
      for (let i = 0; i < img.length; i++) {
        pathImg.push({ path: img[i] });
      }
      let prop = {
        images: pathImg,

        index,
      };
      this.gallery.load(prop);
    }
  }

  @ViewChild("fileInput")
  myInputVariable: ElementRef;

  onClickClearImg() {
    this.myInputVariable.nativeElement.value = "";

    this.noImg = true;
    this.img = [];
    this.selectedFiles = null;
    this.message = [];
    this.progressInfos = [];
    this.previews = [];
    this.checkIsSelectImg = true;
  }

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    // console.log("event ",this.selectedFiles);
    if (this.selectedFiles.length != 0) {
      this.checkIsSelectImg = true;
      this.noImg = false;
      this.message = [];
      this.progressInfos = [];
      this.previews = [];
    }

    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          // console.log(e.target.result);
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  public type: string = "component";

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;

  public scrollToXY(x: number, y: number): void {
    if (this.type === "directive" && this.directiveRef) {
      this.directiveRef.scrollTo(x, y, 500);
    } else if (
      this.type === "component" &&
      this.componentRef &&
      this.componentRef.directiveRef
    ) {
      this.componentRef.directiveRef.scrollTo(x, y, 500);
    }
  }

  public scrollToBottom1(): void {
    if (this.type === "directive" && this.directiveRef) {
      this.directiveRef.scrollToBottom();
    } else if (
      this.type === "component" &&
      this.componentRef &&
      this.componentRef.directiveRef
    ) {
      this.componentRef.directiveRef.scrollToBottom();
    }
  }

  public onScrollEvent(event: any): void {
    // console.log(event);

    if (event.type == "ps-y-reach-start") {
      if (this.timestampTop != "") {
        this.loadChat = true;
        this.getDataChatOld(this.timestampTop);
      }
    }
  }

  playAudioChat() {
    let audio = new Audio();
    audio.src = "assets/sound/mixkit-correct-answer-tone-2870.wav";
    audio.load();
    audio.play();
  }
}
