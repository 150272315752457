import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DoctorCalendar } from "./doctor-calendar.model";
import { GlobalConstants } from "src/app/common/global-constants";

@Injectable({
  providedIn: "root",
})
export class DoctorCalendarService {
  //Http header
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  // httpOptions = {
  //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })

  // };

  private readonly API_URL = "assets/data/calendar.json";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  dataChange: BehaviorSubject<DoctorCalendar[]> = new BehaviorSubject<
    DoctorCalendar[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;

  private subject = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  updateRouteData(data) {
    return this.subject.next(data);
  }

  routeData(): Observable<any> {
    return this.subject.asObservable();
  }

  get data(): DoctorCalendar[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  getAllCalendars(): Observable<DoctorCalendar[]> {
    return this.httpClient
      .get<DoctorCalendar[]>(this.API_URL)
      .pipe(catchError(this.errorHandler));
  }

  addUpdateCalendar(calendar: DoctorCalendar): void {
    this.dialogData = calendar;
  }
  deleteCalendar(calendar: DoctorCalendar): void {
    this.dialogData = calendar;
  }
  errorHandler(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  GetConvosation_(drId: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConvosationById?drId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  sendDocterAccept_(
    userID: string,
    nameDoctor: string,
    starusAccept: boolean,
    dateTimeAppointment: string,
    convoId: any
  ) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/onesignal/sendDocterAccept?userID=${userID}&nameDoctor=${nameDoctor}&starusAccept=${starusAccept}&dateTimeAppointment=${dateTimeAppointment}&CONVO_Id=${convoId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateStatusAccept_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/UpdateStatusAccept`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ChangeNewAppointmentDateAfterPayment_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/ChangeNewAppointmentDateAfterPaymentV2`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // GetUserUpdateAppointmentAfterPayment_(drId: number) {
  //   return new Promise<any>((resolve, reject) => {
  //     let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetUserUpdateAppointmentAfterPayment?drId=${drId}`;
  //     this.httpClient
  //       .get(API_URL, {
  //         headers: this.httpHeaders,
  //         responseType: "json",
  //       })
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  UpdateConfirmNewAppointmentDateAfterPayment_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/UpdateConfirmNewAppointmentDateAfterPayment`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDoctorUpdateAppointmentAfterPaymentByDoctor_(drId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetDoctorUpdateAppointmentAfterPaymentByDoctor?drId=${drId}`;
      this.httpClient
        .get(API_URL, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  CheckNewAppointmentAfterPaymentTimeOut_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/CheckNewAppointmentAfterPaymentTimeOut`;
      this.httpClient
        .get(API_URL, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

}