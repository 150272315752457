import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "../interface-pharmacy/language-item-pharmacy";

@Component({
  selector: "app-package",
  templateUrl: "./package.component.html",
  styleUrls: ["./package.component.scss"],
})
export class PackageComponent implements OnInit {
  languagePharmacy: any;

  constructor(
    private translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {}

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
  }
}
