import { formatDate } from "@angular/common";
export class BankPhar {
  AccountName: string;
  bankNo: string;

  constructor(banks) {
    {
      this.AccountName = banks.AccountName || "";
      this.bankNo = banks.bankNo || "";
    }
  }
}
