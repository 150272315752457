import { formatDate } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UpdateBankDialogComponent } from "src/app/admin/doctors/doctor-profile/update-bank-dialog/update-bank-dialog.component";
import { LanguageAdmin } from "src/app/admin/interface-admin/language-item-admin";
import { GlobalConstants } from "src/app/common/global-constants";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { Pharmacy } from "src/app/pharmacy/pharmacy.model";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import Swal from "sweetalert2";
import { BankPhar } from "./bank-phar.model";

interface Province {
  value: string;
  viewValue: string;
}

interface District {
  value: string;
  viewValue: string;
}
interface SubDistrict {
  value: string;
  viewValue: string;
}
interface Bank {
  value: string;
  viewValue: string;
  viewImg: string;
}
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent {
  urlPath = GlobalConstants.REST_API_TELE;
  languageItemsAdminAllPharmacy: any;
  bankPhar: BankPhar;
  dialogTitle: string;
  pharmacyForm: FormGroup;
  bankPharForm: FormGroup;
  pharmacys: Pharmacy;
  datetimeC: string;
  fileData!: File;
  noImg: boolean = true;
  previewUrl: any = null;
  province!: string;
  district!: string;
  subDistrict!: string;
  provinces: Province[] = [];
  districts: District[] = [];
  subdistricts: SubDistrict[] = [];
  dataProvincePharmacys: any;
  dataDistrictPharmacys: any;
  dataSubDistrictPharmacys: any;
  hidePassword1 = true;
  hidePassword2 = true;
  datapharmacys: any;

  duplicatePassword = false;
  duplicateEmail = false;
  duplicateUsername = false;

  provinceID: string = "";
  idDoctor: string;
  idPharmacy2: string;
  districtID: string = "";
  subdistrictID: string = "";
  selectedProvince!: number;
  selectedDistrict!: number;
  selectedSubdistrict!: number;
  imgPharmacy: string = "";
  idPharmacy: string;
  dataDrugList = [];
  filteredData = [];
  dataLoading: boolean = true;

  selectedbankNamedoc = "bank";
  cardId: number = 0;
  cardIdNameBank: string = "Name Lastname";
  bankNamedoc: string = "";
  AccountNamedoc: string;
  bankNodoc: string;
  bankFromTypedoc: string = "1";
  isCheckBankName: boolean = true;
  isBtnAddBank: boolean = true;
  dataBankByIdPharAll: any;
  dataBank = [];
  getOrderShowAccount: any;
  sumGetOrderShowAccount = 0;
  dataDrugShowAccount: any = [];
  showImgProduct: any;
  imageObject = [];
  // idPharmacy: string;
  dataLocalUser: any;
  roleAdmin: string;

  countOrderAll: number = 0;
  transLang: any;
  adminRole: string;

  constructor(
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService,
    public dialog: MatDialog,
    public pharmacysService: PharmacyService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.idPharmacy = this.activatedRoute.snapshot.paramMap.get("idPhamacy");
    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);
    this.dataLocalUser = dataLocalJSON;
    this.roleAdmin = dataLocalJSON.role;

    this.bankPhar = new BankPhar({});

    this.pharmacys = new Pharmacy({});

    this.pharmacyForm = this.createContactForm();
    this.bankPharForm = this.createContactFormbank();

    this.translate.use(localStorage.getItem("lang"));
    this.adminRole = JSON.parse(localStorage.getItem("currentUser")).role;
  }

  async ngOnInit() {
    this.languageItemsAdminAllPharmacy = LanguageAdmin[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.transLang = localStorage.getItem("lang");
    });
    await this.loadDataPharmacy(this.idPharmacy);
    this.loadDataBank();
    this.loadDataOrderDrugList(this.idPharmacy);
    this.loadDataDrug(this.idPharmacy);
    this.loadDataTotalIncomeAll("all", this.idPharmacy);

    if (this.dataLocalUser.role != "Admin") {
      this.pharmacyForm.get("UserName").disable();
    }
  }
  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError("required")
      ? "Required field"
      : this.formControl.hasError("email")
        ? "Not a valid email"
        : "";
  }

  createContactFormbank(): FormGroup {
    return this.fb.group({
      AccountName: [this.bankPhar.AccountName],
      bankNo: [this.bankPhar.bankNo],
    });
  }

  createContactForm(): FormGroup {
    return this.fb.group({
      img: [this.pharmacys.phaR_Image],
      Name: [this.pharmacys.phaR_Name],
      UserName: [this.pharmacys.phaR_Username],
      Email: [this.pharmacys.phaR_Email],
      Password: [""],
      Password2: [""],
      Phone: [this.pharmacys.phaR_Phone],
      AboutPhamacy: [this.pharmacys.phaR_AboutPhamacy],
      ChatPerTime: [this.pharmacys.phaR_ChatPerTime],
      VideoPerTime: [this.pharmacys.phaR_VideoPerTime],
      Address: [this.pharmacys.phaR_Address],
      Province: [this.pharmacys.phaR_Province],
      District: [this.pharmacys.phaR_District],
      subDistrict: [this.pharmacys.phaR_Subdistrict],
      Zipcode: [this.pharmacys.phaR_Postalcode],
    });
  }
  submit() {
    // emppty stuff
  }
  // handleClear(): void {
  //   this.dialogRef.close();
  // }
  onNoClick(): void {
    // this.dialogRef.close();
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  banks: Bank[] = [
    {
      value: "ธนาคารกรุงเทพ",
      viewValue: "ธนาคารกรุงเทพ จำกัด (มหาชน)",
      viewImg: "assets/images/bank/bk.jpg",
    },
    {
      value: "ธนาคารกรุงไทย",
      viewValue: "ธนาคารกรุงไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/ktb-bank-logo-big.jpg",
    },
    {
      value: "ธนาคารกรุงศรีอยุธยา",
      viewValue: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
      viewImg: "assets/images/bank/ks.png",
    },
    {
      value: "ธนาคารกสิกรไทย",
      viewValue: "ธนาคารกสิกรไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/logo-kbank.jpg",
    },
    {
      value: "ธนาคารเกียรตินาคิน",
      viewValue: "ธนาคารเกียรตินาคิน จำกัด (มหาชน)",
      viewImg: "assets/images/bank/kkn.png",
    },
    {
      value: "ธนาคารซีไอเอ็มบี",
      viewValue: "ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/cmb.png",
    },
    {
      value: "ธนาคารทหารไทย",
      viewValue: "ธนาคารทหารไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/tmb_logo.jpg",
    },
    {
      value: "ธนาคารทิสโก้",
      viewValue: "ธนาคารทิสโก้ จำกัด (มหาชน)",
      viewImg: "assets/images/bank/tisco.jpg",
    },
    {
      value: "ธนาคารไทยพาณิชย์",
      viewValue: "ธนาคารไทยพาณิชย์ จำกัด (มหาชน)",
      viewImg: "assets/images/bank/scb.jpg",
    },
    {
      value: "ธนาคารธนชาต",
      viewValue: "ธนาคารธนชาต จำกัด (มหาชน)",
      viewImg: "assets/images/bank/tnc.jpg",
    },
    {
      value: "ธนาคารนครหลวงไทย",
      viewValue: "ธนาคารนครหลวงไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/nkl.jpg",
    },
    {
      value: "ธนาคารยูโอบี",
      viewValue: "ธนาคารยูโอบี จำกัด (มหาชน)",
      viewImg: "assets/images/bank/uob.jpg",
    },
    {
      value: "ธนาคารสแตนดาร์ดชาร์เตอร์ด",
      viewValue: "ธนาคารสแตนดาร์ดชาร์เตอร์ด จำกัด (มหาชน)",
      viewImg: "assets/images/bank/std.png",
    },
    {
      value: "ธนาคารไอซีบีซี",
      viewValue: "ธนาคารไอซีบีซี จำกัด (มหาชน)",
      viewImg: "assets/images/bank/icbc.png",
    },
    {
      value: "ธนาคารออมสิน",
      viewValue: "ธนาคารออมสิน จำกัด (มหาชน)",
      viewImg: "assets/images/bank/download.png",
    },
  ];

  loadDataDrug(idPharmacy: string) {
    var dataDrug: any;
    this.pharmacysService.GetDrugList_(idPharmacy).then((resDrugSkill) => {
      dataDrug = resDrugSkill;
      this.dataDrugShowAccount = dataDrug;
      // var imgSild:any=[];
      for (let i = 0; i < dataDrug.length; i++) {
        if (dataDrug[i].medcImage != "") {
          var imgDrugLists = dataDrug[i].medcImage.split(",");
          var ImgProduct = imgDrugLists[0];
          var nameProduct = dataDrug[i].medcName;
          this.imageObject.push({
            thumbImage: this.urlPath + "/" + ImgProduct,
            title: nameProduct,
          });
        }
      }
    });
  }

  accId: boolean = false;
  accName: boolean = false;

  onSearchChangeCard(idValue: number): void {
    this.cardId = idValue;
    if (this.cardId.toString().length >= 16) {
      this.accId = true;
    } else {
      this.accId = false;
    }
  }
  onSearchChangeName(idValueName: string): void {
    this.cardIdNameBank = idValueName;
    if (this.cardIdNameBank != "") {
      this.accName = true;
    } else {
      this.accName = false;
    }
  }
  onSearchChangeBank(idValueBank: string): void {
    this.selectedbankNamedoc = idValueBank;
  }

  onChangeBank(event: string) {
    // console.log("event",event);
    this.bankNamedoc = event;
    this.isCheckBankName = false;
    if (this.isCheckBankName == false) {
      this.isBtnAddBank = false;
    }
    // this.isCheckAccountName = false;
  }

  loadDataBank() {
    var dataGetBankPhar: any;
    this.pharmacysService
      .GetBankPhar_(this.idPharmacy, "pharmacy")
      .then((resGetBankPhar) => {
        dataGetBankPhar = resGetBankPhar;
        this.dataBankByIdPharAll = dataGetBankPhar;
      });
    // setInterval(() => {
    //   this.dataLoading = false;
    // }, 1000);
  }

  AddBank() {
    var dataAddBankDoctor: any;
    var addBankDoctor_: any = [];
    var dataAddBankDoc = {
      // drId: this.idPharmacy,
      pharId: this.idPharmacy,
      bankName: this.bankNamedoc,
      bankNo: this.bankPharForm.getRawValue().bankNo,
      bankAccountName: this.bankPharForm.getRawValue().AccountName,
      bankFromType: this.bankFromTypedoc,
    };
    // console.log("dataAddBankDoc", dataAddBankDoc);

    this.pharmacysService
      .AddBankPhar_(dataAddBankDoc)
      .then((resAddBankDoctor) => {
        dataAddBankDoctor = resAddBankDoctor;
        if (dataAddBankDoctor.status == true) {
          var dataBankList = {
            // "dR_Id" : dataAddBankDoctor.idDoctor,
            banK_Id: dataAddBankDoctor.bankId,
            phaR_Id: dataAddBankDoc.pharId,
            banK_Name: dataAddBankDoc.bankName,
            banK_No: dataAddBankDoc.bankNo,
            banK_AccountName: dataAddBankDoc.bankAccountName,
            banK_FromType: dataAddBankDoc.bankFromType,
          };
          if (this.dataBankByIdPharAll.length > 0) {
            this.dataBankByIdPharAll.splice(0, 0, dataBankList);
            // dataAddBankDoc.bankName.cl
          } else {
            addBankDoctor_.push(dataBankList);
            this.dataBankByIdPharAll = addBankDoctor_;
          }
          this.bankPharForm.reset();
        } else {
          this.showNotification(
            "snackbar-success",
            "Add Bank Successfully...!!!",
            "bottom",
            "center"
          );
        }
      });
  }

  editBank(row: any) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(UpdateBankDialogComponent, {
      data: row,
      direction: tempDirection,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var indexDataBank = this.dataBankByIdPharAll.findIndex(
          (d: { bankId: any }) => d.bankId == result.bankId
        );
        // console.log("5555555", indexDataBank);

        this.dataBankByIdPharAll[indexDataBank].banK_Name = result.banK_Name;
        this.dataBankByIdPharAll[indexDataBank].banK_No = result.banK_No;
        this.dataBankByIdPharAll[indexDataBank].banK_AccountName =
          result.banK_AccountName;
        this.dataBankByIdPharAll[indexDataBank].banK_AccountType =
          result.banK_AccountType;

        this.showNotification(
          "snackbar-success",
          "Update Record Successfully...!!!",
          "bottom",
          "center"
        );
      }
    });
  }

  deleteBankDoctor(index: number, idBank: string) {
    // console.log(index,idBank);

    this.customWithFunctiondelectBank(index, idBank);
  }
  customWithFunctiondelectBank(index: number, idBank: string) {
    var langDel = {
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      ok: "Yes, delete it!",
      delete: "Delete record successfully!",
      cancel: "Cancel",
      complete: "Deleted!",
      completetext: "Your file has been deleted.",
    };
    if (this.transLang == "th") {
      langDel = {
        title: "ยืนยันการลบ?",
        text: "หากยืนยันที่จะลบ คุณจะไม่สามารถกลับไปแก้ไขได้",
        ok: "ใช่, ลบออกทันที",
        delete: "ลบข้อมูลสำเร็จ!",
        cancel: "ยกเลิก",
        complete: "ลบข้อมูลสำเร็จ!",
        completetext: "ข้อมูลของคุณถูกนำออกแล้ว",
      };
    }
    var dataDeleteBank: any;
    Swal.fire({
      title: langDel.title,
      text: langDel.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#B1CAE3",
      cancelButtonColor: "#51B1A5",
      confirmButtonText: langDel.ok,
      cancelButtonText: langDel.cancel,
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.pharmacysService
          .DeleteBankOrPhar_(idBank)
          .then((resDeleteBank) => {
            dataDeleteBank = resDeleteBank;
            // console.log("ssss ",dataDeleteSkill);
            if (dataDeleteBank.status == true) {
              Swal.fire(langDel.complete, langDel.completetext, "success");
              this.dataBankByIdPharAll.splice(index, 1);
              this.showNotification(
                "snackbar-success",
                langDel.delete,
                langDel.delete,
                "center"
              );
            } else {
              console.log("error : ", dataDeleteBank);
            }
          });
      }
    });
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 3000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  preview() {
    this.noImg = false;
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  async loadDataPharmacy(idDoctor: string) {
    var dataPharmacyList: any;
    await this.pharmacysService
      .GetPharmacysList_(idDoctor)
      .then((resPharmacyList) => {
        dataPharmacyList = resPharmacyList;
        this.datapharmacys = dataPharmacyList.result;

        var dataProvince = dataPharmacyList.result.phaR_Province.split(",");
        var dataDistrict = dataPharmacyList.result.phaR_District.split(",");
        var dataSubdistrict =
          dataPharmacyList.result.phaR_Subdistrict.split(",");
        // console.log("dataDistrict ",dataDistrict);
        dataPharmacyList.result.phaR_Province = dataProvince[0];
        dataPharmacyList.result.phaR_District = dataDistrict[0];
        dataPharmacyList.result.phaR_Subdistrict = dataSubdistrict[0];
        dataPharmacyList.result.idProvince = dataProvince[1];
        dataPharmacyList.result.idDistrict = dataDistrict[1];
        dataPharmacyList.result.idSubdistrict = dataSubdistrict[1];

        this.pharmacys = dataPharmacyList.result;
        // console.log("pharmacys:: ", this.pharmacys.idDistrict);
        this.pharmacyForm = this.createContactForm();

        this.provinceID = this.pharmacys.idProvince;
        this.districtID = this.pharmacys.idDistrict;
        this.subdistrictID = this.pharmacys.idSubdistrict;
        this.dataProvince();
        this.dataSubDistrict(this.pharmacys.idDistrict);
        this.dataDistrict(this.pharmacys.idProvince);
      });
  }

  public async confirmUpdate(): Promise<void> {
    var dataAddpharmacySuccess: any;
    const formData = new FormData();
    formData.append("FormFiles", this.fileData);
    formData.append("TypeUpload", "pharmacy");
    var img: any = "";
    if (this.fileData != undefined) {
      await this.pharmacysService
        .UploadIMGProfileDrug_(formData)
        .then((resImg) => {
          img = resImg;
        });
    }
    if (
      this.pharmacyForm.getRawValue().Password ==
      this.pharmacyForm.getRawValue().Password2
    ) {
      var dataUpdatePharmacys = {
        PHAR_Id: this.pharmacys.phaR_Id,
        PHAR_Name: this.pharmacyForm.getRawValue().Name,
        PHAR_Username: this.pharmacyForm.getRawValue().UserName.toLowerCase(),
        PHAR_Email: this.pharmacyForm.getRawValue().Email,
        PHAR_Password: this.pharmacyForm.getRawValue().Password,
        PHAR_Image: img,
        PHAR_AboutPhamacy: this.pharmacyForm.getRawValue().AboutPhamacy,
        PHAR_ChatPerTime: this.pharmacyForm.getRawValue().ChatPerTime,
        PHAR_VideoPerTime: this.pharmacyForm.getRawValue().VideoPerTime,
        PHAR_Phone: this.pharmacyForm.getRawValue().Phone,
        PHAR_Address: this.pharmacyForm.getRawValue().Address,
        PHAR_Province: `${this.pharmacyForm.getRawValue().Province},${this.provinceID
          }`,
        PHAR_District: `${this.pharmacyForm.getRawValue().District},${this.districtID
          }`,
        PHAR_Subdistrict: `${this.pharmacyForm.getRawValue().subDistrict},${this.subdistrictID
          }`,
        PHAR_Postalcode: this.pharmacyForm.getRawValue().Zipcode,
      };

      this.pharmacysService
        .UpdatePharmacys_(dataUpdatePharmacys)
        .then((resAddPharmacys) => {
          dataAddpharmacySuccess = resAddPharmacys;
          // console.log("dataAddpharmacySuccess ",dataAddpharmacySuccess);

          if (dataAddpharmacySuccess.status == true) {
            if (img == "") {
              img = this.datapharmacys.phaR_Image;
            }
            var dataUpdatePharmacysLocal = {
              changePassStatus: this.dataLocalUser.changePassStatus,
              id: this.dataLocalUser.id,
              img: img,
              role: this.roleAdmin,
              status: this.dataLocalUser.status,
              token: this.dataLocalUser.token,
              username: this.dataLocalUser.username,
            };
            localStorage.setItem(
              "currentUser",
              JSON.stringify(dataUpdatePharmacysLocal)
            );

            Swal.fire({
              title: this.transLang == "en" ? "Update Success" : "อัปเดตสำเร็จ",
              icon: "success",
              // confirmButtonColor: "#0f9b0f",
              // confirmButtonText: "OK",
            }).then(() => {
              window.location.reload();
            });
          } else {
            if (dataAddpharmacySuccess.msg == "Duplicate Email") {
              this.duplicateEmail = true;
              this.duplicateUsername = false;
              this.duplicatePassword = false;
            } else if (dataAddpharmacySuccess.msg == "Duplicate Username") {
              this.duplicateUsername = true;
              this.duplicateEmail = false;
              this.duplicatePassword = false;
            } else {
              console.log(dataAddpharmacySuccess.msg);
              window.location.reload();
            }
          }
        });
    } else {
      this.duplicatePassword = true;
    }
  }

  dataProvince() {
    this.pharmacysService.GetProvincePharmacys_().then((resdataProvince) => {
      this.dataProvincePharmacys = resdataProvince;
      // console.log(resdataProvince);
      for (let i = 0; i < this.dataProvincePharmacys.length; i++) {
        this.provinces.push({
          value: this.dataProvincePharmacys[i].provinceId,
          viewValue: this.dataProvincePharmacys[i].provinceName,
        });

        if (i == this.dataProvincePharmacys.length - 1) {
          // this.selectedProvince = this.data.idProvince
        }
      }
    });
  }

  dataDistrict(idprovince?: string) {
    this.districts = [];
    this.subdistricts = [];
    this.pharmacysService
      .GetDistrictPharmacys_(idprovince)
      .then((resdataDistrict) => {
        this.dataDistrictPharmacys = resdataDistrict;
        // console.log("------------ ",resdataDistrict);
        for (let i = 0; i < this.dataDistrictPharmacys.length; i++) {
          this.districts.push({
            value: this.dataDistrictPharmacys[i].amphurId,
            viewValue: this.dataDistrictPharmacys[i].amphurName,
          });
          if (i == this.dataDistrictPharmacys.length - 1) {
            // this.selectedDistrict = this.data.idDistrict
          }
        }
      });
  }

  dataSubDistrict(idsubDistrict?: string) {
    this.pharmacysService
      .GetSubDistrictPharmacys_(idsubDistrict)
      .then((resdataSubDistrict) => {
        this.dataSubDistrictPharmacys = resdataSubDistrict;
        // console.log(resdataSubDistrict);
        for (let i = 0; i < this.dataSubDistrictPharmacys.length; i++) {
          this.subdistricts.push({
            value: this.dataSubDistrictPharmacys[i].districtId,
            viewValue: this.dataSubDistrictPharmacys[i].districtName,
          });
          if (i == this.dataSubDistrictPharmacys.length - 1) {
            // this.selectedSubdistrict = this.data.idSubdistrict
          }
        }
      });
  }

  onClickIDProvince(idProvince: string) {
    this.provinceID = idProvince;
  }
  onClickIDDistrict(idDistrict: string) {
    this.districtID = idDistrict;
  }
  onClickIDSubDistrict(idSubDistrict: string) {
    this.subdistrictID = idSubDistrict;
  }

  loadDataOrderDrugList(idPharmacy: string) {
    var dataOrderDrug: any;
    this.pharmacysService
      .GetOrderDruglist_(idPharmacy, null, null)
      .then((resOrderDrug) => {
        dataOrderDrug = resOrderDrug;
        // console.log("<------dataOrderDrug-------->", dataOrderDrug);
        this.getOrderShowAccount = dataOrderDrug;
      });
    // setInterval(() => {
    //   this.dataLoading = false;
    // }, 1000);
  }

  loadDataTotalIncomeAll(allID: string, idUser: string) {
    var dataGetTotalIncomeAll: any;
    var resultOrderDrugSuccess: any = [];
    this.pharmacysService
      .GetPhamacyTotalIncome_(allID, idUser)
      .then((resGetTotalIncomeAll) => {
        dataGetTotalIncomeAll = resGetTotalIncomeAll;

        for (let i = 0; i < dataGetTotalIncomeAll.length; i++) {
          if (
            (dataGetTotalIncomeAll[i].recpT_TransferStutus == true &&
              dataGetTotalIncomeAll[i].recpT_TypePayment == "transfer") ||
            (dataGetTotalIncomeAll[i].recpT_StatusPay == true &&
              dataGetTotalIncomeAll[i].recpT_TypePayment == "digipay")
          ) {
            resultOrderDrugSuccess.push(dataGetTotalIncomeAll[i]);
            var yearData = formatDate(
              dataGetTotalIncomeAll[i].ordeR_Date,
              "yyyy",
              this.locale
            );
            var yearNow = formatDate(new Date(), "yyyy", this.locale);
            if (yearData == yearNow) {
              this.countOrderAll = this.countOrderAll + 1;
            }
          }
        }

        setInterval(() => {
          this.dataLoading = false;
        }, 1000);
      });
  }
}
