import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { PharmacyService } from "../pharmacy.service";
import { Order } from "./order.model";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { MatTableDataSource } from "@angular/material/table";
import { ViewPaymentDialogComponent } from "./dialogs/view-payment-dialog/view-payment-dialog.component";
import { EditCodeDialogComponent } from "./dialogs/edit-code-dialog/edit-code-dialog.component";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { OrderDrugListModel } from "./order-drug-list.model";
import { GlobalConstants } from "src/app/common/global-constants";
import { LOCALE_ID, Inject } from "@angular/core";
import { formatDate } from "@angular/common";
import { Socket } from "ngx-socket-io";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "../interface-pharmacy/language-item-pharmacy";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class OrderListComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  optionForm: FormGroup;
  statusSelectOrder: string = "3";
  displayedColumns = [
    "id",
    "image",
    "name",
    "time",
    "orderList",
    "type",
    "typePayment",
    "more",
  ];
  dataSource: MatTableDataSource<any>;
  exampleDatabase: PharmacyService | null;
  selection = new SelectionModel<Order>(true, []);
  idPharmacy: string;
  showOrderListById: any = [];
  dataOrderAll: any = [];

  imgDrugLists: Array<string> = [];
  imgDrugListsOld: string = "";
  imgF: string = "";
  dataLoading: boolean = true;
  dataTracking = [];
  dataTrackingRequest: any = [];
  sumqty: number = 0;
  orderDrugList: OrderDrugListModel;
  selectedDate = "";
  urlPath = GlobalConstants.REST_API_TELE;
  urlPath2021 = GlobalConstants.URL_PART_2021;

  dateNow = new Date();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  newStartDate!: string;
  newEndDate!: string;

  countOrder: number = 0;
  dataGetOrderPhamacyRequests: any;

  newOrder: boolean = false;
  newOrderCount: number = 0;
  languagePharmacy: any;

  constructor(
    private socket_: Socket,
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public pharmacyService: PharmacyService,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    super();
    this.translate.use(localStorage.getItem("lang"));
    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);
    this.idPharmacy = dataLocalJSON.id;

    this.orderDrugList = new OrderDrugListModel({});

    this.optionForm = this.opionContactForm();
  }

  formControl = new FormControl("", [Validators.required]);
  opionContactForm(): FormGroup {
    return this.fb.group({
      options: ["3"],
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
    this.loadDataOrderDrugList(this.idPharmacy, null, null);
    this.loadDataOrderPhamacyRequest(this.idPharmacy);
    this.socketOrder();
  }

  socketOrder() {
    this.socket_.on("datamessage_orderMedicine", (data: any) => {
      // console.log("data sss ",data);
      if (data.OrderID != localStorage.getItem("NewOrder2")) {
        localStorage.setItem("NewOrder2", data.OrderID);
        this.newOrderCount++;
        // this.loadDataOrderDrugList(this.idPharmacy, null, null);
        this.newOrder = true;
      }
    });
  }

  onSearchChangeDate(idValueDate: string): void {
    this.selectedDate = idValueDate;
  }

  onChengDate() {
    var dataStart: string = "";
    var dataEnd: string = "";
    dataStart = this.range.value.start;
    dataEnd = this.range.value.end;

    if (this.range.value.start != null && this.range.value.end != null) {
      this.newStartDate = formatDate(dataStart, "yyyy-MM-dd", this.locale);
      this.newEndDate = formatDate(dataEnd, "yyyy-MM-dd", this.locale);

      //ส่งออเดอร์ไม่สำเร็จ
      this.dataTrackingRequest = [];
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      setInterval(() => {
        this.dataLoading = false;
      }, 500);
      this.loadDataOrderDrugList(
        this.idPharmacy,
        this.newStartDate,
        this.newEndDate
      );
    }
  }

  loadDataOrderDrugList(
    idPharmacy: string,
    dateStart: string,
    dateEnd: string
  ) {
    var dataOrderDrug: any;
    var resultOrderDrug: any = [];
    var resultOrderDrugAll: any = [];
    var resultOrderDrugSuccess: any = [];
    this.pharmacyService
      .GetOrderDruglist_(idPharmacy, dateStart, dateEnd)
      .then((resOrderDrug) => {
        dataOrderDrug = resOrderDrug;

        if (dataOrderDrug.length > 0) {
          for (let i = 0; i < dataOrderDrug.length; i++) {
            if (dataOrderDrug[i].dataReceipt.recpT_StatusPay == true) {
              resultOrderDrugAll.push(dataOrderDrug[i]);
            }

            if (
              dataOrderDrug[i].dataReceipt.recpT_TrackingNo != null &&
              dataOrderDrug[i].dataReceipt.recpT_TrackingNo != "" &&
              dataOrderDrug[i].dataReceipt.recpT_StatusPay == true
            ) {
              resultOrderDrugSuccess.push(dataOrderDrug[i]);
            } else if (dataOrderDrug[i].dataReceipt.recpT_StatusPay == true) {
              resultOrderDrug.push(dataOrderDrug[i]);
            }

            if (i == dataOrderDrug.length - 1) {
              this.dataOrderAll = resultOrderDrugAll;

              //ส่งออเดอร์สำเร็จ
              this.dataTracking = resultOrderDrugSuccess;
              //ส่งออเดอร์ไม่สำเร็จ
              this.dataTrackingRequest = resultOrderDrug;

              // console.log("resultOrderDrugAll ",resultOrderDrugAll.length);

              // console.log("resultOrderDrugSuccess ",resultOrderDrugSuccess.length);

              // console.log("resultOrderDrug ",resultOrderDrug.length);

              this.dataSource = new MatTableDataSource(resultOrderDrugAll);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              setInterval(() => {
                this.dataLoading = false;
              }, 500);
            }
          }
        } else {
          setInterval(() => {
            this.dataLoading = false;
          }, 500);
        }
      });
  }

  loadDataOrderPhamacyRequest(idPharmacy: string) {
    let dataGetOrderPhamacy: any;
    let dataOrderPhamacyArray: any = [];
    this.pharmacyService
      .GetOrderPhamacyRequestAll_(idPharmacy)
      .then((resGetOrderPhamacy) => {
        dataGetOrderPhamacy = resGetOrderPhamacy;
        for (let i = 0; i < dataGetOrderPhamacy.length; i++) {
          if (dataGetOrderPhamacy[i].dataReceipt.recpT_StatusPay == true) {
            dataOrderPhamacyArray.push(dataGetOrderPhamacy[i]);
          }

          if (i == dataGetOrderPhamacy.length - 1) {
            this.dataGetOrderPhamacyRequests = dataOrderPhamacyArray;
            this.countOrder = this.dataGetOrderPhamacyRequests.length;
          }
        }
      });
  }

  onclickShowDataPhamacyRequest(idOrder: any) {
    var dataSelect: Array<any> = [];
    this.dataTrackingRequest = [];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    var dataPhamacyRequests = this.dataGetOrderPhamacyRequests.find(
      (d: { idOrder: any }) => d.idOrder == idOrder
    );
    dataSelect.push(dataPhamacyRequests);
    this.dataTrackingRequest = dataSelect;
    this.dataSource = new MatTableDataSource(dataSelect);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  sumQty(sum: any) {
    this.sumqty = 0;
    for (let i = 0; i < sum.length; i++) {
      this.sumqty = this.sumqty + sum[i].qty * sum[i].medC_.medC_Price;
    }
  }

  dialogPayment(element, sum) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(ViewPaymentDialogComponent, {
      data: {
        element: element,
        sum: sum,
      },
      width: "80%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("result-- ",result," :: ",result.RECPT_TransferStutus);

      if (result != undefined) {
        // console.log("this.dataTrackingRequest ",this.dataTrackingRequest);

        var indexDatatrackingRequest1 = this.dataTrackingRequest.findIndex(
          (d: { idOrder: any }) => d.idOrder == result.idOrder
        );
        var indexDatatrackingRequest2 = this.dataTracking.findIndex(
          (d: { idOrder: any }) => d.idOrder == result.idOrder
        );
        if (indexDatatrackingRequest1 != -1) {
          this.dataTrackingRequest[
            indexDatatrackingRequest1
          ].dataReceipt.recpT_TransferStutus = result.RECPT_TransferStutus;
        }
        if (indexDatatrackingRequest2 != -1) {
          this.dataTracking[
            indexDatatrackingRequest2
          ].dataReceipt.recpT_TransferStutus = result.RECPT_TransferStutus;
        }

        // this.dataSource = new MatTableDataSource(this.dataTrackingRequest);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
      }
    });
  }

  editDialog(idOrder: number, dataReceipt: any) {
    const dialogRef = this.dialog.open(EditCodeDialogComponent, {
      data: dataReceipt,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var indexDatatrackingAll = this.dataOrderAll.findIndex(
          (d: { idOrder: any }) => d.idOrder == idOrder
        );
        var indexDatatracking = this.dataTracking.findIndex(
          (d: { idOrder: any }) => d.idOrder == idOrder
        );
        var indexDatatrackingRequest = this.dataTrackingRequest.findIndex(
          (d: { idOrder: any }) => d.idOrder == idOrder
        );

        if (indexDatatracking != -1 && this.statusSelectOrder == "2") {
          // this.dataTracking.splice(indexDatatracking, 1);
          this.dataTracking[indexDatatracking].dataReceipt.recpT_TrackingNo =
            result.RECPT_TrackingNo;
          this.dataTracking[
            indexDatatracking
          ].dataReceipt.recpT_ShippingCompany = result.RECPT_ShippingCompany;

          this.dataOrderAll[indexDatatrackingAll].dataReceipt.recpT_TrackingNo =
            result.RECPT_TrackingNo;
          this.dataOrderAll[
            indexDatatrackingAll
          ].dataReceipt.recpT_ShippingCompany = result.RECPT_ShippingCompany;

          if (this.statusSelectOrder == "2") {
            this.dataSource = new MatTableDataSource(this.dataTracking);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else if (
          indexDatatrackingRequest != -1 &&
          this.statusSelectOrder == "1"
        ) {
          if (
            dataReceipt.recpT_ShippingCompany == null ||
            dataReceipt.recpT_ShippingCompany == ""
          ) {
            this.countOrder--;
          }
          this.dataTrackingRequest[
            indexDatatrackingRequest
          ].dataReceipt.recpT_TrackingNo = result.RECPT_TrackingNo;
          this.dataTrackingRequest[
            indexDatatrackingRequest
          ].dataReceipt.recpT_ShippingCompany = result.RECPT_ShippingCompany;

          this.dataOrderAll[indexDatatrackingAll].dataReceipt.recpT_TrackingNo =
            result.RECPT_TrackingNo;
          this.dataOrderAll[
            indexDatatrackingAll
          ].dataReceipt.recpT_ShippingCompany = result.RECPT_ShippingCompany;

          this.dataTracking.push(
            this.dataTrackingRequest[indexDatatrackingRequest]
          );

          if (this.statusSelectOrder == "1") {
            this.dataSource = new MatTableDataSource(this.dataTrackingRequest);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          setTimeout(() => {
            this.dataTrackingRequest.splice(indexDatatrackingRequest, 1);
          }, 5000);
        } else {
          if (
            dataReceipt.recpT_ShippingCompany == null ||
            dataReceipt.recpT_ShippingCompany == ""
          ) {
            this.countOrder--;
          }
          this.dataOrderAll[indexDatatrackingAll].dataReceipt.recpT_TrackingNo =
            result.RECPT_TrackingNo;
          this.dataOrderAll[
            indexDatatrackingAll
          ].dataReceipt.recpT_ShippingCompany = result.RECPT_ShippingCompany;

          this.dataTracking.push(this.dataOrderAll[indexDatatrackingAll]);
          this.dataTrackingRequest.splice(indexDatatrackingAll, 1);

          if (this.statusSelectOrder == "3") {
            this.dataSource = new MatTableDataSource(this.dataOrderAll);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      }
    });
  }

  onChangeStatus(status: string) {
    this.statusSelectOrder = status;
    if (status == "1") {
      this.dataSource = new MatTableDataSource(this.dataTrackingRequest);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (status == "2") {
      this.dataSource = new MatTableDataSource(this.dataTracking);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (status == "3") {
      this.dataSource = new MatTableDataSource(this.dataOrderAll);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  showOrderByListId(lisTId: any) {
    this.showOrderListById = lisTId;
    this.imgDrugLists = this.showOrderListById.medC_Image.split(",");
    this.imgF = this.imgDrugLists[0];
    this.imgDrugListsOld = this.showOrderListById.medC_Image;
  }

  imgClicked(img: string) {
    this.imgF = img;
  }
}
