import { LanguageInterfacePharmacy } from "./language-interface-pharmacy";

export const LanguagePharmacy: LanguageInterfacePharmacy[] = [
  {
    //Dashboard
    pharmacy: "PHARMACY.DASHBOARD.PHARMACY",
    totalreport: "PHARMACY.DASHBOARD.TOTALREPORT",
    dashboard: "PHARMACY.DASHBOARD.DASHBOARD",
    home: "PHARMACY.DASHBOARD.HOME",
    totalincome: "PHARMACY.DASHBOARD.TOTALINCOME",
    totaldrugsalesrevenuetotaled:
      "PHARMACY.DASHBOARD.TOTALDRUGSALESREVENUETOTALED",
    baht: "PHARMACY.DASHBOARD.BAHT",
    totalorder: "PHARMACY.DASHBOARD.TOTALORDER",
    orderdrugsalesrevenuetotaled:
      "PHARMACY.DASHBOARD.ORDERDRUGSALESREVENUETOTALED",
    order: "PHARMACY.DASHBOARD.ORDER",
    rating: "PHARMACY.DASHBOARD.RATING",
    ratings: "PHARMACY.DASHBOARD.RATINGS",
    latestincomereport: "PHARMACY.DASHBOARD.LATESTINCOMEREPORT",
    totaltoday: "PHARMACY.DASHBOARD.TOTALTODAY",
    totalweek: "PHARMACY.DASHBOARD.TOTALWEEK",
    totalmonth: "PHARMACY.DASHBOARD.TOTALMONTH",
    totalincomeofthe: "PHARMACY.DASHBOARD.TOTALINCOMEOFTHE",
    are: "PHARMACY.DASHBOARD.ARE",
    barcharttotalincome: "PHARMACY.DASHBOARD.BARCHARTTOTALINCOME",
    recentorderreport: "PHARMACY.DASHBOARD.RECENTORDERREPORT",
    ordertoday: "PHARMACY.DASHBOARD.ORDERTODAY",
    orderweek: "PHARMACY.DASHBOARD.ORDERWEEK",
    ordermonth: "PHARMACY.DASHBOARD.ORDERMONTH",
    totalsalesofthe: "PHARMACY.DASHBOARD.TOTALSALESOFTHE",
    barchattotalorder: "PHARMACY.DASHBOARD.BARCHATTOTALORDER",
    topmedicineitems: "PHARMACY.DASHBOARD.TOPMEDICINEITEMS",
    topunpopulardrugs: "PHARMACY.DASHBOARD.TOPUNPOPULARDRUGS",
    sell: "PHARMACY.DASHBOARD.SELL",
    pieces: "PHARMACY.DASHBOARD.PIECES",
    weekly: "PHARMACY.DASHBOARD.WEEKLY",

    //Drug list
    druglist: "PHARMACY.DRUGLIST.DRUGLIST",
    listpharmacytable: "PHARMACY.DRUGLIST.LISTPHARMACYTABLE",
    search: "PHARMACY.DRUGLIST.SEARCH",
    medcname: "PHARMACY.DRUGLIST.MEDCNAME",
    medcprice: "PHARMACY.DRUGLIST.MEDCPRICE",
    medcqty: "PHARMACY.DRUGLIST.MEDCQTY",
    medcunit: "PHARMACY.DRUGLIST.MEDCUNIT",
    medcdescription: "PHARMACY.DRUGLIST.MEDCDESCRIPTION",
    medccaution: "PHARMACY.DRUGLIST.MEDCCAUTION",
    actions: "PHARMACY.DRUGLIST.ACTIONS",
    addcategory: "PHARMACY.DRUGLIST.ADDCATEGORY",
    updatecategory: "PHARMACY.DRUGLIST.EDITCATEGORY",
    categorypharmacy: "PHARMACY.DRUGLIST.CATEGORYPHARMACY",
    categorynamethai: "PHARMACY.DRUGLIST.CATEGORYNAMETHAI",
    categorynameenglish: "PHARMACY.DRUGLIST.CATEGORYNAMEENGLISH",
    newdruglist: "PHARMACY.DRUGLIST.NEWDRUGLIST",
    medicines: "PHARMACY.DRUGLIST.MEDICINES",
    name: "PHARMACY.DRUGLIST.NAME",
    medicinesname: "PHARMACY.DRUGLIST.MEDICINESNAME",
    discount: "PHARMACY.DRUGLIST.DISCOUNT",
    price: "PHARMACY.DRUGLIST.PRICE",
    qty: "PHARMACY.DRUGLIST.QTY",
    unit: "PHARMACY.DRUGLIST.UNIT",
    discription: "PHARMACY.DRUGLIST.DISCRIPTION",
    caution: "PHARMACY.DRUGLIST.CAUTION",
    howtouse: "PHARMACY.DRUGLIST.HOWTOUSE",
    expirationdate: "PHARMACY.DRUGLIST.EXPIRATIONDATE",
    save: "PHARMACY.DRUGLIST.SAVE",
    cancle: "PHARMACY.DRUGLIST.CANCLE",
    update: "PHARMACY.DRUGLIST.UPDATE",
    view: "PHARMACY.DRUGLIST.VIEW",
    productdetails: "PHARMACY.DRUGLIST.PRODUCTDETAILS",

    //order drug list
    orderdruglist: "PHARMACY.ORDERDRUGLIST.ORDERDRUGLIST",
    enterdaterange: "PHARMACY.ORDERDRUGLIST.ENTERDATERANGE",
    starddate: "PHARMACY.ORDERDRUGLIST.STARDDATE",
    enddate: "PHARMACY.ORDERDRUGLIST.ENDDATE",
    dateinformation: "PHARMACY.ORDERDRUGLIST.DATEINFORMATION",
    waiting: "PHARMACY.ORDERDRUGLIST.WAITING",
    successful: "PHARMACY.ORDERDRUGLIST.SUCCESSFUL",
    all: "PHARMACY.ORDERDRUGLIST.ALL",
    id: "PHARMACY.ORDERDRUGLIST.ID",
    image: "PHARMACY.ORDERDRUGLIST.IMAGE",
    orderlist: "PHARMACY.ORDERDRUGLIST.ORDERLIST",
    typ: "PHARMACY.ORDERDRUGLIST.TYP",
    typepayment: "PHARMACY.ORDERDRUGLIST.TYPEPAYMENT",
    more: "PHARMACY.ORDERDRUGLIST.MORE",
    nodata: "PHARMACY.ORDERDRUGLIST.NODATA",
    listofallproducts: "PHARMACY.ORDERDRUGLIST.LISTOFALLPRODUCTS",
    orderdate: "PHARMACY.ORDERDRUGLIST.ORDERDATE",
    quantity: "PHARMACY.ORDERDRUGLIST.QUANTITY",
    list: "PHARMACY.ORDERDRUGLIST.LIST",
    total: "PHARMACY.ORDERDRUGLIST.TOTAL",
    totalprice: "PHARMACY.ORDERDRUGLIST.TOTALPRICE",
    totalpriceall: "PHARMACY.ORDERDRUGLIST.TOTALPRICEALL",
    parceltracking: "PHARMACY.ORDERDRUGLIST.PARCELTRACKING",
    transferstatus: "PHARMACY.ORDERDRUGLIST.TRANSFERSTATUS",
    amounttobepaid: "PHARMACY.ORDERDRUGLIST.AMOUNTTOBEPAID",
    allbankaccounts: "PHARMACY.ORDERDRUGLIST.ALLBANKACCOUNTS",
    accountnumber: "PHARMACY.ORDERDRUGLIST.ACCOUNTNUMBER",
    bank: "PHARMACY.ORDERDRUGLIST.BANK",
    accountname: "PHARMACY.ORDERDRUGLIST.ACCOUNTNAME",
    transfersuccessfully: "PHARMACY.ORDERDRUGLIST.TRANSFERSUCCESSFULLY",
    failedtransfer: "PHARMACY.ORDERDRUGLIST.FAILEDTRANSFER",
    note: "PHARMACY.ORDERDRUGLIST.NOTE",
    record: "PHARMACY.ORDERDRUGLIST.RECORD",
    failedtransferstatus: "PHARMACY.ORDERDRUGLIST.FAILEDTRANSFERSTATUS",
    transferstatussuccessful: "PHARMACY.ORDERDRUGLIST.TRANSFERSTATUSSUCCESSFUL",
    waitingfordelivery: "PHARMACY.ORDERDRUGLIST.WAITINGFORDELIVERY",
    successfuldelivery: "PHARMACY.ORDERDRUGLIST.SUCCESSFULDELIVERY",
    waitingtransferstatus: "PHARMACY.ORDERDRUGLIST.WAITINGTRANSFERSTATUS",
    checkthetransferstatus: "PHARMACY.ORDERDRUGLIST.CHECKTHETRANSFERSTATUS",
    payviacredit: "PHARMACY.ORDERDRUGLIST.PAYVIACREDIT",
    banktransfer: "PHARMACY.ORDERDRUGLIST.BANKTRANSFER",
    anorderhasbeenplaced: "PHARMACY.ORDERDRUGLIST.ANORDERHASBEENPLACED",
    orderpleasereloadnewpage: "PHARMACY.ORDERDRUGLIST.ORDERPLEASERELOADNEWPAGE",
    parceltrackingnumber: "PHARMACY.ORDERDRUGLIST.PARCELTRACKINGNUMBER",

    // chat
    c_chat: "PHARMACY.CHAT.CHAT",
    c_search: "PHARMACY.CHAT.SEARCH",
    c_noresult: "PHARMACY.CHAT.NORESULT",
    c_start: "PHARMACY.CHAT.START",
    c_person: "PHARMACY.CHAT.PERSON",
    c_text: "PHARMACY.CHAT.TEXT",
    c_send: "PHARMACY.CHAT.SEND",
    c_loading: "PHARMACY.CHAT.LOADING",
    c_heading: "PHARMACY.CHAT.HEADING",

    // total income
    monthincome: "PHARMACY.TOTALINCOME.MONTHINCOME",
    ti_totalincome: "PHARMACY.TOTALINCOME.TOTALINCOME",
    ti_monthorder: "PHARMACY.TOTALINCOME.MONTHORDER",
    ti_allorder: "PHARMACY.TOTALINCOME.ALLORDERS",
    ti_todayincomeandorsers: "PHARMACY.TOTALINCOME.TODAYINCOMEANDORDERS",
    ti_incomeinfo: "PHARMACY.TOTALINCOME.INCOMEINFO",
    ti_netincome: "PHARMACY.TOTALINCOME.NETINCOME",
    ti_ordertoday: "PHARMACY.TOTALINCOME.ORDERTODAY",
    ti_nodata: "PHARMACY.TOTALINCOME.NODATA",
    ti_annualincome: "PHARMACY.TOTALINCOME.ANNUALINCOME",
    ti_ordersperyear: "PHARMACY.TOTALINCOME.ORDERSPERYEAR",
    ti_order: "PHARMACY.TOTALINCOME.ORDER",
    ti_history: "PHARMACY.TOTALINCOME.HISTORY",
    ti_daterange: "PHARMACY.TOTALINCOME.DATERANGE",
    ti_idorder: "PHARMACY.TOTALINCOME.IDORDER",
    ti_price: "PHARMACY.TOTALINCOME.PRICE",
    ti_time: "PHARMACY.TOTALINCOME.TIME",
    ti_orderlist: "PHARMACY.TOTALINCOME.ORDERLIST",
    ti_transferstatus: "PHARMACY.TOTALINCOME.TRANSFERSTATUS",
    ti_payment: "PHARMACY.TOTALINCOME.PAYMENT",

    // total income -> invoice
    iv_invoice: "PHARMACY.INVOICE.INVOICE",
    iv_cusinfo: "PHARMACY.INVOICE.CUSINFO",
    iv_pharinfo: "PHARMACY.INVOICE.PHARINFO",
    iv_email: "PHARMACY.INVOICE.EMAIL",
    iv_phone: "PHARMACY.INVOICE.PHONE",
    iv_name: "PHARMACY.INVOICE.NAME",
    iv_medname: "PHARMACY.INVOICE.MEDNAME",
    iv_use: "PHARMACY.INVOICE.USE",
    iv_qty: "PHARMACY.INVOICE.QTY",
    iv_price: "PHARMACY.INVOICE.PRICE",
    iv_total: "PHARMACY.INVOICE.TOTAL",
    iv_amount: "PHARMACY.INVOICE.AMOUNT",
    iv_discount: "PHARMACY.INVOICE.DISCOUNT",
    iv_vat: "PHARMACY.INVOICE.VAT",
    iv_print: "PHARMACY.INVOICE.PRINT",

    // package
    package: "MENUITEMS.DISTRIBUTOR.PACKAGE",
    allpackage: "MENUITEMS.DISTRIBUTOR.ALLPACKAGE",
    mypackage: "MENUITEMS.DISTRIBUTOR.MYPACKAGE",
    nomatch: "ADMIN.ALLPACKAGE.NOMATCH",
  },
];
