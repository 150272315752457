import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GlobalConstants } from "src/app/common/global-constants";
import { ViewFormDrugListComponent } from "../../drug-list/dialogs/view-form-drug-list/view-form-drug-list.component";
import { PharmacyService } from "../../pharmacy.service";
import { LanguagePharmacy } from "../../interface-pharmacy/language-item-pharmacy";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";

@Component({
  selector: "app-pharmacy-total-income-dialog",
  templateUrl: "./pharmacy-total-income-dialog.component.html",
  styleUrls: ["./pharmacy-total-income-dialog.component.scss"],
})
export class PharmacyTotalIncomeDialogComponent implements OnInit {
  userOrder: any;
  userPhar: any;
  urlPath2021 = GlobalConstants.URL_PART_2021;
  urlPath = GlobalConstants.REST_API_TELE;

  languagePharmacy: any;
  datatranslate: any;

  constructor(
    public dialogRef: MatDialogRef<ViewFormDrugListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pharmacyService: PharmacyService,
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService
  ) {
    // console.log(data);
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
    });

    this.getUserOrder();
    this.getUsetPhar();
  }

  getUserOrder() {
    this.pharmacyService.GetUserByID_(this.data.user_ID).then((resGetUser) => {
      this.userOrder = resGetUser;
    });
  }
  getUsetPhar() {
    this.pharmacyService
      .GetPharmacysList_(this.data.phaR_Id)
      .then((resPharmacyList) => {
        this.userPhar = resPharmacyList;
        // console.log("userPhar", this.userPhar);
      });
  }
}
