<div class="addContainer">
  <div class="row">
    <h4>{{languagePharmacy.parceltrackingnumber | translate}}</h4>
    <form [formGroup]="orderForm" ngSubmit="submit">
      <mat-form-field appearance="outline" style="display: block;">
        <mat-label>{{languagePharmacy.parceltrackingnumber | translate}}</mat-label>
        <input matInput formControlName="trackingNo" placeholder="{{languagePharmacy.parceltrackingnumber | translate}}"
          required>
      </mat-form-field>
      <mat-form-field appearance="outline" style="display: block;">
        <mat-label>{{languagePharmacy.parceltracking | translate}}</mat-label>
        <mat-select [(value)]="selectedParcelTracking" formControlName="shippingCompany">
          <mat-option value="บริษัทขนส่ง ไปรษณีย์ไทย">บริษัทขนส่ง ไปรษณีย์ไทย</mat-option>
          <mat-option value="บริษัทขนส่ง BEST EXPRESS">บริษัทขนส่ง BEST EXPRESS</mat-option>
          <mat-option value="บริษัทขนส่ง KERRY EXPRESS">บริษัทขนส่ง KERRY EXPRESS</mat-option>
          <mat-option value="บริษัทขนส่ง NINJA VAN">บริษัทขนส่ง NINJA VAN</mat-option>
          <mat-option value="บริษัทขนส่ง J&T EXPRESS">บริษัทขนส่ง J&T EXPRESS</mat-option>
          <mat-option value="บริษัทขนส่ง FLASH EXPRESS">บริษัทขนส่ง FLASH EXPRESS</mat-option>
          <mat-option value="บริษัทขนส่ง SCG EXPRESS">บริษัทขนส่ง SCG EXPRESS</mat-option>
          <mat-option value="บริษัทขนส่ง DHL EXPRESS">บริษัทขนส่ง DHL EXPRESS</mat-option>
          <mat-option value="บริษัทขนส่ง LALAMOVE">บริษัทขนส่ง LALAMOVE</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <button mat-raised-button color="primary" [class.auth-spinner]="loading" [disabled]="loading"
      style="display: block;background-color: #51B1A5;color: white;" class="auth-form-btn"
      (click)="confirmUpdateTrackingNo(recpNo)" [disabled]="!orderForm.valid" type="submit">{{languagePharmacy.update |
      translate}}</button>
  </div>
</div>