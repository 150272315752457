<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">{{languagePharmacy.druglist | translate}}</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a href="javascript:void(0)">
                <i class="fas fa-home"></i> {{languagePharmacy.home | translate}}</a>
            </li>
            <li class="breadcrumb-item active">{{languagePharmacy.druglist | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="{{languagePharmacy.druglist | translate}}">
              <div class="body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="ngxTableHeader">
                      <ul class="header-buttons-left ms-0 mb-0">
                        <li class="dropdown">
                          <h2>
                            <strong>{{languagePharmacy.listpharmacytable | translate}}</strong>
                          </h2>
                        </li>
                        <li class="dropdown m-l-20">
                          <label for="search-input"><i class="material-icons search-icon">search</i></label>
                          <input placeholder="{{languagePharmacy.search | translate}}" type="text"
                            class="browser-default search-field" (keyup)='filterDatatable($event)'
                            aria-label="Search box" #input>
                        </li>
                      </ul>
                      <ul class="header-buttons" *ngIf="adminRole != 'AdminViewOnly'">
                        <li>
                          <button mat-mini-fab style="background-color: #51B1A5;" (click)='addDruglistNew()'>
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <table *ngIf="!dataLoading" mat-table [dataSource]="dataSource" matSort>

                      <!-- ID Column -->
                      <ng-container matColumnDef="medcName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.medcname | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.medcName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="medcPrice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.medcprice |
                          translate}} </th>
                        <td mat-cell *matCellDef="let row">฿ {{row.medcPrice}} </td>
                      </ng-container>

                      <ng-container matColumnDef="medcQty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.medcqty | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.medcQty}} </td>
                      </ng-container>

                      <ng-container matColumnDef="medcUnit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.medcunit | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.medcUnit}} </td>
                      </ng-container>

                      <ng-container matColumnDef="medcDescription">
                        <th class="tableDes" mat-header-cell *matHeaderCellDef mat-sort-header>
                          {{languagePharmacy.medcdescription | translate}} </th>
                        <td class="tableDes" mat-cell *matCellDef="let row" style="padding-top: 12px;width: 305px;">
                          <p style="width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            {{row.medcDescription}}</p>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="medcCaution">
                        <th class="tableCau" mat-header-cell *matHeaderCellDef mat-sort-header>
                          {{languagePharmacy.medccaution | translate}} </th>
                        <td class="tableCau" mat-cell *matCellDef="let row" style="padding-top: 12px;width: 305px;">
                          <p style="width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            {{row.medcCaution}}</p>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.actions | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row; let i=index;" style="width: 160px;">
                          <button mat-icon-button color="accent" class="btn-tbl-edit" (click)="$event.stopPropagation()"
                            (click)="detailsCall(row)">
                            <!-- routerLink="/admin/doctors/doctor-profile/{{row.dR_Id}}"> -->
                            <mat-icon aria-label="View" class="col-white">remove_red_eye</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" *ngIf="adminRole != 'AdminViewOnly'"
                            class="btn-tbl-edit" (click)="$event.stopPropagation()" (click)="editDrug(row)">
                            <mat-icon aria-label="Edit" class="col-white">edit</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" *ngIf="adminRole != 'AdminViewOnly'"
                            class="btn-tbl-delete" (click)="$event.stopPropagation()"
                            (click)="deleteItem(i, row.medcId)">
                            <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                          </button>
                        </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="detailsCall(row)">
                      </tr>

                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">{{languagePharmacy.nomatch | translate}} "{{input.value}}"</td>
                      </tr>
                    </table>
                    <img *ngIf="dataLoading" class="text-center d-block border-top py-1"
                      src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" style="width:220px;margin: auto;"
                      alt="loading">
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{languagePharmacy.addcategory | translate}}">
              <div class="container mt-4 mb-4">
                <div class="row clearfix">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" style="margin: auto;">
                      <div class="header" style="display: flex;justify-content: space-between;">
                        <h2 style="margin-top:auto;margin-bottom: auto;">
                          <strong>{{languagePharmacy.categorypharmacy | translate}}</strong>
                        </h2>
                        <div class="icon-button-demo" *ngIf="adminRole != 'AdminViewOnly'">
                          <button mat-mini-fab style="background-color: #51B1A5;" (click)="addCategoryNew()">
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div class="col-12  text-center mt-5" *ngIf="dataLoadingCategory">
                        <div class="preloader">
                          <div class="spinner-layer pl-black">
                            <div class="circle-clipper left">
                              <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                              <div class="circle"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="body table-responsive" *ngIf="!dataLoadingCategory">
                        <table class="table table-condensed">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{{languagePharmacy.categorynamethai | translate}}</th>
                              <th>{{languagePharmacy.categorynameenglish | translate}}</th>
                              <th *ngIf="adminRole != 'AdminViewOnly'" style="text-align:center">
                                {{languagePharmacy.actions | translate}}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let itemCategory of dataCategoryMedicine; index as i">
                              <th scope="row">{{i+1}}</th>
                              <td *ngIf="itemCategory.medcaT_NameTH != null && itemCategory.medcaT_NameTH != ''">{{
                                itemCategory.medcaT_NameTH }}</td>
                              <td *ngIf="itemCategory.medcaT_NameTH == null || itemCategory.medcaT_NameTH == ''">-</td>
                              <td *ngIf="itemCategory.medcaT_NameEN != null && itemCategory.medcaT_NameEN != ''">{{
                                itemCategory.medcaT_NameEN }}</td>
                              <td *ngIf="itemCategory.medcaT_NameEN == null || itemCategory.medcaT_NameEN == ''">-</td>
                              <td *ngIf="adminRole != 'AdminViewOnly'" style="text-align:center">
                                <button mat-icon-button color="accent" class="btn-tbl-edit"
                                  (click)="editCategory(itemCategory)" (click)="$event.stopPropagation()">
                                  <mat-icon aria-label="Edit" class="col-white">edit</mat-icon>
                                </button>
                                <button mat-icon-button color="accent" class="btn-tbl-delete"
                                  (click)="deleteCategory(itemCategory.medcaT_Id,i)" (click)="$event.stopPropagation()">
                                  <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>