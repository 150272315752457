import { formatDate } from "@angular/common";
export class Pharmacy {
  phaR_Id: number;
  phaR_Image: string;
  phaR_Name: string;
  phaR_Username: string;
  phaR_Email: string;
  phaR_Password: string;
  phaR_Password2: string;
  phaR_Phone: string;
  phaR_AboutPhamacy: string;
  phaR_ChatPerTime: string;
  phaR_VideoPerTime: string;
  phaR_Address: string;
  phaR_Province: string;
  phaR_District: string;
  phaR_Subdistrict: string;
  phaR_Postalcode: string;
  idProvince: string;
  idDistrict: string;
  idSubdistrict: string;

  constructor(pharmacys) {
    {
      this.phaR_Id = pharmacys.phaR_Id || this.getRandomID();
      this.phaR_Image =
        pharmacys.phaR_Image || "assets/images/imgdoctor/nouser.svg";
      this.phaR_Username = pharmacys.phaR_Username || "";
      this.phaR_Email = pharmacys.phaR_Email || "";
      this.phaR_Password = pharmacys.phaR_Password || "";
      this.phaR_Password2 = pharmacys.phaR_Password2 || "";
      this.phaR_Phone = pharmacys.phaR_Phone || "";
      this.phaR_AboutPhamacy = pharmacys.phaR_AboutPhamacy || "";
      this.phaR_ChatPerTime = pharmacys.phaR_ChatPerTime || "";
      this.phaR_VideoPerTime = pharmacys.phaR_VideoPerTime || "";
      this.phaR_Address = pharmacys.phaR_Address || "";
      this.phaR_Province = pharmacys.phaR_Province || "";
      this.phaR_District = pharmacys.phaR_District || "";
      this.phaR_Subdistrict = pharmacys.phaR_Subdistrict || "";
      this.phaR_Postalcode = pharmacys.phaR_Postalcode || "";
      this.idProvince = pharmacys.idProvince || "";
      this.idDistrict = pharmacys.idDistrict || "";
      this.idSubdistrict = pharmacys.idSubdistrict || "";
    }
  }
  public getRandomID(): string {
    const S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4();
  }
}
