<div class="addContainer" style="min-width: 326px;">
    <div style="text-align: end;">
        <button mat-icon-button (click)="logOut()">
            <mat-icon>exit_to_app</mat-icon>
        </button>
    </div>
    <img src="assets/images/lock.gif" alt="" style="margin: auto;" width="162">
    <h3 style="text-align: center;padding-top: 10px;">{{languageWorking.changepassword | translate}}</h3>
    <h6 style="text-align: center;padding-top: 10px;">{{languageWorking.youmustchangeyourpasswordforyour | translate}}
        <strong>{{languageWorking.security | translate}}.</strong>
    </h6>
    <form class="example-container" [formGroup]="passWord">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>{{languageWorking.newpassword | translate}}</mat-label>
            <input matInput [type]="hideNewP ? 'password' : 'text'" formControlName="passwordNew" minlength="6"
                required>
            <button mat-icon-button matSuffix (click)="hideNewP = !hideNewP" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hideNewP">
                <mat-icon>{{hideNewP ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>{{languageWorking.confirmpassword | translate}}</mat-label>
            <input matInput [type]="hideConP ? 'password' : 'text'" formControlName="passwordConF" required>
            <button mat-icon-button matSuffix (click)="hideConP = !hideConP" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hideConP">
                <mat-icon>{{hideConP ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <div *ngIf="passNew != PassCon" class="bounce alert alert-danger" role="alert">
            * {{languageWorking.passwordsdonotmatch | translate}}
        </div>
    </form>
    <button mat-raised-button class="save" [disabled]="!passWord.valid"
        (click)="addNewPassword()">{{languageWorking.savechang | translate}}</button>
</div>