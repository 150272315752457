import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";

@Component({
  selector: "app-close-call",
  templateUrl: "./close-call.component.html",
  styleUrls: ["./close-call.component.scss"],
})
export class CloseCallComponent implements OnInit {
  datatranslatechat: any;
  constructor(
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService,
  ) {
    this.translate.use(localStorage.getItem("lang"));
  }

  ngOnInit() {
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslatechat = localStorage.getItem("lang");
    });
  }
}
