import { Injectable, OnInit } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class OneSignalService implements OnInit {
  OneSignal: any;
  constructor() {}

  ngOnInit(): void {}

  async onLoad() {
    var OneSignal = window["OneSignal"] || [];
    // console.log("Init OneSignal");
    OneSignal.push([
      "init",
      {
        appId: "5708c7aa-9757-4aa8-a3ae-9c1782798d03",
        autoRegister: false,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: false,
        },
      },
    ]);
    // return OneSignal;
  }

  async onLoadPlayerID(): Promise<any> {
    return new Promise(async (resolve) => {
      var OneSignal = window["OneSignal"] || [];
      OneSignal.push(async function () {
        /* These examples are all valid */
        await OneSignal.getUserId(function (userId) {
          // console.log("OneSignal User ID:", userId);

          resolve(userId);
          // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
        });
      });
    });
  }
}
