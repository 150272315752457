import { NgModule } from "@angular/core";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { RightSidebarComponent } from "./layout/right-sidebar/right-sidebar.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { fakeBackendProvider } from "./core/interceptor/fake-backend";
import { ErrorInterceptor } from "./core/interceptor/error.interceptor";
import { JwtInterceptor } from "./core/interceptor/jwt.interceptor";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ClickOutsideModule } from "ng-click-outside";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { PharmacyRoutingModule } from "./pharmacy/pharmacy-routing.module";
import { MatBadgeModule } from "@angular/material/badge";
import { ToastrModule } from "ngx-toastr";
import { NgImageSliderModule } from "ng-image-slider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { TimeStartDialogComponent } from "./layout/header/dialogs/time-start-dialog/time-start-dialog.component";
import { ChangPasswordDialogComponent } from "./layout/header/dialogs/chang-password-dialog/chang-password-dialog.component";
import { JitsiComponent } from "./jitsi/jitsi/jitsi.component";
import { CallComponent } from "./jitsi/jitsi/dialog/call/call.component";
import { CloseCallComponent } from "./jitsi/jitsi/dialog/close-call/close-call.component";
import { DatePipe } from "@angular/common";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { IvyGalleryModule } from "angular-gallery";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";



const config: SocketIoConfig = {
  url: "https://connect.medilifewellness.com:2422/",
  options: { transports: ["websocket", "polling", "flashsocket"] },
};
// const config: SocketIoConfig = { url: 'http://localhost:2022/', options: {transports: ['websocket', 'polling', 'flashsocket']} }; //local

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    TimeStartDialogComponent,
    ChangPasswordDialogComponent,
    JitsiComponent,
    CallComponent,
    CloseCallComponent,
  ],
  imports: [
    MatBadgeModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    // core & shared
    CoreModule,
    SharedModule,
    PharmacyRoutingModule,
    IvyGalleryModule,
    NgImageSliderModule,
    MatDialogModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    SocketIoModule.forRoot(config),

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    DatePipe,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
