<div class="auth-container">
  <div class="row auth-main">
    <div class="col-sm-12 px-0">
      <div class=" left-img" style="background-image: url(assets/images/Nurse.jpg)">
        <div class="form-section">
          <div class="auth-wrapper ">
            <div style="text-align: center;margin-bottom: 30px;">
              <img src="assets/images/logo/RuxLogo-EN.png" alt="" width="200px">
              <h2 class="welcome-msg" style="margin-top: 20px;"><strong>{{datatranslatechat == "en" ?
                  'Thank-you':'ขอบคุณ'}}</strong></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>