import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { Language } from 'src/app/doctor/interface/language_sym/Language-item';
import { AdminSettingService } from './admin-setting.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-setting',
  templateUrl: './admin-setting.component.html',
  styleUrls: ['./admin-setting.component.scss']
})
export class AdminSettingComponent implements OnInit {

  languageItems: any;
  hidePassword1: boolean = true;
  hidePassword2: boolean = true;
  adminId: number;
  admin: FormGroup;
  adminData: any;
  transLang: any;

  constructor(
    public dialogRef: MatDialogRef<AdminSettingComponent>,
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private adminSettingService: AdminSettingService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    this.adminId = JSON.parse(localStorage.getItem("currentUser")).id;
    this.admin = this.adminForm(false);
  }

  ngOnInit(): void {
    this.languageItems = Language[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.transLang = localStorage.getItem("lang");
    });
    this.getAdmin(this.adminId);
  }

  adminForm(isData: boolean) {
    return this.fb.group({
      username: [!isData ? "" : this.adminData.view_Username, [Validators.required, Validators.minLength(4)]],
      password: ["", [Validators.minLength(6)]],
      passwordConfirm: ["", [Validators.minLength(6)]],
    });
  }

  getAdmin(adminId: number) {
    this.adminSettingService.GetAdminViewOnlyListById_(adminId).then((res) => {
      if (res.status) {
        this.adminData = res.data;
        this.admin = this.adminForm(true);
      }
    }, (err) => {
      console.log("Error -> ", err);
    });
  }

  putAdmin() {
    if (this.admin.getRawValue().password != this.admin.getRawValue().passwordConfirm) {
      Swal.fire({
        title: this.transLang == "en" ? "Password not match." : "รหัสผ่านไม่ตรงกัน",
        text: this.transLang == "en" ? "Password and Confirm Password are not same." : "รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: "OK"
      })
    } else {
      Swal.fire({
        title: this.transLang == "en" ? "Update?" : "อัปเดค?",
        text: this.transLang == "en" ? "Are you sure to update?" : "คุณต้องการอัปเดคหรือไม่?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          var putData: any = {
            viewId: this.adminId,
            viewUsername: this.admin.getRawValue().username,
            viewPassword: this.admin.getRawValue().password
          }
          this.adminSettingService.UpdateAdminViewOnly_(putData).then((res) => {
            if (res.status) {
              Swal.fire({
                title: this.transLang == "en" ? "Update!" : "อัปเดค!",
                text: this.transLang == "en" ? "Admin has been updated." : "แก้ไขข้อมูลผู้ดูแลระบบแล้ว!",
                icon: "success",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
              this.dialogRef.close();
            }
          }, (err) => {
            console.log("Error -> ", err);
          });
        }
      });
    }
  }

}
