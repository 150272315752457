export class GlobalConstants {
  public static REST_API_SW: string = "https://api.medilifewellness.com:1243";

  public static REST_API_CHAT: string =
    "https://connect.medilifewellness.com:4523";

  // public static  REST_API_TELE: string = 'http://43.225.141.52:2077';

  // public static REST_API_TELE: string = "https://localhost:5001"; //local
  public static REST_API_TELE: string = "https://api.medilifewellness.com:2477";

  // public static  REST_API_TELE: string = 'http://releep.achatsocial.com:2077';
  // public static REST_API_TELE: string = 'https://localhost:44398'; //local

  public static URL_PART_2021: string = "https://api.medilifewellness.com:2443";
  // public static URL_PART_2021: string = "http://releep.achatsocial.com:2021";

  public static REST_API_HEALTH: string =
    // "https://dashboard.medilifewellness.com";
    "https://personal.medilifewellness.com";

  public static REST_API_PDF: string =
    "https://personal.medilifewellness.com";
}
