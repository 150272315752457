<div class="auth-container">
    <div class="row auth-main">
        <div class="col-sm-6 px-0 d-none d-sm-block">
            <div class="left-img" style="background-image: url(assets/images/pages/bg-04.png);">
            </div>
        </div>
        <div class="col-sm-6 auth-form-section">
            <div class="form-section">
                <div class="auth-wrapper">
                    <form>
                        <span class="error-header p-b-45">
              404
            </span>
                        <span class="error-subheader p-b-5">
              Looks Like You're Lost
            </span>
                        <span class="error-subheader2 p-b-5">
              The Page You Are Looking For Not Available!
            </span>
                        <div class="container-auth-form-btn mt-5">
                            <button mat-flat-button color="primary" class="auth-form-btn" type="submit">
                Go To Home Page
              </button>
                        </div>
                        <div class="w-full p-t-15 p-b-15 text-center">
                            <div>
                                <a routerLink="/authentication/signin" class="txt1">
                  Need Help?
                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>