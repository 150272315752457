import { Component, OnInit } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from "@angular/common";
import { AuthService } from "./core/service/auth.service";
import { OneSignalService } from "./common/one-signal.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  currentUrl: string;
  constructor(
    private os: OneSignalService,
    private authService: AuthService,
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService
  ) {
    //set theme orange
    localStorage.setItem("choose_skin", "theme-green");
    localStorage.setItem("choose_skin_active", "green");

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });

    _router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event["url"] == "/authentication/signin" || event["url"] == "/") {
          if (localStorage.getItem("currentUser")) {
            // this._router.navigate([`admin/dashboard/main`]);
            // this.checkLogin();
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.checkLogin();
    // ------------------------------------------------------------ห้ามลบ--------------------------------------------------------------------
    // this.os.onLoad();
    // var OneSignal = window['OneSignal'] || [];
    // // console.log("Init OneSignal");
    // OneSignal.push(["init", {
    //   appId: "5708c7aa-9757-4aa8-a3ae-9c1782798d03",
    //   autoRegister: false,
    //   allowLocalhostAsSecureOrigin: true,
    //   notifyButton: {
    //     enable: false
    //   }
    // }]);

    // OneSignal.push(function () {
    //   /* These examples are all valid */
    //   OneSignal.getUserId(function (userId) {
    //     console.log("OneSignal User ID:", userId);
    //     // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
    //   });
    // });

    // OneSignal.push(function () {
    //   OneSignal.on('notificationDisplay', function (event) {
    //     console.warn('OneSignal notification displayed:', event);
    //   });
    // });
    // ------------------------------------------------------------ห้ามลบ--------------------------------------------------------------------
  }

  checkLogin() {
    var dataLocal = localStorage.getItem("currentUser");

    if (dataLocal != null) {
      var data_ = JSON.parse(dataLocal);
      // console.log("token_.token ",data_.token);

      this.authService
        .CheckToken(data_.token)
        .toPromise()
        .then((res) => {
          // if (res == null) {
          //   this.showHead = false;
          //   // alert("API Connection Error!")
          //   this._snackBar.openFromComponent(ConnectApiErrorComponent, {
          //     horizontalPosition: this.horizontalPosition,
          //     verticalPosition: this.verticalPosition,
          //     // duration: this.durationInSeconds * 1000,
          //   });
          // }
          if (res != null) {
            if (
              res.username == data_.username &&
              res.status == true &&
              res.id == data_.id &&
              res.role == data_.role
            ) {
              // this.router.navigate([`dashboard/${localStorage.getItem("currentIdAdmin")}`]);
              if (res.role == "Admin") {
                this._router.navigate([`admin/dashboard/main`]);
              }
              if (res.role == "Doctor") {
                // this._router.navigate([`doctor/dashboard/${res.id}`]);
                this._router.navigate([`doctor/menu/${res.id}`]);
              }
              if (res.role == "Nutritionist") {
                // this._router.navigate([`doctor/dashboard/${res.id}`]);
                this._router.navigate([`nutritionist/menu/${res.id}`]);
              }
              if (res.role == "Pharmacy") {
                this._router.navigate([`pharmacy/dashboard/${res.id}`]);
              }
              if (res.role == "Patient") {
                this._router.navigate([`patient/dashboard`]);
              }
              if (res.role == "Dealer") {
                this._router.navigate([`dealer/dashboard/${res.id}`]);
              }
              if (res.role == "Distributor") {
                this._router.navigate([`distributor/dashboard/${res.id}`]);
              }
              if (res.role == "TelSupport") {
                this._router.navigate([`telSupport/main/${res.id}`]);
              }
              if (res.role == "Audit") {
                this._router.navigate([`audit/dashboard/${res.id}`]);
              }
            } else {
              localStorage.removeItem("currentUser");
              localStorage.removeItem("onesignal-allow");
              localStorage.removeItem("changTimes");
              this._router
                .navigate(["/authentication/signin"], { replaceUrl: true })
                .then();
            }
          } else {
            console.log("Error res");
          }
        });
    }
  }
}
