export class TransferModel {
  recptNo: number;
  transferStutus: boolean;
  transferNote: string;

  constructor(transfers) {
    {
      this.recptNo = transfers.recptNo || "";
      this.transferStutus = transfers.transferStutus || "";
      this.transferNote = transfers.transferNote || "";
    }
  }
  public getRandomID(): string {
    const S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4();
  }
}
