export class OrderDrugListModel {
  recptNo: number;
  trackingNo: string;

  constructor(orders) {
    {
      this.recptNo = orders.recptNo || "";
      this.trackingNo = orders.trackingNo || "";
    }
  }
}
