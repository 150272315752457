import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { GlobalConstants } from "src/app/common/global-constants";
import { Socket } from "ngx-socket-io";

@Component({
  selector: "app-call",
  templateUrl: "./call.component.html",
  styleUrls: ["./call.component.scss"],
})
export class CallComponent implements OnInit {
  urlPath2021 = GlobalConstants.URL_PART_2021;
  dataCall: any;
  timerCountDown: any;
  call: boolean = true;
  constructor(
    private socket_: Socket,
    public dialogRef: MatDialogRef<CallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log("---- ",data);
    this.dataCall = data;
  }

  ngOnInit() {
    this.timerCountDown = setInterval(this.playAudioCall, 2300);
    var endCall = true;
    this.socket_.on("call_pharmacy", (dataCall: any) => {
      // console.log("dataCall ", dataCall);
      if (
        dataCall.PharmacyConvoIsTalking == "False" &&
        dataCall.HangUp == "user"
      ) {
        // this.onClickCancel();
        if (endCall) {
          clearInterval(this.timerCountDown);
          setTimeout(() => {
            this.call = false;
            this.playAudioCallEnd();
          }, 1000);
          setTimeout(() => {
            this.dialogRef.close();
          }, 30000);
          endCall = false;
        }
      }
    });

    this.socket_.on("dataMessagePharmacyAnswertheCall", (data: any) => {
      // console.log("dataMessagePharmacyAnswertheCall ", data);
      clearInterval(this.timerCountDown);
      // this.playAudioCallEnd();
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);
    });
  }

  onClickConfirm() {
    clearInterval(this.timerCountDown);
    this.socket_.emit("Pharmacy-AnswertheCall", {
      dataCall: this.dataCall,
      status: "AnswertheCall",
    });
    this.dialogRef.close(true);
  }

  onClickCancel() {
    clearInterval(this.timerCountDown);
    this.socket_.emit("Pharmacy-AnswertheCall", {
      dataCall: this.dataCall,
      status: "NotAnsweringtheCall",
    });
    this.playAudioCallEnd();
    this.dialogRef.close();
  }

  playAudioCall() {
    let audio = new Audio();
    audio.src = "assets/sound/call-v2.wav";
    audio.load();
    audio.play();
  }

  playAudioCallEnd() {
    let audio = new Audio();
    audio.src = "assets/sound/call-end.wav";
    audio.load();
    audio.play();
  }
}
