import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { catchError, map, timeout } from "rxjs/operators";
import { User } from "../models/user";
import { GlobalConstants } from "src/app/common/global-constants";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  REST_API: string = GlobalConstants.REST_API_TELE;

  //Http header
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(dara_: any) {
    let API_URL = `${this.REST_API}/api/telemedicine/login/Login`;
    return this.httpClient
      .post<any>(API_URL, dara_, {
        headers: this.httpHeaders,
        responseType: "json",
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // console.log("user ",user);
          // if(user.status == true) {
          localStorage.setItem("currentUser", JSON.stringify(user));
          if (user.role == "Doctor") {
            localStorage.setItem("onesignal-allow", "false");
          }
          this.currentUserSubject.next(user);
          return user;

          // }else{
          //   return "Username or password is incorrect";
          // }
        }),
        catchError((e) => {
          return of(null);
        })
      );
    // return this.httpClient
    //   .post<any>(`${environment.apiUrl}/authenticate`, {
    //     username,
    //     password,
    //   })
    //   .pipe(
    //     map((user) => {
    //       // store user details and jwt token in local storage to keep user logged in between page refreshes
    //       console.log("user ",user);

    //       localStorage.setItem("currentUser", JSON.stringify(user));
    //       this.currentUserSubject.next(user);
    //       return user;
    //     })
    //   );
  }

  CheckToken(auth_token: any): Observable<any> {
    let API_URL = `${this.REST_API}/api/telemedicine/login/Info`;
    var data = this.httpClient.get(API_URL, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `bearer ${auth_token}`,
      }),
    });
    // console.log("data lll l ",data );

    return data.pipe(
      timeout(2000),
      map((res: any) => {
        // console.log("555555555555+ ", res);

        return res || {};
      }),
      catchError((e) => {
        return of(null);
      })
    );
  }

  // CheckToken(auth_token: any) {
  //   return new Promise((resolve, reject) => {
  //     let API_URL = `${this.REST_API}/api/telemedicine/login/Info`;
  //     this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${auth_token}` , responseType: "json" }) })
  //       .subscribe(data => {
  //         resolve(data);
  //       },
  //         error => {
  //           reject(error);
  //         });
  //   });
  // }

  GetConvosation_(nuId: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/conversation/GetConvosationRequsetNutritionistById?nttnId=${nuId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetConvosationDoc_(drId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/conversation/GetConvosationRequsetDoctorById?drId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetConvosationByIdCon_(conId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/conversation/ConversationById?converId=${conId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  Readconversation_(idConver: string, read: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/conversation/Readconversation?idConver=${idConver}&read=${read}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ReadOrder_(idOrder: string, read: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/order/UpdateNotiReadOrder?idOrder=${idOrder}&statusRead=${read}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetUserByID_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/user/GetUserByID?idUser=${idUser}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertPlayerIDLogin_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/login/InsertPlayerIDLogin`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  logout(nameDoctor: string, status: boolean, role: string) {
    // remove user from local storage to log user out
    // localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    // return of({ success: false });
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/telemedicine/login/UpdatastatusLogin?nameDoctor=${nameDoctor}&status=${status}&role=${role}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
