import { Injectable } from "@angular/core";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { GlobalConstants } from "src/app/common/global-constants";

@Injectable({
  providedIn: "root",
})
export class DoctorTotalIncomeService extends UnsubscribeOnDestroyAdapter {
  u_Token?: string;

  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private httpClient: HttpClient) {
    super();
    var dataToken = JSON.parse(localStorage.getItem("currentUser"));
    if (dataToken != null) {
      this.u_Token = dataToken.token;
    }
  }

  GetDoctorTotalIncome_(monthID: string, idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetMonthlyIncome?type=${monthID}&idDoctor=${idUser}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDoctorList_(idDoctor: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetDoctorList?idDoctor=${idDoctor}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetUserByID_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/user/GetUserByID?idUser=${idUser}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDoctorIncomelastMonthCompareCurrentMonth_(doctorId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetDoctorIncomelastMonthCompareCurrentMonth?DoctorID=${doctorId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDoctorRaiting_(doctorId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetDoctorRaiting?DoctorID=${doctorId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetNutritionistTotalIncome_(monthID: string, idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/GetMonthlyIncomeNutritionist?type=${monthID}&idNutritionis=${idUser}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetNutritionistList_(idNutritionist: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/GetNutritionistList?idNutritionis=${idNutritionist}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetNutritionistIncomelastMonthCompareCurrentMonth_(nutritionistId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/GetNutritionistIncomelastMonthCompareCurrentMonth?idNutritionist=${nutritionistId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetNutritionistRating_(nutritionistId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/GetNutritionistRaiting?idNutritionist=${nutritionistId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
