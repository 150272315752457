<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">{{languagePharmacy.c_chat | translate}}</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a href="javascript:void(0)">
                <i class="fas fa-home"></i> {{languagePharmacy.home | translate}}</a>
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="javascript:void(0)">{{languagePharmacy.pharmacy | translate}}</a>
            </li>
            <li class="breadcrumb-item active">{{languagePharmacy.c_chat | translate}}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!LoadingChatTOPhamacy">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="card">
          <div class="body">
            <div id="plist" class="people-list">
              <div class="form-line m-b-15">
                <input type="text" class="form-control" [(ngModel)]="searchText"
                  placeholder="{{languagePharmacy.c_search | translate}}">
                <!-- <input type="text" class="form-control" matInput #movieSearchInput placeholder="Search...." /> -->
              </div>
              <div class="tab-content">
                <div id="chat_user" class="chatUser" [perfectScrollbar]>
                  <ul class="chat-list list-unstyled m-b-0"
                    *ngIf="( dataAppointmentsHistory | filter : searchText) as result">
                    <li class="clearfix " *ngFor="let itemAppointmentHistory of result"
                      (click)="onClickChatUser(itemAppointmentHistory.dataUser.user_ID,itemAppointmentHistory.dataUser.user_Pic,itemAppointmentHistory.dataUser.user_Name,itemAppointmentHistory.new_msg)">
                      <div style="float: left;margin-left: -4px;" *ngIf="itemAppointmentHistory.new_msg != '0'">
                        <!-- && clickChat != true && this.userId -->
                        <span id="mat-badge-content-0"
                          style="background-color: red;width: 22px;height: 22px;border-radius: 50%;padding-top: 2px;"
                          aria-hidden="true"
                          class="mat-badge-content mat-badge-active">{{itemAppointmentHistory.new_msg}}</span>
                      </div>
                      <img src="{{urlPath2021}}{{itemAppointmentHistory.dataUser.user_Pic}}" width="45px" height="45px"
                        style="object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"
                        alt="avatar user">
                      <div class="about">
                        <div class="name textLastName">{{itemAppointmentHistory.dataUser.user_Name}}</div>
                        <div class="status textLastMsg" *ngIf="itemAppointmentHistory.lastHistory.last_msg">
                          {{checkMSG(itemAppointmentHistory.lastHistory.last_msg)}}
                        </div>
                      </div>
                      <div class="about" style="float: right;padding-top: 3px;">
                        <div style="color: rgb(196, 194, 194);font-size: 12px;">
                          <div class="timeAgo">{{itemAppointmentHistory.time | timeago:live}}</div>
                        </div>
                      </div>

                    </li>
                    <p *ngIf="result.length == 0">{{languagePharmacy.c_noresult | translate}}</p>
                    <!-- <li class="clearfix active">
                        <img src="assets/images/user/user1.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">William Smith</div>
                          <div class="status">
                            <i class="material-icons offline">fiber_manual_record</i>
                            left 7 mins ago </div>
                        </div>
                      </li>
                      <li class="clearfix ">
                        <img src="assets/images/user/user2.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Martha Williams</div>
                          <div class="status">
                            <i class="material-icons offline">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user3.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Joseph Clark</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user4.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Nancy Taylor</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user5.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Margaret Wilson</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user6.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Joseph Jones</div>
                          <div class="status">
                            <i class="material-icons offline">fiber_manual_record</i>
                            left 30 mins ago </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user7.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Jane Brown</div>
                          <div class="status">
                            <i class="material-icons offline">fiber_manual_record</i>
                            left 10 hours ago </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user8.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Eliza Johnson</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user3.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Mike Clark</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user4.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Ann Henry</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user5.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">Nancy Smith</div>
                          <div class="status">
                            <i class="material-icons online">fiber_manual_record</i>
                            online </div>
                        </div>
                      </li>
                      <li class="clearfix">
                        <img src="assets/images/user/user9.jpg" alt="avatar">
                        <div class="about">
                          <div class="name">David Wilson</div>
                          <div class="status">
                            <i class="material-icons offline">fiber_manual_record</i>
                            offline since Oct 28 </div>
                        </div>
                      </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <div class="card">
          <div class="chat">
            <div class="chat-header clearfix" *ngIf="clickChat == true">
              <img src="{{urlPath2021}}{{userImg}}" alt="avatar" width="45px" height="45px" style="object-fit: cover;"
                onerror="this.src='assets/images/imgdoctor/nouser.svg'">
              <div class="chat-about">
                <div class="chat-with">{{userName}}</div>
                <div class="status">
                  <i class="material-icons online">fiber_manual_record</i>
                  online
                </div>
              </div>
            </div>
            <mat-progress-bar *ngIf="loadChat" mode="query"></mat-progress-bar>


            <div fxLayout="column" fxLayoutAlign="stretch">
              <div class="content-container" fxLayout="column" fxLayoutAlign="stretch" id="chatView">
                <perfect-scrollbar [perfectScrollbar] *ngIf="type === 'component'" class="scroll-container"
                  fxFlex="auto" [config]="config" [scrollIndicators]="true" (psXReachEnd)="onScrollEvent($event)"
                  (psYReachEnd)="onScrollEvent($event)" (psXReachStart)="onScrollEvent($event)"
                  (psYReachStart)="onScrollEvent($event)">
                  <div class="scrollable-content">
                    <div class="chat-history">
                      <div *ngIf="loadChat" style="text-align: center;margin-top: 80px;margin-bottom: 80px;">
                      </div>
                      <ul>
                        <li class="clearfix" *ngFor="let itemHistoryChat of dataHistoryChats">
                          <div *ngIf="itemHistoryChat.sender_id == idPharmacy">
                            <div class="message-data text-end">
                              <span class="message-data-time">{{itemHistoryChat.timestamp | timeago:live}}
                              </span>
                              &nbsp; &nbsp;
                              <span class="message-data-name">{{itemHistoryChat.sender_name}}</span>
                            </div>
                            <div class="message other-message float-end"
                              *ngIf="itemHistoryChat.msg != '' && itemHistoryChat.msg != null">
                              {{itemHistoryChat.msg}}</div>
                            <div class="my-message-img-video-sticker float-end"
                              *ngIf="itemHistoryChat.media_type == 'Images'" style="text-align: end;">
                              <span *ngFor="let itemImg of itemHistoryChat.media_path; let i = index">
                                <img class="border-select" src="{{url}}/{{itemImg}}"
                                  (click)="showGallery(i,itemHistoryChat.media_path,url)" alt="chat-image"
                                  style="max-height: 260px;max-width: 220px;padding: 5px;">
                              </span>
                            </div>
                          </div>

                          <div *ngIf="itemHistoryChat.sender_id != idPharmacy && itemHistoryChat.sender_id == userId">
                            <div class="message-data">
                              <span class="message-data-name">{{itemHistoryChat.sender_name}}</span>
                              <span class="message-data-time">{{itemHistoryChat.timestamp | timeago:live}}</span>
                            </div>
                            <div class="message my-message"
                              *ngIf="itemHistoryChat.msg != '' && itemHistoryChat.msg != null">
                              <p style="padding: 0px;margin: 0px;">{{itemHistoryChat.msg}}</p>
                            </div>
                            <div class="my-message-img-video-sticker" *ngIf="itemHistoryChat.media_type == 'Images'">
                              <span *ngFor="let itemImg of itemHistoryChat.media_path; let i = index">
                                <img class="border-select" src="{{itemImg}}"
                                  (click)="showGallery(i,itemHistoryChat.media_path,'')" alt="chat-image"
                                  style="max-height: 260px;max-width: 220px;padding: 5px;">
                              </span>
                            </div>
                            <div class="my-message-img-video-sticker" *ngIf="itemHistoryChat.media_type == 'Sticker'">
                              <img class="border-select" src="{{itemHistoryChat.media_path}}" alt="chat-sticker"
                                style="max-height: 260px;max-width: 220px;padding: 5px;">
                            </div>
                            <div class="my-message-img-video-sticker" *ngIf="itemHistoryChat.media_type == 'Video'">
                              <video controls (click)="toggleVideo()" #videoPlayer
                                style="max-height: 260px;max-width: 220px;padding: 5px;">
                                <source src="{{itemHistoryChat.media_path}}" type="video/mp4" />
                              </video>
                            </div>
                            <div class="my-message-img-video-sticker" *ngIf="itemHistoryChat.media_type == 'File'">
                              <a href="{{itemHistoryChat.media_path}}" download>
                                <img src="assets\images\folder.png" alt="icon-file" style="width: 150px;">
                              </a>
                            </div>
                            <div class="my-message-img-video-sticker" *ngIf="itemHistoryChat.media_type == 'Audio'">
                              <audio controls>
                                <source src="{{itemHistoryChat.media_path}}" type="audio/mp4">
                              </audio>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <p *ngIf="dataHistoryChats.length < 1 && clickChat == true" style="text-align: center">
                        {{languagePharmacy.c_start | translate}}</p>
                      <p *ngIf="clickChat == false" style="text-align: center">{{languagePharmacy.c_person | translate}}
                      </p>

                    </div>

                  </div>
                </perfect-scrollbar>

              </div>
            </div>

            <div class="chat-message clearfix" *ngIf="clickChat == true " style="padding-top:0px">
              <div class="form-group" style="margin-bottom: 0px;">
                <div class="form-line">
                  <div class="col-12">
                    <mat-card style="background: #fff3eac4;" *ngIf="previews.length > 0">
                      <div style="text-align: end">
                        <button mat-icon-button (click)="onClickClearImg()">
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                      <div class="image-preview row" style="display: flex;justify-content: center;">
                        <img *ngFor='let preview of previews' [src]="preview" class="image-preview"
                          style="max-width: 240px;padding: 5px;" alt="img-chat">
                      </div>
                    </mat-card>
                  </div>
                  <div style="display: flex;">
                    <form [formGroup]="chartForm" style="width: -webkit-fill-available;">
                      <mat-form-field class="example-full-width" appearance="outline"
                        [hideRequiredMarker]="hideRequiredControl.value" [floatLabel]="false">
                        <input matInput formControlName="textChat" placeholder="{{languagePharmacy.c_text | translate}}"
                          required (keyup.enter)="onEnter()">
                        <mat-error *ngIf="chartForm.get('textChat') == 0">
                          {{languagePharmacy.c_heading | translate}}
                        </mat-error>
                      </mat-form-field>
                    </form>
                    <span class="input-group-append"
                      style="margin-top: 10px;margin-left: 5px;width: 200px;text-align: center;">
                      <button mat-mini-fab class="bg-deep-orange msr-2" (click)="fileInput.click()"> <i
                          class="material-icons">photo_library</i>
                        <input type="file" #fileInput accept="image/*" multiple (change)="selectFiles($event)"
                          style="display:none;" accept="image/png, image/jpeg" />
                      </button>
                      <button mat-raised-button color="warn" class="deleteBot" (click)="sendMsgbtn()">
                        <mat-icon>send</mat-icon> {{languagePharmacy.c_send | translate}}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="LoadingChatTOPhamacy" class="card" style="background-color: white;height: 600px;">
      <div style="margin: auto;text-align: center;">
        <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300" alt="loadChat">
        <p>{{languagePharmacy.c_loading | translate}}</p>
      </div>
    </div>
  </div>
</section>