<section class="content">
  <div class="container-fluid"
    *ngIf="languageItemsAdminAllPharmacy != null && languageItemsAdminAllPharmacy != '' && languageItemsAdminAllPharmacy != undefined && languageItemsAdminAllPharmacy != []">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">{{languageItemsAdminAllPharmacy.settings | translate}}</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a href="javascript:void(0)">
                <i class="fas fa-home"></i> {{languageItemsAdminAllPharmacy.home | translate}}</a>
            </li>
            <li class="breadcrumb-item active">{{languageItemsAdminAllPharmacy.settings | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card" style="padding: 20px;">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{languageItemsAdminAllPharmacy.account | translate}}">
          <mat-card *ngIf="datapharmacys != null">
            <div class="row" style="padding-bottom: 30px;">
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-12">
                <mat-card class="example-card backMedicine">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12">
                        <div class="row ng-star-inserted"
                          style="display: flex;justify-content: center;text-align: center;">
                          <div class="image-preview" *ngIf="previewUrl">
                            <img [src]="previewUrl"
                              style="border-radius: 50%;width: 120px;height: 120px;object-fit: cover;" />
                          </div>
                          <div *ngIf="noImg">
                            <img *ngIf="pharmacys != null" src="{{urlPath}}/{{pharmacys.phaR_Image}}"
                              class="mat-elevation-z8"
                              style="border-radius: 50%;width: 120px;height: 120px;object-fit: cover;border: 3px solid rgb(255, 255, 255);"
                              onerror="this.src='assets/images/imgdoctor/nouser.svg'" />
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12">
                        <h4 style="align-items: center;height: 6em;display: grid;text-align: center;">
                          <div class="row">
                            <b style="padding-bottom: 10px;color: white;">{{datapharmacys.phaR_Name}}</b>
                            <p style="font-size: 15px;color: rgb(197, 196, 196);">
                              {{languageItemsAdminAllPharmacy.pharmacys | translate}}</p>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1" style="display: flex;justify-content: center;">
                <div class="col-xs-2 v-divider" style="border-right: 1px solid  #333;"></div>
              </div>
              <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-12">
                <!-- <div class="col-12  text-center mt-5" *ngIf="dataLoading">
                  <div class="preloader">
                    <div class="spinner-layer pl-black">
                      <div class="circle-clipper left">
                        <div class="circle"></div>
                      </div>
                      <div class="circle-clipper right">
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="row" style="margin: 25px;">
                  <div class="col-xs-5 col-sm-12 col-md-12 col-lg-5 col-12">
                    <div class="media-title font-12" *ngIf="dataDrugShowAccount != null">
                      <i class="fas fa-store file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.allProducts | translate}}:&nbsp;
                      &nbsp;<b>{{dataDrugShowAccount.length}}</b> {{languageItemsAdminAllPharmacy.list | translate}}
                    </div>
                    <div class="media-title font-12" *ngIf="dataDrugShowAccount == null">
                      <i class="fas fa-store file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.allProducts | translate}}:&nbsp; &nbsp;<b>0</b>
                      {{languageItemsAdminAllPharmacy.list | translate}}
                    </div>
                    <div class="media-title font-12">
                      <i class="fas fa-video file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.video | translate}}:&nbsp; &nbsp;<b
                        style="vertical-align: sub;font-size: 23px;">∞</b> {{languageItemsAdminAllPharmacy.min |
                      translate}}
                    </div>
                    <div class="media-title font-12">
                      <i class="fas fa-comments file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.chat | translate}}:&nbsp; &nbsp;<b
                        style="vertical-align: sub;font-size: 23px;">∞</b> {{languageItemsAdminAllPharmacy.min |
                      translate}}
                    </div>
                  </div>
                  <div class="col-xs-7 col-sm-12 col-md-12 col-lg-7 col-12">
                    <div class="media-title font-12">
                      <i class="fas fa-users file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.allOrders | translate}}:&nbsp; &nbsp;<b>{{countOrderAll}}</b>
                      {{languageItemsAdminAllPharmacy.min | translate}}
                    </div>
                    <div class="media-title font-12">
                      <i class="fas fa-phone file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.phone | translate}} :&nbsp;
                      &nbsp;<b>{{datapharmacys.phaR_Phone}}</b>
                    </div>
                    <div class="media-title font-12">
                      <i class="fas fa-home file-style col-black font-18" style="margin: 10px;"></i>
                      {{languageItemsAdminAllPharmacy.location | translate}}:&nbsp; &nbsp;
                      <span style="margin-left: 99px;display: flex;margin-top: -24px;">
                        <b>{{datapharmacys.phaR_Address}}&nbsp;
                          &nbsp;{{datapharmacys.phaR_Subdistrict}}&nbsp;,
                          {{datapharmacys.phaR_District}}&nbsp;,{{datapharmacys.phaR_Province}}
                        </b></span>

                      <!-- <i class="fas fa-home file-style col-black font-18" style="margin: 10px;"></i>
                      Address:&nbsp; &nbsp;<b>{{datapharmacys.phaR_Address}}&nbsp;
                        &nbsp;{{datapharmacys.phaR_Subdistrict}}&nbsp;,
                        {{datapharmacys.phaR_District}}&nbsp;,{{datapharmacys.phaR_Province}}
                      </b> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <p style="text-align: center;" class="ex2"> {{datapharmacys.phaR_AboutPhamacy}}</p>
            <hr>
            <div style="padding-top: 30px;padding-bottom: 80px;" *ngIf="dataDrugShowAccount.length != 0">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-6">
                  <h4><b>{{languageItemsAdminAllPharmacy.recommendedProducts | translate}}</b></h4>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-6">
                  <button mat-button *ngIf="roleAdmin != 'Admin'" style="float: right;color: #51B1A5;"
                    routerLink="/pharmacy/drug-list/{{idPharmacy}}">{{languageItemsAdminAllPharmacy.allProducts |
                    translate}} ></button>
                  <button mat-button *ngIf="roleAdmin == 'Admin'" style="float: right;color: #51B1A5;"
                    routerLink="/admin/pharmacys/drug-list/{{idPharmacy}}">{{languageItemsAdminAllPharmacy.allProducts |
                    translate}} ></button>
                </div>
              </div>
              <div *ngIf="dataDrugShowAccount.length >= 5"
                style="width: 1218; height:240px; text-align:center;margin:0; padding:0;">
                <ng-image-slider #nav [images]="imageObject" [infinite]="true" [imagePopup]="false" [autoSlide]="2"
                  [imageSize]="{width: 337, height: 325}" slideImage="1"></ng-image-slider>
              </div>
              <div *ngIf="dataDrugShowAccount.length <= 4"
                style="width: 1218; height:240px; text-align:center;margin:0; padding:0;">
                <ng-image-slider #nav [images]="imageObject" [imagePopup]="false"
                  [imageSize]="{width: 337, height: 325}"></ng-image-slider>
              </div>
            </div>
          </mat-card>
        </mat-tab>
        <mat-tab label="{{languageItemsAdminAllPharmacy.settingAccount | translate}}">
          <div style="padding: 20px;">
            <div class="header">
              <h2>
                <strong>{{languageItemsAdminAllPharmacy.settings | translate}}</strong>
                {{languageItemsAdminAllPharmacy.account | translate}}
              </h2>
            </div>
            <div class="row">
              <div class="col-12 col-xl-4 col-lg-5 col-md-12 col-sm-12">
                <!-- img -->
                <div class="col-12 col-md-2" style="margin: auto;">
                  <div class="row" style="display: flex;justify-content: center;text-align: center;">
                    <div class="image-preview" *ngIf="previewUrl">
                      <img [src]="previewUrl"
                        style="border-radius: 50%;width: 160px;height: 160px;object-fit: cover;" />
                    </div>
                    <div *ngIf="noImg">
                      <img *ngIf="pharmacys != null" src="{{urlPath}}/{{pharmacys.phaR_Image}}" class="mat-elevation-z8"
                        style="border-radius: 50%;width: 160px;height: 160px;object-fit: cover;"
                        onerror="this.src='assets/images/imgdoctor/nouser.svg'" />
                    </div>
                    <div *ngIf="adminRole != 'AdminViewOnly'" style="margin-left: 195px;margin-top: -35px;">
                      <button mat-mini-fab (click)="fileInput.click()" style="background-color: #51B1A5;">
                        <mat-icon>photo_size_select_actual</mat-icon>
                        <input #fileInput type="file" name="image" (change)="fileProgress($event)" style="display:none;"
                          accept="image/png, image/jpeg" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- img -->
              </div>
              <div class="col-12 col-xl-8 col-lg-7 col-md-12 col-sm-12">
                <div mat-dialog-content style="overflow: hidden;">
                  <form class="register-form m-6" [formGroup]="pharmacyForm" (ngSubmit)="submit">
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.namePharmacy | translate}}</mat-label>
                          <input matInput formControlName="Name" required
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9ก-๙]/gi, '');">
                          <mat-icon matSuffix>store</mat-icon>
                          <mat-error *ngIf="pharmacyForm.get('Name').hasError('required')">
                            {{languageItemsAdminAllPharmacy.namePharmacyIsRequired | translate}}
                          </mat-error>
                          <mat-hint *ngIf="duplicateUsername" style="color: red;">
                            {{languageItemsAdminAllPharmacy.duplicateNamePharmacy | translate}} </mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.userName | translate}}</mat-label>
                          <input matInput formControlName="UserName" required minlength="4"
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9]/gi, '');">
                          <mat-icon matSuffix>account_circle</mat-icon>
                          <mat-error *ngIf="pharmacyForm.get('UserName').hasError('required')">
                            {{languageItemsAdminAllPharmacy.usernameIsRequired | translate}}
                          </mat-error>
                          <mat-hint *ngIf="duplicateUsername" style="color: red;">
                            {{languageItemsAdminAllPharmacy.duplicateUsername | translate}} </mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.email | translate}}</mat-label>
                          <input matInput formControlName="Email" required type="Email"
                            oninput="this.value = this.value.replace(/\s+|\s+$|\s+(?=\s)/g, '');">
                          <mat-icon matSuffix>account_circle</mat-icon>
                          <mat-error *ngIf="pharmacyForm.get('Email').hasError('required')">
                            {{languageItemsAdminAllPharmacy.emailIsRequired | translate}}
                          </mat-error>
                          <mat-hint *ngIf="duplicateEmail" style="color: red;">
                            {{languageItemsAdminAllPharmacy.duplicateEmail | translate}} </mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.enterYourPassword | translate}}</mat-label>
                          <input matInput [type]="hidePassword1 ? 'password' : 'text'" formControlName="Password"
                            oninput="this.value = this.value.replace(/^\s+|\s+$|\s+(?=\s)/g, '');">
                          <button mat-icon-button matSuffix (click)="hidePassword1 = !hidePassword1"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword1">
                            <mat-icon>{{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>

                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.confirmPassword | translate}}</mat-label>
                          <input matInput [type]="hidePassword2 ? 'password' : 'text'" formControlName="Password2">
                          <button mat-icon-button matSuffix (click)="hidePassword2 = !hidePassword2"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword2">
                            <mat-icon>{{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                          <mat-hint *ngIf="duplicatePassword" style="color: red;">
                            {{languageItemsAdminAllPharmacy.passwordsAreNotTheSame | translate}}
                          </mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.phone | translate}}</mat-label>
                          <input matInput #message maxlength="10" placeholder="Ex. I need help with..." required
                            minlength="10" formControlName="Phone"
                            oninput="this.value = this.value.replace(/^[1-9]/, '').replace(/^(\d[13470])/, '').replace(/[^0-9]/g, '');">
                          <mat-icon matSuffix>phone_in_talk</mat-icon>
                          <mat-hint align="start"><strong>{{languageItemsAdminAllPharmacy.cantFillMoreThan10 |
                              translate}} </strong> </mat-hint>
                          <mat-hint align="end">{{message.value.length}} / 10</mat-hint>
                          <mat-error *ngIf="pharmacyForm.get('Phone').hasError('required')">
                            {{languageItemsAdminAllPharmacy.numberIsRequired | translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.aboutPhamacy | translate}}</mat-label>
                          <input matInput formControlName="AboutPhamacy" required>
                          <mat-icon matSuffix>account_circle</mat-icon>
                          <mat-error *ngIf="pharmacyForm.get('AboutPhamacy').hasError('required')">
                            {{languageItemsAdminAllPharmacy.aboutPhamacyIsRequired | translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>ChatPerTime</mat-label>
                          <input matInput disabled  formControlName="ChatPerTime" >
                          <mat-icon matSuffix>question_answer</mat-icon>
                          <mat-error *ngIf="pharmacyForm.get('ChatPerTime').hasError('required')">
                            ChatPerTime is required
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>VideoPerTime</mat-label>
                          <input matInput disabled formControlName="VideoPerTime" >
                          <mat-icon matSuffix>videocam</mat-icon>
                        </mat-form-field>
                      </div> -->

                      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.address | translate}}</mat-label>
                          <textarea matInput formControlName="Address" required></textarea>
                          <mat-error *ngIf="pharmacyForm.get('Address').hasError('required')">
                            {{languageItemsAdminAllPharmacy.pleaseEnteraValidAddress | translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field appearance="outline" class="example-full-width">
                          <mat-label>{{languageItemsAdminAllPharmacy.province | translate}}</mat-label>
                          <mat-select required formControlName="Province">
                            <mat-option *ngFor="let province of provinces" [value]="province.viewValue"
                              (click)="dataDistrict(province.value)" (click)="onClickIDProvince(province.value)">
                              {{province.viewValue}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field appearance="outline" class="example-full-width">
                          <mat-label>{{languageItemsAdminAllPharmacy.district | translate}}</mat-label>
                          <mat-select required formControlName="District">
                            <mat-option *ngFor="let district of districts" [value]="district.viewValue"
                              (click)="dataSubDistrict(district.value)" (click)="onClickIDDistrict(district.value)">
                              {{district.viewValue}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field appearance="outline" class="example-full-width">
                          <mat-label>{{languageItemsAdminAllPharmacy.subdistricts | translate}}</mat-label>
                          <mat-select required formControlName="subDistrict">
                            <mat-option *ngFor="let subDistrict of subdistricts" [value]="subDistrict.viewValue"
                              (click)="onClickIDSubDistrict(subDistrict.value)">
                              {{subDistrict.viewValue}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>{{languageItemsAdminAllPharmacy.zipCode | translate}}</mat-label>
                          <input matInput formControlName="Zipcode" #message maxlength="5" minlength="5"
                            oninput="this.value = this.value.replace(/[^0-9]/gi, '');">
                          <!-- <mat-icon matSuffix>videocam</mat-icon> -->
                          <mat-error *ngIf="pharmacyForm.get('Zipcode').hasError('required')">
                            {{languageItemsAdminAllPharmacy.zipCodeIsRequired | translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row" *ngIf="adminRole != 'AdminViewOnly'">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="example-button-row">
                          <!-- <button mat-raised-button color="primary" *ngIf="action == 'add'" [type]="submit"
                        [disabled]="!pharmacyForm.valid" (click)="confirmAdd()">Save</button> -->
                          <button mat-raised-button color="primary" [disabled]="!pharmacyForm.valid" [type]="submit"
                            (click)="confirmUpdate()">{{languageItemsAdminAllPharmacy.update | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{languageItemsAdminAllPharmacy.settinPayment | translate}}"
          *ngIf="dataLocalUser.role == 'Admin'">
          <div class="header">
            <h2>
              <strong>{{languageItemsAdminAllPharmacy.settings | translate}}</strong>
              {{languageItemsAdminAllPharmacy.payment | translate}}
            </h2>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารกรุงเทพ'" style="display: flex;justify-content: center;">
                    <mat-card class="example-card cardBankBK">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: start;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-BK.png" style="width: 50px;height: 50px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-22"
                            style="color: rgb(41, 43, 145);text-align: center;padding-top: 8px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="text-align: start;color: black;padding-top: 90px;">
                        <p style="font-size: 20px;line-height: 15pt; text-shadow: -1px -1px rgb(187, 187, 187);">
                          {{cardId}}</p>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                          style="padding-right: 5px;text-align: right;">
                          <img src="assets/images/logoBank/logo-UnionPay.png" style="width: 80px;height: 60px;" />
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารกรุงไทย'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankKT">
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="text-align: start;display: flex;justify-content: center;">
                          <img src="assets/images/logoBank/logo-KT.png" style="width: 80px;height: 50px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2">
                          <div class="media-title font-20" style="color: rgb(255, 255, 255);padding-top: 8px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank">
                        <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="text-align: start;color: white;line-height: 10pt">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" id="chipBank"
                          style="padding-right: 5px;text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารกรุงศรีอยุธยา'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankKS">
                      <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mb-2">
                          <div class="media-title font-22" style="color: white;text-shadow: -1px -1px black;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-2"
                          style="text-align: end;padding-right: 8px;margin-top: -17px;">
                          <img src="assets/images/logoBank/logo-BS.png" style="width: 80px;height: 100px;" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2" id="chipBank">
                          <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2"
                          style="text-align: start;color: rgb(0, 0, 0);line-height: 10pt">
                          <h4 style="text-shadow: -1px -1px rgb(212, 212, 212);">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 5px;text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารกสิกรไทย'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankKSK">
                      <div class="row">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2"
                          style="color: white;text-align: end;justify-self: center;">
                          <div class="media-title font-22"><b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: start;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-Kbank.png" style="width: 50px;" />
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank">
                        <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="text-align: start;color: white;line-height: 10pt">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 5px;text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารเกียรตินาคิน'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankKNK">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: end;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-KNK.png" style="width: 60px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2" style="color: white;display: flex;">
                          <div class="media-title font-22"><b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2"
                          style="text-align: start;color: rgb(219, 219, 219);padding-left: 50px;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p style="color: white;">{{cardIdNameBank}}</p>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2" id="chipBank">
                          <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารซีไอเอ็มบี'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankCIMB">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: end;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-CIMB.jpg" style="width: 55px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-22" style="color: white;"><b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="text-align: center;display: flex;justify-content: center;padding-top: 10px;">
                          <img src="assets/images/bank/chip.jpg" id="chipBank"
                            style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2" style="text-align: start;color: white;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                        style="padding-right: 5px;padding-bottom: 20px; text-align: end;">
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารทหารไทย'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankTMB">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2" style="text-align: end;">
                          <img src="assets/images/logoBank/logo-TMB.png" style="width: 70px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-22" style="color: white;padding-left: 20px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="padding-top: 20px;">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="display: flex;justify-content: center;">
                          <img src="assets/images/bank/chip.jpg" id="chipBank"
                            style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2" style="text-align: start;color: white;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                        style="padding-right: 5px;padding-bottom: 20px; text-align: end;">
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="selectedbankNamedoc == 'ธนาคารทิสโก้'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankTSO">
                      <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mb-2">
                          <div class="media-title font-22" style="color: rgb(83, 83, 83);">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-2"
                          style="text-align: end;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-TISCO.png" style="width: 50px;" />
                        </div>
                      </div>
                      <img src="assets/images/bank/chip.jpg" id="chipBank"
                        style="border-radius: 7px;width: 39px;height: 39px;margin-left: 50px;" />
                      <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2"
                        style="padding-left: 50px;color: rgb(10, 10, 10);">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 5px;padding-bottom: 20px; text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารไทยพาณิชย์'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankTPN">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="text-align: end;padding-right: 10px;color: white;">
                        <div class="media-title font-22"><b>{{selectedbankNamedoc}}</b>
                          <img src="assets/images/logoBank/logo-SCB.png" style="width: 40px;" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="display: flex;justify-content: center;">
                          <img src="assets/images/bank/chip.jpg" id="chipBank"
                            style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2" style="text-align: start;color: white;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                        style="padding-right: 5px;padding-bottom: 20px; text-align: end;">
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="selectedbankNamedoc == 'ธนาคารธนชาต'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankTNC">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: end;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-TNC.png" style="width: 50px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-22" style="color: white;"><b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="padding-top: 20px;">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="display: flex;justify-content: center;">
                          <img src="assets/images/bank/chip.jpg" id="chipBank"
                            style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2" style="text-align: start;color: white;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                        style="padding-right: 5px;padding-bottom: 20px; text-align: end;">
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารนครหลวงไทย'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankNKL">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: end;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-NKL.png" style="width: 50px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-22" style="color: white;"><b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="padding-top: 10px;">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="display: flex;justify-content: center;">
                          <img src="assets/images/bank/chip.jpg" id="chipBank"
                            style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2" style="text-align: start;color: white;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                        style="padding-right: 5px;padding-bottom: 20px; text-align: end;">
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="selectedbankNamedoc == 'ธนาคารยูโอบี'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankUOB">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: start;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-UOB.png" style="width: 60px;height: 50px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-20" style="color: rgb(255, 255, 255);padding-top: 8px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg12 col-md-12 col-sm-12 mb-2" style="padding-left: 50px;"
                        id="chipBank">
                        <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="padding-left: 50px;text-align: start;color: white;line-height: 10pt">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 5px;text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารสแตนดาร์ดชาร์เตอร์ด'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankSDCT">
                      <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2"
                          style="text-align: start;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-STD.jpg" style="width: 40px;height: 50px;" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-20" style="color: rgb(255, 255, 255);padding-top: 8px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" style="padding-left: 50px;"
                        id="chipBank">
                        <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="padding-left: 50px;text-align: start;color: white;line-height: 10pt">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 5px;text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2"
                    *ngIf="selectedbankNamedoc == 'ธนาคารไอซีบีซี'" style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankICBC">
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2"
                          style="text-align: start;padding-right: 5px;">
                          <img src="assets/images/logoBank/logo-ICBC.jpg" style="width: 90px;height: 40px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2">
                          <div class="media-title font-20" style="color: rgb(255, 255, 255);padding-top: 8px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank">
                        <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="text-align: start;color: white;line-height: 10pt">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 5px;text-align: end;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="selectedbankNamedoc == 'ธนาคารออมสิน'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankAC">
                      <div class="row">
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2">
                          <div class="media-title font-22" style="color: rgb(255, 255, 255);padding-top: 8px;">
                            <b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2" style="text-align: start;">
                          <img src="assets/images/logoBank/logo-AC.png" style="width: 60px;" />
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank">
                        <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
                        style="text-align: start;color: white;line-height: 10pt">
                        <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                        <p>{{cardIdNameBank}}</p>
                      </div>
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" id="chipBank"
                          style="padding-right: 10px;text-align: end;margin-bottom: 10px;">
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 mb-2" *ngIf="selectedbankNamedoc == 'bank'"
                    style="display: flex; justify-content: center;">
                    <mat-card class="example-card cardBankOrang" style="padding-right: 10px;">
                      <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mb-2">
                          <div class="media-title font-18" style="color: white;"><b>{{selectedbankNamedoc}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-2">
                          <img src="assets/images/bank/chip.jpg" style="border-radius: 7px;width: 39px;height: 39px;" />
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 mb-2" style="text-align: start;color: white;">
                          <h4 style="text-shadow: -1px -1px black;">{{cardId}}</h4>
                          <p>{{cardIdNameBank}}</p>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mb-2" style="float: right;">
                    <form [formGroup]="bankPharForm" (ngSubmit)="submit">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>{{languageItemsAdminAllPharmacy.bankName | translate}}</mat-label>
                        <mat-select required [(value)]="selectedbankNamedoc"
                          (ngModelChange)="onSearchChangeBank($event)"
                          (selectionChange)="onChangeBank(selectedbankNamedoc)">
                          <mat-option *ngFor="let bankname of banks" [value]="bankname.value">
                            <img src="{{bankname.viewImg}}"
                              style="border-radius: 50%;width: 30px;height: 30px;object-fit: cover;">
                            &nbsp;&nbsp;
                            {{bankname.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{languageItemsAdminAllPharmacy.cardCode16Digits | translate}}</mat-label>
                        <input matInput #message maxlength="16" required formControlName="bankNo"
                          (input)="onSearchChangeCard($event.target.value)">
                        <mat-icon matSuffix>payment</mat-icon>
                        <mat-error *ngIf="bankPharForm.get('bankNo').hasError('required')">
                          {{languageItemsAdminAllPharmacy.cardCodeIsRequired | translate}}
                        </mat-error>
                        <mat-hint *ngIf="duplicateUsername" style="color: red;">
                          {{languageItemsAdminAllPharmacy.duplicateCardCode | translate}} </mat-hint>
                      </mat-form-field>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{languageItemsAdminAllPharmacy.accountName | translate}}</mat-label>
                        <input matInput required formControlName="AccountName"
                          (input)="onSearchChangeName($event.target.value)">
                        <mat-icon matSuffix>account_circle</mat-icon>
                        <mat-error *ngIf="bankPharForm.get('AccountName').hasError('required')">
                          {{languageItemsAdminAllPharmacy.accountNameIsRequired | translate}}
                        </mat-error>
                        <mat-hint *ngIf="duplicateUsername" style="color: red;">
                          {{languageItemsAdminAllPharmacy.duplicateAccountName | translate}} </mat-hint>
                      </mat-form-field>
                      <div *ngIf="adminRole != 'AdminViewOnly'" style="text-align: end;">
                        <button mat-raised-button color="primary"
                          style="background-color:#51B1A5;width: 80px;margin-left: 10px;"
                          [disabled]="!accId || !accName || isBtnAddBank || dataBankByIdPharAll.length > 0"
                          (click)="AddBank()">{{languageItemsAdminAllPharmacy.add | translate}}</button>
                        <!-- disabled="{{isBtnAddWorktime}}"  (click)="AddWorkingDoctor()" -->
                        <!-- disabled="{{isCheckSelectSkill}}" (click)="AddSkillDoctor()">Add</button> -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <div class="body">
                  <div class="row">
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-2"
                      *ngFor="let dataAddBankDoc of dataBankByIdPharAll;index as i" sty>
                      <li class="report-list" style="margin-bottom: 10px;height: 100px;">
                        <!-- <div class="d-flex"> -->
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-2">
                          <div class="media-title font-16"><b>{{dataAddBankDoc.banK_Name}} </b>
                            <i class="fas fa-university col-gray font-20"></i>
                          </div>
                          <div class="media-title font-12">{{languageItemsAdminAllPharmacy.accountName | translate}} :
                            <b style="font-size: 14px;">{{dataAddBankDoc.banK_AccountName}}</b>
                          </div>
                          <div class="media-title font-12">{{languageItemsAdminAllPharmacy.cardCode | translate}} :
                            <b style="font-size: 14px;">{{dataAddBankDoc.banK_No}}</b>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2">
                          <td *ngIf="adminRole != 'AdminViewOnly'">
                            <button mat-icon-button color="accent" class="btn-tbl-edit"
                              (click)="$event.stopPropagation()" (click)="editBank(dataAddBankDoc)">
                              <mat-icon aria-label="Edit" class="col-white">edit</mat-icon>
                            </button>
                            <button mat-icon-button color="accent" class="btn-tbl-delete"
                              (click)="$event.stopPropagation()" (click)="deleteBankDoctor(i, dataAddBankDoc.banK_Id)">
                              <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                            </button>
                          </td>
                        </div>
                      </li>
                      <h3 *ngIf="dataAddBankDoc == null">{{languageItemsAdminAllPharmacy.noDataPayment | translate}}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{languageItemsAdminAllPharmacy.location | translate}}">
          <app-location-pharmacy></app-location-pharmacy>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>
