import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { formatDate, Time } from "@angular/common";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "src/app/pharmacy/interface-pharmacy/language-item-pharmacy";

@Component({
  selector: "app-form-category",
  templateUrl: "./form-category.component.html",
  styleUrls: ["./form-category.component.scss"],
})
export class FormCategoryComponent implements OnInit {
  pharmacyCategoryForm: FormGroup;
  pharmacyCategory: any;
  languagePharmacy: any;
  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FormCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public pharmacyService: PharmacyService,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    this.pharmacyCategory = data;
    this.pharmacyCategoryForm = this.createContactForm(data);
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
  }

  createContactForm(checkData: any): FormGroup {
    if (checkData.action == "add") {
      return this.fb.group({
        categoryTH: [""],
        categoryEN: [""],
      });
    } else {
      return this.fb.group({
        categoryTH: [checkData.drugCategory.medcaT_NameTH],
        categoryEN: [checkData.drugCategory.medcaT_NameEN],
      });
    }
  }

  public confirmAddCategory(dataSlill: any): void {
    var dataAddSkill: any;
    var dataMedicineCate: any;
    // console.log('ข้อมูลที่ได้-->' , this.doctorSkillForm.getRawValue());
    if (dataSlill.action == "add") {
      dataAddSkill = {
        MEDCAT_NameTH: this.pharmacyCategoryForm.getRawValue().categoryTH,
        MEDCAT_NameEN: this.pharmacyCategoryForm.getRawValue().categoryEN,
      };
    } else if (dataSlill.action == "edit") {
      dataAddSkill = {
        MEDCAT_Id: dataSlill.drugCategory.medcaT_Id,
        MEDCAT_NameTH: this.pharmacyCategoryForm.getRawValue().categoryTH,
        MEDCAT_NameEN: this.pharmacyCategoryForm.getRawValue().categoryEN,
      };
    }
    // console.log("dataAddSkill ",dataAddSkill);

    this.pharmacyService
      .AddMedicineCateId_(dataAddSkill)
      .then((resMedicineCate) => {
        // console.log("resMedicineCate : ",resMedicineCate);
        dataMedicineCate = resMedicineCate;
        if (dataMedicineCate.status == true) {
          if (dataSlill.action != "edit") {
            this.dialogRef.close(resMedicineCate);
          } else {
            this.dialogRef.close(dataAddSkill);
          }
        } else {
          if (dataMedicineCate.msg == "Duplicate NameTH") {
            this.showNotification(
              "snackbar-warning",
              "Duplicate NameTH...!!!",
              "bottom",
              "center"
            );
          } else if (dataMedicineCate.msg == "Duplicate NameEN") {
            this.showNotification(
              "snackbar-warning",
              "Duplicate NameEN...!!!",
              "bottom",
              "center"
            );
          } else {
            this.dialogRef.close();
          }
        }
      });
  }

  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
}
