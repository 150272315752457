import { LanguageInterfaceHeader } from "./language-interface-header";

export const LanguageHeader: LanguageInterfaceHeader[] = [
  {
    working: "WORKING.WORKING",
    enableworking: "WORKING.ENABLEWORKING",
    workingtime: "WORKING.WORKINGTIME",
    start: "WORKING.START",
    end: "WORKING.END",
    starttime: "WORKING.STARTTIME",
    updatetime: "WORKING.UPDATETIME",
    stopwork: "WORKING.STOPWORK",
    enddate: "WORKING.ENDDATE",
    //noti
    nonotification: "HEADER.NONOTIFICATION",
    profile: "HEADER.PROFILE",
    logout: "HEADER.LOGOUT",
    chatnoti: "HEADER.CHATNOTI",
    cancel: "NUTRITIONIST.CONSULT.CANCEL",

    //changpass
    changepassword: "HEADER.CHANGEPASSWORD",
    youmustchangeyourpasswordforyour: "HEADER.YOUMUSTCHANGEYOURPASSWORDFORYOUR",
    security: "HEADER.SECURITY",
    newpassword: "HEADER.NEWPASSWORD",
    confirmpassword: "HEADER.CONFIRMPASSWORD",
    passwordsdonotmatch: "HEADER.PASSWORDSDONOTMATCH",
    savechang: "HEADER.SAVECHANG",
  },
];
