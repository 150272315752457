<div class="addContainer">
    <div class="contact-details">
        <div class="modalHeader" style="margin-bottom: 20px;">
            <div class="contact-details-heading">
                <div class="contact-details-name mb-2">{{languagePharmacy.view | translate}} {{druglists.medcName}}
                </div>
            </div>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="section" style="text-align: center;">
            <div class="container" style="text-align: center;">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <div style="margin-bottom: 8px;">
                            <img src="{{urlPath}}/{{imgF}}" style="max-height: 360px;"
                                onerror="this.src='assets/images/404/404error_2.png'">
                        </div>
                        <carousel>
                            <div class="carousel-cell" *ngFor='let imgDrugList of imgDrugLists'
                                (mouseenter)="imgClicked(imgDrugList)">
                                <img class="border-select" src="{{urlPath}}/{{imgDrugList}}" style="object-fit: cover;"
                                    onerror="this.src='assets/images/404/404error_2.png'">
                            </div>
                        </carousel>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-card class="mat-elevation-z8" style="height: 530px;overflow-x: hidden;overflow-y: scroll;">
                            <div style="margin-top:20px;font-size:18px;text-align: start;">
                                <strong>{{druglists.medcName}}</strong>&nbsp;
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title style="padding-top: 12px;">
                                            <p
                                                style="width: 210px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: rgb(15, 94, 15);">
                                                {{druglists.medcDescription}}</p>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <p style="overflow: hidden;font-size: 14px;color: rgb(15, 94, 15);">
                                        {{druglists.medcDescription}}</p>
                                </mat-expansion-panel>
                            </div>
                            <!-- <div style="margin-top:3px;font-size:18px;text-align: start;">
                                <span class="badge bg-danger">ExpirationDate : {{druglists.medcExpirationDate |
                                    date: 'dd/MM/YYYY'}}</span>
                            </div> -->
                            <div
                                style="margin-top:15px;font-size:18px;text-align: start; background-color:#1a202e0f;padding-top:5px;margin-bottom:5px;padding: 6px;;">
                                <strong>{{languagePharmacy.productdetails | translate}}</strong>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                                <span style="margin-right: 40px;">{{languagePharmacy.discount | translate}}</span>
                                :&nbsp;
                                <div class="contact-detail-info" style="text-align:start">
                                    <b>{{druglists.medcDiscount}}</b>
                                </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                                <span style="margin-right: 63px;">{{languagePharmacy.price | translate}}</span> :&nbsp;
                                <div class="contact-detail-info" style="text-align:start">
                                    <b>฿{{druglists.medcPrice}}</b>
                                </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                                <span style="margin-right: 74px;">{{languagePharmacy.qty | translate}}</span> :&nbsp;
                                <div class="contact-detail-info" style="text-align:start"> <b>{{druglists.medcQty}}
                                        {{druglists.medcUnit}}</b>
                                </div>
                            </div>
                            <!-- <div class="contact-details-field" style="margin-top:15px">
                                <span style="margin-right: 70px;">Unit</span> :&nbsp;
                                <div class="contact-detail-info" style="text-align:start"> <b>{{druglists.medcUnit}}</b>
                                </div>
                            </div> -->
                            <div class="contact-details-field" style="margin-top:15px">
                                <span style="margin-right: 5px;">{{languagePharmacy.medicinesname | translate}}</span>
                                :&nbsp;
                                <div class="contact-detail-info" style="text-align:start">
                                    <b>{{druglists.medcMedicalName}}</b>
                                </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px;">
                                <span style="margin-right: 46px;">{{languagePharmacy.caution | translate}}</span>
                                :&nbsp;
                                <div class="contact-detail-info" style="text-align:start">
                                    <b>{{druglists.medcCaution}}</b>
                                </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                                <span style="margin-right: 27px;">{{languagePharmacy.howtouse | translate}}</span>
                                :&nbsp;
                                <div class="contact-detail-info" style="text-align:start">
                                    <b>{{druglists.medcHowToUse}}</b>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>