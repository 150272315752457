import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { GlobalConstants } from "src/app/common/global-constants";

@Injectable({
  providedIn: "root",
})
export class DoctorProfileService extends UnsubscribeOnDestroyAdapter {
  //Http header
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  GetSkillDoctor_(idSkill: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetSkillDoctor?idSkill=${idSkill}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetSkillByDoctor_(idSkill: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetSkillByDoctor?idSkill=${idSkill}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  AddSkillOrDoctor_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/AddSkillOrDoctor`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetDoctorList_(idDoctor: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetDoctorList?idDoctor=${idDoctor}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteSkillOrDoctor_(idSkill: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/DeleteSkillOrDoctor?idSkill=${idSkill}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UploadIMGProfileDoctor_(data: FormData) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UploadImgBackOffice`;
      this.httpClient
        .post(API_URL, data, { responseType: "text" })
        // this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateDoctor_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UpdateDoctor`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // AddWorkingTimeDoctor
  // DeleteWorkingTimeDoctor
  AddWorkingTimeDoctor_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/AddWorkingTimeDoctor`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetWorkingTimeDoctor_(idWork: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetWorkingTimeByDoctor?idDoctor=${idWork}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteWorkOrDoctor_(idWorking: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/DeleteWorkingTimeDoctor?idWorkTime=${idWorking}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetBankDoctor_(idDoctor: string, type: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/GetBank?ids=${idDoctor}&type=${type}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  AddBankDoctor_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/InsertBankOrUpdate`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteBankOrDoctor_(idBank: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/DeleteBank?idBank=${idBank}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
