<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-toggle collapsed" (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <div *ngIf="!homeTrigger; then falseBlock else trueBlock"></div>
      <ng-template #falseBlock>
        <a class="navbar-brand">
          <!-- routerLink={{homePage}} -->
          <img *ngIf="shownav" src="assets\images\logo\RuxLogo-EN.png" width="100px" style="margin-left: 30px;"
            alt="img-logo-rux" />
          <!-- <img src="assets/images/logo.png" alt="" /> -->
          <!-- <span class="logo-name">Releep</span> -->
        </a>
      </ng-template>
      <ng-template #trueBlock>
        <a class="navbar-brand" routerLink={{homePage}}>
          <img *ngIf="shownav" src="assets\images\logo\RuxLogo-EN.png" width="100px" style="margin-left: 30px;"
            alt="img-logo-rux" />
          <img *ngIf="!shownav" src="assets\images\logo\RuxLogo-EN.png" width="40px" style="margin-top: 10px;"
            alt="img-logo-rux" />
        </a>
      </ng-template>
    </div>

    <!-- <button (click)="openWin()">Open "myWindow"</button>
<button (click)="closeWin()">Close "myWindow"</button> -->

    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right" style="margin-right: 0px;">
        <!-- Start Time -->
        <li class="nav-item" ngbDropdown style="margin-top: -2px;" *ngIf="roleLogin == 'Doctor' && isChecked == false">
          <button class="btn draw-borderNot"
            (click)="openDialogTimeStartWorking('start')">{{languageHeader.enableworking | translate}}</button>
        </li>
        <li class="nav-item" ngbDropdown style="margin-top: -2px;" *ngIf="roleLogin == 'Doctor' && isChecked == true">
          <div class="row" style="display: flex;">
            <div class="intro-banner-vdo-play-btn" target="_blank">
              <button class="btn draw-borderAcc" (click)="openDialogTimeStartWorking('update')">{{languageHeader.working
                | translate}}</button>
              <span class="ripple greenBg"></span>
              <span class="ripple greenBg"></span>
            </div>
          </div>
        </li>
        <li class="nav-item" ngbDropdown style="margin-top: 2px;" *ngIf="roleLogin == 'Doctor' && isChecked == true">
          <p class="job_post" style="margin-bottom: 0px;"
            *ngIf="getElapsedTime(dataGetTimeWork.worK_time_end) as elapsed">
            <span style="color: white;">
              <i class="fas fa-history"></i>&nbsp;
              <b>{{elapsed}}</b>
            </span>
          </p>
        </li>

        <li class="nav-item" ngbDropdown style="margin-top: 2px;"
          *ngIf="roleLogin == 'Nutritionist' && isCheckedNut == false">
          <button class="btn draw-borderNot"
            (click)="openDialogTimeStartWorking('start')">{{languageHeader.enableworking | translate}}</button>
        </li>
        <li class="nav-item" ngbDropdown style="margin-top: 2px;"
          *ngIf="roleLogin == 'Nutritionist' && isCheckedNut == true">
          <div class="row" style="display: flex;">
            <div class="intro-banner-vdo-play-btn" target="_blank">
              <button class="btn draw-borderAcc" (click)="openDialogTimeStartWorking('update')">{{languageHeader.working
                | translate}}</button>
              <span class="ripple greenBg"></span>
              <span class="ripple greenBg"></span>
            </div>
          </div>
        </li>
        <li class="nav-item" ngbDropdown style="margin-top: 2px;"
          *ngIf="roleLogin == 'Nutritionist' && isCheckedNut == true">
          <p class="job_post" style="margin-bottom: 0px;"
            *ngIf="getElapsedTime(getTimeWorkNutritionistList.worK_time_end) as elapsed">
            <span style="color: white;">
              <i class="fas fa-history"></i>&nbsp;
              <b>{{elapsed}}</b>
            </span>
          </p>
        </li>
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>

        <!-- #END# Full Screen Button -->
        <li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="20">
            <img *ngIf="flagvalue == undefined" src="{{defaultFlag}}" height="20">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="20"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li>
        <!-- #Chat# Notifications-->
        <li class="nav-item" ngbDropdown style="margin-right: -10px;" *ngIf="userRole == 'Pharmacy'">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon *ngIf="countnNotificationsChat <= 0" matBadgeColor="warn"
              routerLink="/pharmacy/pharmacy-chat/{{id}}">chat
            </mat-icon>
            <mat-icon *ngIf="countnNotificationsChat > 0" matBadge="{{countnNotificationsChat}}" matBadgeColor="warn"
              routerLink="/pharmacy/pharmacy-chat/{{id}}">chat
            </mat-icon>
          </button>
        </li>
        <!-- #START# Notifications-->
        <li class="nav-item" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons" *ngIf="userRole == 'Doctor'">
            <mat-icon matBadge="{{countnNotifications + countnNotifications2}}" matBadgeColor="warn"
              (click)="loadData()">notifications_none
            </mat-icon>
          </button>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons" *ngIf="userRole == 'Pharmacy'">
            <mat-icon matBadge="{{countnNotifications}}" matBadgeColor="warn" (click)="loadDataNotiOrder(id)">
              notifications_none
            </mat-icon>
          </button>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons" *ngIf="userRole == 'Nutritionist'">
            <mat-icon matBadge="{{countnNotifications}}" matBadgeColor="warn" (click)="loadData()">
              notifications_none
            </mat-icon>
          </button>

          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div *ngIf="!loading" class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;"
              [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="javascript:void(0)" *ngFor="let notification of notifications ; let i = index "
                    (click)="onClickRead(notification.idNoti,i,notification.message)">

                    <span class="table-img msg-user">
                      <img src="{{urlPath2021}}{{notification.userImg}}"
                        onerror="this.src='assets/images/imgdoctor/nouser.svg'" alt="" style="object-fit: cover;">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">{{notification.message}}</span>
                      <span class="menu-desc" *ngIf="userRole == 'Pharmacy'">{{notification.totalPrice}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time | date:'dd/MM/YYYY'}}
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a *ngIf="!loading && notifications.length == 0"
              class="text-center d-block border-top py-1">{{languageHeader.nonotification | translate}}
            </a>
            <img *ngIf="loading" class="text-center d-block border-top py-1"
              src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" style="width:220px;margin: auto;" alt="loading">
          </div>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown>
          <a ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="{{pathUrl}}/{{userImg}}" class="rounded-circle" width="32" height="32" alt="User"
              *ngIf="userRole != 'Admin'" style="object-fit: cover;"
              onerror="this.src='assets/images/imgdoctor/nouser.svg'">
            <img src="http://releep.achatsocial.com:4001/assets/images/imgdoctor/nouser.svg" class="rounded-circle"
              width="32" height="32" alt="User" *ngIf="userRole == 'Admin'" style="object-fit: cover;"
              onerror="this.src='assets/images/imgdoctor/nouser.svg'">
          </a>

          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="this.userRole == 'Doctor'">
                      <a href="#" routerLink="doctor/doctor-profile/{{id}}">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{languageHeader.profile |
                        translate}}
                      </a>
                    </li>
                    <li *ngIf="this.userRole == 'Pharmacy'">
                      <a href="#" routerLink="pharmacy/settings/{{id}}">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{languageHeader.profile |
                        translate}}
                      </a>
                    </li>
                    <li *ngIf="this.userRole == 'Distributor'">
                      <a href="#" routerLink="distributor/profile/{{id}}">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{languageHeader.profile |
                        translate}}
                      </a>
                    </li>
                    <li *ngIf="this.userRole == 'Pharmacy'">
                      <a>
                        <mat-icon aria-hidden="false" class="msr-2">message
                        </mat-icon>{{languageHeader.chatnoti | translate}}.
                        <mat-slide-toggle style="padding-left: 40px;" [(ngModel)]="isCheckedClose"
                          (change)="CloseNotiChat($event)"></mat-slide-toggle>
                      </a>
                    </li>
                    <li *ngIf="this.userRole == 'Nutritionist'">
                      <a href="#" routerLink="nutritionist/nutritionist-profile/{{id}}">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{languageHeader.profile |
                        translate}}
                      </a>
                    </li>
                    <li *ngIf="this.userRole == 'Dealer'">
                      <a href="#" routerLink="dealer/dealer-profile/{{id}}">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{languageHeader.profile |
                        translate}}
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" >
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" >
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>{{languageHeader.logout
                        | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
        <!-- <li class="float-end">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
            <mat-icon id="settingBtn">settings</mat-icon>
          </button>
        </li> -->
      </ul>
    </div>
  </div>
</nav>