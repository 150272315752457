import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LanguageAdmin } from "src/app/admin/interface-admin/language-item-admin";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { DoctorProfileService } from "../doctor-profile.service";

interface Bank {
  value: string;
  viewValue: string;
  viewImg: string;
}
interface AccountType {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-update-bank-dialog",
  templateUrl: "./update-bank-dialog.component.html",
  styleUrls: ["./update-bank-dialog.component.sass"],
})
export class UpdateBankDialogComponent implements OnInit {
  [x: string]: any;
  doctorForm: FormGroup;

  pharIddoc: string;
  languageItemsAdminAllPharmacy: any;
  selectedbankNamedoc = "ธนาคารกรุงเทพ จำกัด (มหาชน)";
  selectedbankAccountTypedoc = "ชำระผ่าน iBanking";
  bankNamedoc: string = "";
  AccountTypedoc: string = "";
  AccountNamedoc: string;
  bankNodoc: string;
  bankFromTypedoc: string = "1";
  isCheckBankName: boolean = true;
  isCheckAccountName: boolean = true;
  isBtnAddBank: boolean = true;
  dataBankByIdDocAll: any;
  bankDoc: any;
  idBank: string;
  idDoctor: string;
  selectedAccountType: string;

  constructor(
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService,
    public dialogRef: MatDialogRef<UpdateBankDialogComponent>,
    public doctorProfileService: DoctorProfileService,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.idDoctor = this.activatedRoute.snapshot.paramMap.get("idDoctor");
    this.bankDoc = data;

    this.doctorForm = this.createContactForm();
    this.selectedAccountType = data.banK_AccountType;
    this.selectedBankNamedoc = data.banK_Name;
    this.translate.use(localStorage.getItem("lang"));
  }
  createContactForm(): FormGroup {
    return this.fb.group({
      AccountName: [this.bankDoc.banK_AccountName],
      bankNo: [this.bankDoc.banK_No],
    });
  }
  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError("required")
      ? "Required field"
      : this.formControl.hasError("email")
        ? "Not a valid email"
        : "";
  }

  ngOnInit() {
    this.languageItemsAdminAllPharmacy = LanguageAdmin[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
  }
  submit() {
    // emppty stuff
  }
  banks: Bank[] = [
    {
      value: "ธนาคารกรุงเทพ",
      viewValue: "ธนาคารกรุงเทพ จำกัด (มหาชน)",
      viewImg: "assets/images/bank/bk.jpg",
    },
    {
      value: "ธนาคารกรุงไทย",
      viewValue: "ธนาคารกรุงไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/ktb-bank-logo-big.jpg",
    },
    {
      value: "ธนาคารกรุงศรีอยุธยา",
      viewValue: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
      viewImg: "assets/images/bank/ks.png",
    },
    {
      value: "ธนาคารกสิกรไทย",
      viewValue: "ธนาคารกสิกรไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/logo-kbank.jpg",
    },
    {
      value: "ธนาคารเกียรตินาคิน",
      viewValue: "ธนาคารเกียรตินาคิน จำกัด (มหาชน)",
      viewImg: "assets/images/bank/kkn.png",
    },
    {
      value: "ธนาคารซีไอเอ็มบี",
      viewValue: "ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/cmb.png",
    },
    {
      value: "ธนาคารทหารไทย",
      viewValue: "ธนาคารทหารไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/tmb_logo.jpg",
    },
    {
      value: "ธนาคารทิสโก้",
      viewValue: "ธนาคารทิสโก้ จำกัด (มหาชน)",
      viewImg: "assets/images/bank/tisco.jpg",
    },
    {
      value: "ธนาคารไทยพาณิชย์",
      viewValue: "ธนาคารไทยพาณิชย์ จำกัด (มหาชน)",
      viewImg: "assets/images/bank/scb.jpg",
    },
    {
      value: "ธนาคารธนชาต",
      viewValue: "ธนาคารธนชาต จำกัด (มหาชน)",
      viewImg: "assets/images/bank/tnc.jpg",
    },
    {
      value: "ธนาคารนครหลวงไทย",
      viewValue: "ธนาคารนครหลวงไทย จำกัด (มหาชน)",
      viewImg: "assets/images/bank/nkl.jpg",
    },
    {
      value: "ธนาคารยูโอบี",
      viewValue: "ธนาคารยูโอบี จำกัด (มหาชน)",
      viewImg: "assets/images/bank/uob.jpg",
    },
    {
      value: "ธนาคารสแตนดาร์ดชาร์เตอร์ด",
      viewValue: "ธนาคารสแตนดาร์ดชาร์เตอร์ด จำกัด (มหาชน)",
      viewImg: "assets/images/bank/std.png",
    },
    {
      value: "ธนาคารไอซีบีซี",
      viewValue: "ธนาคารไอซีบีซี จำกัด (มหาชน)",
      viewImg: "assets/images/bank/icbc.png",
    },
    {
      value: "ธนาคารออมสิน",
      viewValue: "ธนาคารออมสิน จำกัด (มหาชน)",
      viewImg: "assets/images/bank/download.png",
    },
  ];
  accountTypes: AccountType[] = [
    {
      value: "ชำระผ่านบัตรเครดิต/ บัตรเดบิต",
      viewValue: "ชำระผ่านบัตรเครดิต/ บัตรเดบิต",
    },
    { value: "iBanking", viewValue: "ชำระผ่าน iBanking" },
    { value: "Mobile Banking", viewValue: "ชำระผ่าน Mobile Banking" },
    { value: "Atm", viewValue: "ชำระผ่าน ATM" },
  ];

  cardId: number = 0;
  cardName: string = "cardName";
  accName: boolean = true;
  accId: boolean = true;

  onSearchChangeCard(idValue: number): void {
    this.cardId = idValue;
    if (this.cardId.toString().length >= 16) {
      this.accId = true;
    } else {
      this.accId = false;
    }
  }
  onSearchChangeName(idValue: string): void {
    this.cardName = idValue;
    if (this.cardName != "") {
      this.accName = true;
    } else {
      this.accName = false;
    }
    // console.log(this.accName);
  }

  onChangeBank(event: string) {
    // console.log(event);
    this.bankNamedoc = event;
    this.isCheckBankName = false;
    // this.isCheckAccountName = false;
  }
  onChangeAccountType(event: string) {
    // console.log(event);
    this.AccountTypedoc = event;
    this.isCheckAccountName = false;
  }

  confirmUpdate() {
    var dataUpdateBankDoctor: any;
    var dataUpdateBankDoc = {
      bankId: this.bankDoc.banK_Id,
      drId: this.bankDoc.dR_Id,
      pharId: this.pharIddoc,
      bankName: this.selectedBankNamedoc,
      bankNo: this.doctorForm.getRawValue().bankNo,
      bankAccountName: this.doctorForm.getRawValue().AccountName,
      bankAccountType: this.selectedAccountType,
      bankFromType: this.bankFromTypedoc,
    };
    // console.log("8888888888", dataUpdateBankDoc);

    this.doctorProfileService
      .AddBankDoctor_(dataUpdateBankDoc)
      .then((resUpdateBankDoctor) => {
        dataUpdateBankDoctor = resUpdateBankDoctor;
        // console.log('bank update', dataUpdateBankDoctor);
        if (dataUpdateBankDoctor.status == true) {
          var dataBankListUpdate = {
            dR_Id: dataUpdateBankDoc.drId,
            bank_Id: dataUpdateBankDoc.bankId,
            banK_Name: dataUpdateBankDoc.bankName,
            banK_No: dataUpdateBankDoc.bankNo,
            banK_AccountName: dataUpdateBankDoc.bankAccountName,
            banK_AccountType: dataUpdateBankDoc.bankAccountType,
            banK_FromType: dataUpdateBankDoc.bankFromType,
          };

          this.dialogRef.close(dataBankListUpdate);
          // this.dataBankByIdDocAll.splice(0, 0, dataBankList);
        } else {
          this.showNotification(
            "snackbar-success",
            "Add Bank Successfully...!!!",
            "bottom",
            "center"
          );
        }
        // if (dataAddBankDoctor.status == true) {

        //   var dataBankList ={

        //       "bankId" : dataAddBankDoc.bankId,
        //       "dR_Id" : dataAddBankDoc.drId,
        //       "banK_Name" : dataAddBankDoc.bankName,
        //       "banK_No" : dataAddBankDoc.bankNo,
        //       "banK_AccountName" : dataAddBankDoc.bankAccountName,
        //       "banK_AccountType" : dataAddBankDoc.bankAccountType,
        //       "banK_FromType" : dataAddBankDoc.bankFromType,
        //     };
        //     // this.bankDoc.splice(0, 0, dataBankList);
        //     this.dialogRef.close(dataBankList);
        //     }
      });
  }
}
