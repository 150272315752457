import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  private messageSource = new BehaviorSubject(0);
  currentMessage = this.messageSource.asObservable();

  //เปลี่ยนภาษา
  private compHeaderValueLanguageBS = new BehaviorSubject<string>("");
  languageValue = this.compHeaderValueLanguageBS.asObservable();

  private compHeaderValueChat = new BehaviorSubject(0);
  countChat = this.compHeaderValueChat.asObservable();

  private appointmentAftePaymentValue = new BehaviorSubject<any>("");
  dataAppointmentAftePaymentValue = this.appointmentAftePaymentValue.asObservable();

  private socketUserCancelConverseValue = new BehaviorSubject<any>("");
  dataSocketUserCancelConverse = this.socketUserCancelConverseValue.asObservable();

  private iscountDownAppointmentSidebarValue = new BehaviorSubject<boolean>(false);
  iscountDownAppointmentSidebar = this.iscountDownAppointmentSidebarValue.asObservable();

  private countNotiAlertChangDateByuserValue = new BehaviorSubject(0);
  countNotiAlertChangDateByuser = this.countNotiAlertChangDateByuserValue.asObservable();

  private updateDoctorAcceptAppointmentValue = new BehaviorSubject<boolean>(false);
  dataDoctorAcceptAppointment = this.updateDoctorAcceptAppointmentValue.asObservable();

  constructor() { }

  public changeMessage(message: number): void {
    this.messageSource.next(message);
  }

  //เปลี่ยนภาษา
  updateCompcompHeaderValLanguage(val) {
    this.compHeaderValueLanguageBS.next(val);
  }

  updateCountChat(val) {
    this.compHeaderValueChat.next(val);
  }

  updateAppointmentAftePayment(val) {
    this.appointmentAftePaymentValue.next(val);
  }

  alertDataSocketUserCancelConverse(val) {
    this.socketUserCancelConverseValue.next(val);
  }

  countDownAppointmentSidebar(val) {
    this.iscountDownAppointmentSidebarValue.next(val);
  }

  countNotiAlertChangDateSidebar(val) {
    this.countNotiAlertChangDateByuserValue.next(val);
  }

  updateDoctorAcceptAppointment(val) {
    this.updateDoctorAcceptAppointmentValue.next(val);
  }

}
