<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <!-- <img [src]='pharmacys.img' alt="avatar"> -->
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 msl-3 font-20">
            {{languagePharmacy.newdruglist | translate}}</div>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content style="overflow-x: hidden;">
    <div class="row" style="display: flex;justify-content: center;">
      <div class="col-4" *ngIf="!noImg || imgDrugListsOld != ''">
        <!-- <div class="col-4"> -->
        <div style="text-align: end">
          <button mat-icon-button (click)="onClickClearImg()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="image-preview row">
          <img *ngFor='let imgDrugList of imgDrugLists' src="{{urlPath}}/{{imgDrugList}}" class="image-preview"
            style="border-radius: 5%;max-width: 150px;max-height: 150px; object-fit: cover;padding: 5px;margin: auto;"
            onerror="this.src='assets/images/404/404error_2.png'">
        </div>
        <div class="image-preview row">
          <img *ngFor='let preview of previews' [src]="preview" class="image-preview"
            style="border-radius: 5%;max-width: 150px;max-height: 150px; object-fit: cover;padding: 5px;margin: auto;">
        </div>
        <!-- <div class="image-preview" *ngIf="previewUrl">
              <img [src]="previewUrl" style="border-radius: 5%;width: 140px;height: 140px;object-fit: cover;" />
            </div> -->

        <!-- <div *ngIf="noImg">
              <img src="{{urlPath}}{{imgDrugList}}"
                style="border-radius: 50%;width: 160px;height: 160px;object-fit: cover;"
                onerror="this.src='assets/images/img-pharmacy/pharmacy.png'" />
            </div> -->


      </div>
      <div class="col-8">
        <form class="register-form m-6" [formGroup]="drugListForm">
          <!-- img -->
          <!-- img -->
          <div class="row">
            <div class="col-xl-4 col-lg-3 col-md-12 col-sm-12 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>{{languagePharmacy.medicines | translate}}</mat-label>
                <mat-select required formControlName="Medicines">
                  <mat-option *ngFor="let medicine of medicines" [value]="medicine.value">
                    {{medicine.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.name | translate}}</mat-label>
                <input matInput formControlName="Name" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Name').hasError('required')">
                        Name is required
                    </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate name </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.medicinesname | translate}}</mat-label>
                <input matInput formControlName="MedicalName" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('MedicalName').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate MedicalName </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.discount | translate}}</mat-label>
                <input matInput formControlName="Discount" required type="number">
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Discount').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate Discount </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.price | translate}}</mat-label>
                <input matInput formControlName="Price" required type="number">
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Price').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate Price </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.qty | translate}}</mat-label>
                <input matInput formControlName="Qty" required type="number">
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Qty').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate Qty </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.unit | translate}}</mat-label>
                <input matInput formControlName="Unit" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Unit').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate Unit </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.discription | translate}}</mat-label>
                <input matInput formControlName="Description" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Description').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate Description </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.caution | translate}}</mat-label>
                <input matInput formControlName="Caution" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('Caution').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate Caution </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{languagePharmacy.howtouse | translate}}</mat-label>
                <input matInput formControlName="HowToUse" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <!-- <mat-error *ngIf="drugListForm.get('HowToUse').hasError('required')">
                          Name is required
                      </mat-error> -->
                <mat-hint *ngIf="duplicateUsername" style="color: red;"> Duplicate HowToUse </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width mb-3" appearance="outline">
                <mat-label>{{languagePharmacy.expirationdate | translate}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="ExpirationDate" required disabled>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
                <mat-error *ngIf="drugListForm.get('ExpirationDate').hasError('required')">
                  expiration of date is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
            style="display: flex;justify-content: space-between;">
            <div class="example-button-row">
              <button mat-mini-fab (click)="fileInput.click()" style="background-color: #51B1A5;">
                <mat-icon>photo_size_select_actual</mat-icon>
                <input type="file" #fileInput accept="image/*" multiple (change)="selectFiles($event)"
                  style="display:none;" accept="image/png, image/jpeg" />
              </button>
            </div>
            <div class="example-button-row">
              <button mat-raised-button color="primary" *ngIf="action == 'add'" [type]="submit"
                [disabled]="!drugListForm.valid" (click)="confirmAdd()">{{languagePharmacy.save | translate}}</button>
              <button mat-raised-button color="primary" *ngIf="action == 'edit'" [type]="submit"
                [disabled]="!drugListForm.valid" (click)="confirmUpdate()">{{languagePharmacy.update |
                translate}}</button>
              <button mat-raised-button color="warn" (click)="onNoClick()" tabindex="-1">{{languagePharmacy.cancle |
                translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>