import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalConstants } from "src/app/common/global-constants";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { InsertPacKageInterface } from "./interfaces/insert-package.interface";

@Injectable({
  providedIn: "root",
})
export class PackageService extends UnsubscribeOnDestroyAdapter {
  u_Token?: string;
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  sentByDtt: boolean = false;

  constructor(private httpClient: HttpClient) {
    super();
    var dataToken = JSON.parse(localStorage.getItem("currentUser"));
    if (dataToken != null) {
      this.u_Token = dataToken.token;
    }
  }

  UploadIMGProfileDistributor_(data: FormData) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UploadImgBackOffice`;
      this.httpClient
        .post(API_URL, data, { responseType: "text" })
        // this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetProvinceDistributor_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetProvinceDropdownSelect`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDistrictDistributor_(idprovince: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetDistrictDropdownSelect?idDistrict=${idprovince}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetSubDistrictDistributor_(idsubDistrict: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetSubDistrictDropdownSelect?idSubDistrict=${idsubDistrict}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDealer_(idDealer: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetDealer?id=${idDealer}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertAndUpdateDealer_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/InsertAndUpdateDealer`;
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDistributor_(idDistributor: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetDistributor?id=${idDistributor}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPacKage_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPacKage`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetAudit_(dataId: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetAudit?id=${dataId}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  RemoveAudit_(idAudit: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/RemoveAudit?auditId=${idAudit}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertPacKage_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/InsertPacKage`;
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertAndUpdateDistributor_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/InsertAndUpdateDistributor`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertAndUpdateAudit_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/InsertAndUpdateAudit`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertOrUpdatePacKageUse_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/InsertOrUpdatePacKageUse`;
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPacKageUse_(idDealer: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPacKageUse?id=${idDealer}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPacKageUseToDistributor_(id: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPacKageUseToDistributor?id=${id}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPacKageOfDistributor_(id: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPacKageOfDistributor?id=${id}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPacKageUseToDistributorV2_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPacKageUseToDistributorV2`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertPacKageUseToDistributor(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/InsertPacKageUseToDistributor`;
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPharmacyList_(id: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetPharmacyList?idPharmacy=${id}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPacKagefromDistributor_(idDistributor: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPacKageUseToDistributor?id=${idDistributor}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  RemovePacKageUseToDistributor_(dttId: any, idPackage: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/RemovePacKageUseToDistributor?todisId=${dttId}&pkStockId=${idPackage}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DisablePacKageUseToDistributor_(
    todisId: any,
    pkStockId: any,
    action: boolean
  ) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/DisablePacKageUseToDistributor?todisId=${todisId}&pkStockId=${pkStockId}&action=${action}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPackageRequest_(idDistributor: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPackageRequest?disId=${idDistributor}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdatePacKageRequestToDealer_(dealerId: any, status: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/UpdatePacKageRequestToDealer?todeaId=${dealerId}&confirmStatus=${status}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdatePacKageRequestToDealerV2_(
    deaId: number,
    disStockId: number,
    status: string
  ) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/UpdatePacKageRequestToDealerV2?deaId=${deaId}&disStockId=${disStockId}&confirmStatus=${status}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetSendRequestPackageFromDistributor_(idDistributor: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetSendRequestPackageFormDistributor?disId=${idDistributor}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetSendRequestPackageFromDistributorV2_(idDistributor: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetSendRequestPackageFormDistributorV2?disId=${idDistributor}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPackageRequestFromDealer_(idDistributor: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetPackageRequestFromDealer?disId=${idDistributor}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // GetRequestPackageFormDistributor_() {
  //   return new Promise<any>((resolve, reject) => {
  //     let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetRequestPackageFormDistributor`;
  //     this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
  //       .subscribe(data => {
  //         resolve(data);
  //       },
  //         error => {
  //           reject(error);
  //         });
  //   });
  // }
  GetRequestPackageFormDistributorV2_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/GetRequestPackageFormDistributorV2`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  CancelRequestPacKageFromDistributor_(reqId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/CancelRequestPacKageFormDistributor?reqId=${reqId}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  CancelRequestPacKageFormDistributorV2_(reqId: number, status: String) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/CancelRequestPacKageFormDistributorV2?reqId=${reqId}&statusCancle=${status}`;
      this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          }
        );
    });
  }

  ConfirmRequestPacKageFormDistributorV2_(reqId: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/ConfirmRequestPacKageFormDistributorV2?reqId=${reqId}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  RequestPacKageFromDistributor_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/RequestPacKageFormDistributor`;
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  RequestPacKageFromDistributorV2_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/RequestPacKageFormDistributorV2`;
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateRequestPacKageFromDistributor_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/UpdateRequestPacKageFormDistributor`;
      this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateRequestPacKageFromDistributorV2_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/UpdateRequestPacKageFormDistributorV2`;
      this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  CancelRequestPacKageFormDistributor_(reqId: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/package/CancelRequestPacKageFormDistributorV2?reqId=${reqId}`;
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({ Authorization: `bearer ${this.u_Token}` }),
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
