import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { GlobalConstants } from "src/app/common/global-constants";
@Injectable()
export class AppointmentsService extends UnsubscribeOnDestroyAdapter {
  //Http header
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  GetConvosation_(drId: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConvosationById?drId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetConvosationRequsetDoctorById_(drId: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConvosationRequsetDoctorById?drId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetConversationV2_(drId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConversationV2?doctorId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetConversationV3_(drId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConversationV3?doctorId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetConversationOverTime_(converId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConversationOverTime?converId=${converId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetConversationOverTimeV2_(converId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConversationOverTimeV2?converId=${converId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDoctorList_(idDoctor: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetDoctorList?idDoctor=${idDoctor}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  Deleteconversation_(idConver: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/Deleteconversation?idConver=${idConver}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  sendDocterAccept_(
    userID: string,
    nameDoctor: string,
    starusAccept: boolean,
    dateTimeAppointment: string,
    convoId: any
  ) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/onesignal/sendDocterAccept?userID=${userID}&nameDoctor=${nameDoctor}&starusAccept=${starusAccept}&dateTimeAppointment=${dateTimeAppointment}&CONVO_Id=${convoId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateStatusAccept_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/UpdateStatusAccept`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  SendNotificationAfterTime_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/onesignal/SendNotificationAfterTime`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  AddTime_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/InsertDoctorWorking`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  UpdateTime_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UpdateDoctorWorking`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateDoctorPass_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/ChangePasswordDoctor`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdatePharmacyPass_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/ChangePasswordPhar`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateNutritionistPass_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/ChangePasswordNutritionist`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetWorkByDrId_(drId: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/GetWorkByDrId?drId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteWorkByDrId_(idDoctor: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/DeleteDoctorWorkingById?workId=${idDoctor}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetWorkByNutritionistId_(nutritionisId: String) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/GetWorkByNutritionistId?nutritionisId=${nutritionisId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  AddTimeNutritionist_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/InsertNutritionistWorking`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateTimeNutritionist_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/UpdateNutritionistWorking`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteWorkByNutritionistId_(workId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/nutritionist/DeleteNutritionistWorkingById?workId=${workId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetBankDoctor_(idDoctor: string, type: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/GetBank?ids=${idDoctor}&type=${type}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateChangeNewAppointmentDate_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/ChangeNewAppointmentDate`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
