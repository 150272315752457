<div style="height: 400px;" *ngIf="call">

  <div mat-dialog-content style="overflow: hidden;">
    <div class="row" style="display: flex;justify-content: center;">
      <div class="intro-banner-vdo-play-btn" target="_blank" style="display: flex;justify-content: center;">
        <img src="{{urlPath2021}}{{dataCall.userPic}}" onerror="this.src='assets/images/imgdoctor/nouser.svg'" alt="img"
          style='object-fit: cover; width: 200px;height: 193px;border-radius:50%'>
        <span class="ripple greenBg"></span>
        <span class="ripple greenBg"></span>
      </div>
    </div>
    <h4 style="text-align: center;margin-top: 30px;"><strong>{{dataCall.userName}}</strong></h4>
    <h5 style="text-align: center;margin-bottom: 10px;">Video calling...</h5>
  </div>
  <div mat-dialog-actions style="display: flex;justify-content: space-evenly;margin-bottom: 0px;">
    <img src="assets/images/phonCall.gif" alt="img" (click)="onClickConfirm()"
      style='object-fit: cover; width: 60px;height: 60px;'>
    <img src="assets/images/simple_loading.gif" style='object-fit: cover; width: 100px;height: 60px;'>
    <button mat-fab color="warn" (click)="onClickCancel()">
      <mat-icon> call_end</mat-icon>
    </button>
  </div>
</div>

<div style="height: 360px;" *ngIf="!call">
  <div mat-dialog-content style="overflow: hidden;">
    <div class="row" style="display: flex;justify-content: center;">
      <img src="{{urlPath2021}}{{dataCall.userPic}}" onerror="this.src='assets/images/imgdoctor/nouser.svg'" alt="img"
        style='object-fit: cover; width: 200px;height: 193px;border-radius:50%'>
    </div>
    <h4 style="text-align: center;margin-top: 30px;"><strong>{{dataCall.userName}}</strong></h4>
    <h5 style="text-align: center;margin-bottom: 10px;">Hang up video call...</h5>
  </div>
  <div mat-dialog-actions style="display: flex;justify-content: space-evenly;margin-bottom: 0px;">
    <button mat-fab color="warn" class="bounce" style="background: #5f5f5f;" (click)="onClickCancel()">
      <mat-icon> call_end</mat-icon>
    </button>
  </div>
</div>