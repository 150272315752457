export enum Role {
  Admin = "Admin",
  AdminViewOnly = "AdminViewOnly",
  Doctor = "Doctor",
  Patient = "Patient",
  Pharmacy = "Pharmacy",
  Nutritionist = "Nutritionist",
  Distributor = "Distributor",
  Dealer = "Dealer",
  Audit = "Audit",
  TelSupport = "TelSupport",
  Packer = "Packer",
  Deliver = "Deliver",
}
