import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
declare var JitsiMeetExternalAPI: any;
import { Socket } from "ngx-socket-io";
import * as moment from "moment";
import Swal from "sweetalert2";
import { DoctorChatsService } from "src/app/doctor/doctor-chats/doctor-chats.service";
import { AppointmentsService } from "src/app/doctor/appointments/appointments.service";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";

@Component({
  selector: "app-jitsi",
  templateUrl: "./jitsi.component.html",
  styleUrls: ["./jitsi.component.scss"],
})
export class JitsiComponent implements OnInit, AfterViewInit, OnDestroy {
  domain: string = "meet.medilifewellness.com/"; // For self hosted use your domain
  // domain: string = "conference.achatsocial.com/"; // For self hosted use your domain
  idConverPhamacy: any;
  idDoctor: any;
  idNutritionist: any;
  role: any;
  room: any;
  timeCountdown: any;
  options: any;
  api: any;
  user: any;
  jwt: string = "";

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;

  num = 0;
  index = 0;
  datatranslatechat: any;
  timerCountDown: any;

  constructor(
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pharmacyService: PharmacyService,
    private socket_: Socket,
    public doctorChatsService: DoctorChatsService,
    public appointmentsService: AppointmentsService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    // var data_ = JSON.parse(localStorage.getItem("dataCall"));
    this.role = this.activatedRoute.snapshot.paramMap.get("role");

    if (this.role == "pharmacy") {
      this.idConverPhamacy =
        this.activatedRoute.snapshot.paramMap.get("idConverPhamacy");
      this.room = this.activatedRoute.snapshot.paramMap.get("room");
      this.jwt = this.activatedRoute.snapshot.paramMap.get("jwtToken");
    } else {
      this.room = this.activatedRoute.snapshot.paramMap.get("room");
      this.timeCountdown = JSON.parse(
        this.activatedRoute.snapshot.paramMap.get("timeCountdowm")
      );
      this.jwt = this.activatedRoute.snapshot.paramMap.get("jwtToken");
      if (this.role == "doctor") {
        this.idDoctor = this.activatedRoute.snapshot.paramMap.get("idDoctor");
      } else {
        this.idNutritionist =
          this.activatedRoute.snapshot.paramMap.get("idNutritionist");
      }
    }
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslatechat = localStorage.getItem("lang");
    });

    let timeC = new Date(this.timeCountdown);
    let timeN = new Date();

    if (timeC < timeN) {
      if (this.role == "doctor" || this.role == "nutritionist") {
        this.router
          .navigate([`/rux/thank-you`], { replaceUrl: true })
          .then(() => {
            window.location.reload();
          });
      }
    }
    this.socKetOverTime();
    this.timerCountDown = setInterval(this.getElapsedTime, 1000);
    // console.log(">>:1111 ",JSON.parse(this.timerCountDown));
    // const str = this.link;

    // const strCopy = str.split("https://conference.achatsocial.com/");

    // const strCopy2 = strCopy[1].split("?jwt=");
    // const strCopy2 = this.link.split("?jwt=");

    // this.room = strCopy2[0];
    // this.jwt = strCopy2[1];
    // this.room = 'DR168_8eff13db-2fe9-4415-897f-b3b6b359ed7e'; // Set your room name
    // this.user = {
    //   name: 'Akash Verma' // Set your username
    // }
    this.socket_.on("call_pharmacy", (dataCall: any) => {
      // console.log("dataCall------ ", dataCall);
      if (
        dataCall.HangUp == "user" &&
        dataCall.PharmacyConvoIsTalking == "False" &&
        dataCall.PharmacyConvoID == this.idConverPhamacy
      ) {
        this.handleVideoConferenceLeft();
      }
    });

    if (this.idDoctor != undefined) {
      this.socket_.on("datasocket_userCancelConverseDoctor", (data: any) => {
        if (
          data.DoctorID == this.idDoctor &&
          data.StatusCancel == "cancelTalkingSuccess"
        ) {
          this.alertNitiCancelConverDoctor();
        }
      });
    }

    if (this.idNutritionist != undefined) {
      this.socket_.on("datasocket_userCancelConverseDoctor", (data: any) => {
        if (
          data.NutritionistID == this.idNutritionist &&
          data.StatusCancel == "cancelTalkingSuccess"
        ) {
        }
      });
    }
  }

  scrHeight: any;
  scrWidth: any;

  socKetOverTime() {
    this.socket_.on("overtime_tele", (data: any) => {
      // console.log("header data overtime---> ", data);
      if (data.Action == "success") {
        if (data.Id == this.idDoctor) {
          this.loadUserChatAppointments();
        }
      }
    });
  }

  async loadUserChatAppointments() {
    let dataGetConvosationAppointments: any = [];
    this.doctorChatsService
      .GetConvosation_(Number(this.idDoctor))
      .then(async (resGetConvosationAppointments) => {
        // console.log(">>>>>>>>>> ", resGetConvosationAppointments);
        dataGetConvosationAppointments = resGetConvosationAppointments;

        if (dataGetConvosationAppointments.length > 0) {
          var momentDate: any;
          await this.getConvosationOverTimeV2().then((resOutTime) => {
            // console.log("resOutTime--->", resOutTime);
            if (resOutTime != null) {
              if (resOutTime.status) {
                momentDate = moment(resOutTime.timeOut);
              }
            } else {
              momentDate = moment();
            }
          }, error => {
            console.log("error", error);
          });
          for (let i = 0; i < dataGetConvosationAppointments.length; i++) {
            if (
              dataGetConvosationAppointments[i].convoDoctorAccept == true &&
              dataGetConvosationAppointments[i].convoIsTalking == true &&
              momentDate.isAfter(moment())
            ) {
              this.timeCountdown = momentDate;
            }
          }
        }
      });
  }

  getConvosationOverTime = () => {
    return new Promise<any>((resolve, reject) => {
      this.appointmentsService
        .GetConversationV2_(Number(this.idDoctor))
        .then((res) => {
          this.appointmentsService
            .GetConversationOverTimeV2_(res.data.convO_Id)
            .then(async (res) => {
              resolve(res);
            },
              (error) => {
                reject(error);
              });
        });
    });
  };
  getConvosationOverTimeV2 = () => {
    return new Promise<any>((resolve, reject) => {
      this.appointmentsService.GetConversationV3_(Number(this.idDoctor)).then((res) => {
        if (res.data != null) {
          this.appointmentsService
            .GetConversationOverTimeV2_(res.data.convO_Id)
            .then(async (res) => {
              resolve(res);
            },
              (error) => {
                reject(error);
              });
        } else {
          resolve(null)
        }
      });
    });
  };

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    if (
      window.innerWidth != this.scrWidth ||
      window.innerHeight != this.scrHeight
    ) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      // this.dassss();
      if (this.num > 0) {
        // location.reload();
        document.querySelector(`#jitsi-iframe_${this.index}`).remove();
        this.index++;
        this.d1.nativeElement.insertAdjacentHTML(
          "beforeend",
          `<div id="jitsi-iframe_${this.index}" style="overflow: hidden !important;"></div>`
        );
        this.dassss();
      } else {
        this.num++;
      }
    }
  }

  @ViewChild("one") d1: ElementRef;

  ngAfterViewInit(): void {
    this.d1.nativeElement.insertAdjacentHTML(
      "beforeend",
      `<div id="jitsi-iframe_${this.index}" style="overflow: hidden !important;"></div>`
    );
    this.dassss();
  }

  dassss() {
    this.options = {
      roomName: this.room,
      width: this.scrWidth,
      height: this.scrHeight,
      jwt: this.jwt,
      // jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsiYXZhdGFyIjpudWxsLCJuYW1lIjoi4LiU4LijLiDguJnguLTguJXguLQgIOC4peC4teC5geC4quC4mSIsImVtYWlsIjoiTml0aGkubEBnbWFpbC5jb20iLCJpZCI6ImFiY2Q6YTFiMmMzLWQ0ZTVmNi0wYWJjMS0yM2RlLWFiY2RlZjAxZmVkY2JrIn19LCJhdWQiOiJhY2hhdF9qd3RfYXBwX2lkIiwiaXNzIjoiYWNoYXRfand0X2FwcF9pZCIsInN1YiI6ImNvbmZlcmVuY2UuYWNoYXRzb2NpYWwuY29tIiwicm9vbSI6IioifQ.2vfQnBU5bqJpZOpC23O3n_0Uh8PPEJlhrB3DzlxIxjk",
      configOverwrite: { prejoinPageEnabled: true, },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        TOOLBAR_BUTTONS: [
          "microphone", "camera", "tileview", "participants-pane", "settings",
          "hangup",
        ],
      },
      parentNode: document.querySelector(`#jitsi-iframe_${this.index}`),
      // userInfo: {
      //   displayName: this.user.name
      // }
    };

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    // Event handlers
    this.api.addEventListeners({

      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
    });
  }

  ngOnDestroy() {
    // console.log("00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000");

    this.endCall();
  }

  @HostListener("window:unload", ["$event"])
  unloadHandler(event) {
    // console.log("event ", event);
    var dataUpdateCall = {
      pharConvo_Id: this.idConverPhamacy,
      pharConvo_IsTalking: false,
      HangUp: "pharmacy",
    };

    this.pharmacyService
      .InsertAndUpdatePhamarcyConversation_(dataUpdateCall)
      .then((resInsertAndUpdatePhamarcyConversation) => {
        localStorage.removeItem("dataCall");

        this.endCall();
        this.router.navigate(["/rux/thank-you"]);
      });
  }

  endCall() {
    if (this.role == "pharmacy") {
      let text = this.room;
      let resultidUSer = text.split("_");
      var dataCallObj = {
        userID: resultidUSer[1],
        pharmacyConvoID: this.idConverPhamacy,
      };
      this.socket_.emit("Pharmacy-AnswertheCall", {
        dataCall: dataCallObj,
        status: "NotAnsweringtheCall",
      });
      this.router
        .navigate([`/rux/thank-you`], { replaceUrl: true })
        .then(() => {
          window.location.reload();
        });
    }
    if (this.role == "doctor") {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.datatranslatechat == "en" ? "The time for processing is not yet complete." : 'เวลาการดำเนินการสนทนายังไม่เสร็จสิ้น',
        showConfirmButton: false,
        timer: 2000
      }).then((res) => {
        window.location.reload();
      });
    }
  }

  handleClose = () => {
    console.log("handleClose");
  };

  handleParticipantLeft = async (participant) => {
    // console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    // console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    // console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    // console.log("handleVideoConferenceLeft");
    var dataUpdateCall = {
      pharConvo_Id: this.idConverPhamacy,
      pharConvo_IsTalking: false,
      HangUp: "pharmacy",
    };
    this.pharmacyService
      .InsertAndUpdatePhamarcyConversation_(dataUpdateCall)
      .then((resInsertAndUpdatePhamarcyConversation) => {
        localStorage.removeItem("dataCall");
        this.endCall();
      });
  };

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  executeCommand(command: string) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      // this.router.navigate(["/rux/thank-you"]);
      this.router
        .navigate([`/rux/thank-you`], { replaceUrl: true })
        .then(() => {
          window.location.reload();
        });
      return;
    }

    if (command == "toggleAudio") {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == "toggleVideo") {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }

  getElapsedTime(entry: any) {
    var date__ = new Date(entry).getTime() - new Date().getTime();
    var countdown = moment(date__).format("mm:ss");
    if (countdown == "00:00") {
      clearInterval(this.timerCountDown);
      this.router
        .navigate([`/rux/thank-you`], { replaceUrl: true })
        .then(() => {
          window.location.reload();
        });
    }
    return countdown;
  }

  alertNitiCancelConverDoctor() {
    Swal.fire({
      title: "จบการสนทนา",
      confirmButtonText: "ยืนยัน",
    }).then((result) => {
      if (result.isConfirmed) {
        this.router
          .navigate([`/rux/thank-you`], { replaceUrl: true })
          .then(() => {
            window.location.reload();
          });
      }
    });

    setTimeout(() => {
      this.router
        .navigate([`/rux/thank-you`], { replaceUrl: true })
        .then(() => {
          window.location.reload();
        });
    }, 5000);
  }
}
