<div class="body" style="max-height: 600px;">
  <div class="row">
    <div class="col-md-12">
      <div class="white-box" id="print-section">
        <h3>
          <b>{{languagePharmacy.iv_invoice | translate}}</b>
          <span class="float-end">#{{data.ordeR_Id}}</span>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="float-start">
              <address>
                <h4>{{languagePharmacy.iv_cusinfo | translate}}</h4>
                <div class="row">
                  <!-- <div class="col-xl-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 col-7">
                    <img src="{{urlPath2021}}{{userOrder.user_Pic}}" alt="logo" class="logo-default" width="200" height="250"/>
                  </div> -->
                  <div class="col-xl-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-12">
                    <p class="text-muted" style="margin-left: 15px;">
                      {{userOrder.user_Name}} , {{userOrder.user_Address}}, <br> {{userOrder.user_Subdistrict}}, <br>
                      {{userOrder.user_District}}, <br> {{userOrder.user_Province}} - {{userOrder.user_Postalcode}}
                    </p>
                  </div>
                </div>
              </address>
            </div>
            <div class="float-end text-end">
              <address>
                <h4>{{languagePharmacy.iv_pharinfo | translate}}</h4>
                <!-- <img src="{{urlPath}}/{{userPhar.result.phaR_Image}}" alt="logo" class="logo-default" width="100" /> -->
                <p class="font-bold addr-font-h4" style="line-height: 10px;padding-top: 10px;">
                  {{userPhar.result.phaR_Name}}</p>
                <p class="text-muted m-l-30">
                  {{userPhar.result.phaR_Address}},{{userPhar.result.phaR_Subdistrict}},
                  <br> {{userPhar.result.phaR_District}},
                  <br> {{userPhar.result.phaR_Province}} - {{userPhar.result.phaR_Postalcode}}
                  <br> {{languagePharmacy.iv_email | translate}}: {{userPhar.result.phaR_Email}}
                  <br> {{languagePharmacy.iv_phone | translate}}: {{userPhar.result.phaR_Phone}}
                </p>
                <!-- <p>
                  <b>Status :</b>
                  <span class="label label-success">Success</span>
                  </p> -->
              </address>
            </div>
          </div>
          <div class="col-md-12">
            <div class="table-responsive m-t-30">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th>{{languagePharmacy.iv_name | translate}}</th>
                    <th>{{languagePharmacy.iv_medname | translate}}</th>
                    <th>{{languagePharmacy.iv_use | translate}}</th>
                    <th>{{languagePharmacy.iv_qty | translate}}</th>
                    <th>{{languagePharmacy.iv_price | translate}}</th>
                    <th>{{languagePharmacy.iv_total | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let order of data.orderList;let i=index;">
                    <td class="text-center">{{i+1}}</td>
                    <td>{{order.medC_.medC_Name}}</td>
                    <td>{{order.medC_.medC_MedicalName}}</td>
                    <td>{{order.medC_.medC_HowToUse}}</td>
                    <td>{{order.qty}} รายการ</td>
                    <td>฿ {{order.medC_.medC_Price}}</td>
                    <td>฿ {{order.medC_.medC_Price * order.qty }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12">
            <div class="float-end m-t-30 text-end">
              <p>{{languagePharmacy.iv_amount | translate}}: $ 0</p>
              <p>{{languagePharmacy.iv_discount | translate}} : $ 0 </p>
              <p>{{languagePharmacy.iv_vat | translate}} (10%) : $0 </p>
              <hr>
              <h3><b>{{languagePharmacy.iv_total | translate}} :</b> $ {{data.recpT_TotalPrice}}</h3>
            </div>
            <div class="clearfix"></div>
            <hr>
            <!-- <div class="text-end" style="margin-bottom: 10px;">
              <button mat-flat-button color="primary"><i class="fas fa-print"></i>
                Print</button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="text-end" style="margin-bottom: 20px;">
        <button mat-flat-button color="primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint><i
            class="fas fa-print"></i>
          {{languagePharmacy.iv_print | translate}}</button>
      </div>
    </div>
  </div>
</div>