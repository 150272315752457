import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { Pharmacy } from "./pharmacy.model";
import { GlobalConstants } from "../common/global-constants";
@Injectable({
  providedIn: "root",
})
export class PharmacyService extends UnsubscribeOnDestroyAdapter {
  //Http header
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  AddMedicineCateId_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/InsertMedicineCateId`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetCategoryMedicine_() {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/GetCategoryMedicine`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteCategoryMedicine_(idCategory: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/DeleteCategoryMedicine?idCategory=${idCategory}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetProvincePharmacys_() {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetProvinceDropdownSelect`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDistrictPharmacys_(idprovince: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetDistrictDropdownSelect?idDistrict=${idprovince}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  GetSubDistrictPharmacys_(idsubDistrict: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetSubDistrictDropdownSelect?idSubDistrict=${idsubDistrict}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  private readonly API_URL = "assets/data/doc-appointments.json";
  isTblLoading = true;
  dataChange: BehaviorSubject<Pharmacy[]> = new BehaviorSubject<Pharmacy[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {
    super();
  }
  get data(): Pharmacy[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllAppointmentss(): void {
    this.subs.sink = this.httpClient.get<Pharmacy[]>(this.API_URL).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }
  addAppointments(pharmacy: Pharmacy): void {
    this.dialogData = pharmacy;

    /*  this.httpClient.post(this.API_URL, appointments).subscribe(data => {
      this.dialogData = appointments;
      },
      (err: HttpErrorResponse) => {
     // error code here
    });*/
  }
  updateAppointments(pharmacy: Pharmacy): void {
    this.dialogData = pharmacy;

    /* this.httpClient.put(this.API_URL + appointments.id, appointments).subscribe(data => {
      this.dialogData = appointments;
    },
    (err: HttpErrorResponse) => {
      // error code here
    }
  );*/
  }
  deleteAppointments(id: number): void {
    // console.log(id);

    /*  this.httpClient.delete(this.API_URL + id).subscribe(data => {
      // console.log(id);
      },
      (err: HttpErrorResponse) => {
         // error code here
      }
    );*/
  }

  AddDrug_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/InsertMedicine`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDrugList_(idPharmacy: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/GetMedicineAndCategoryByPharmacy?idPharmacy=${idPharmacy}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteMedicine_(idMedicine: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/DeleteMedicine?idMedicine=${idMedicine}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateDrugList_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/medicine/UpdateMedicine`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  UpdatePharmacys_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/UpdatePharmacy`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetUserByID_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/user/GetUserByID?idUser=${idUser}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetUserList_(dataID: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/user/GetUserList`;
      this.httpClient
        .post(API_URL, dataID, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UploadIMGChatPharmacy_(data: FormData) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UploadImgBackOffice`;
      this.httpClient
        .post(API_URL, data, { responseType: "text" })
        // this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UploadIMGProfileDrug_(data: FormData) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UploadImgBackOffice`;
      this.httpClient
        .post(API_URL, data, { responseType: "text" })
        // this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetOldHistoryChat(myMultiUser: string, topTimeStamp: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/get_old_chat_hist/${myMultiUser}/${topTimeStamp}`;
      // console.log("API_URL ", API_URL);

      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPharmacysList_(idPharmacy: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetPharmacyList?idPharmacy=${idPharmacy}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetOrderDruglist_(idPharmacy: string, dateStart: string, dateEnd: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/order/GetOrderPhamacy?idPhamacy=${idPharmacy}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetOrderPhamacyRequestAll_(idPharmacy: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/order/GetOrderPhamacyRequestAll?idPhamacy=${idPharmacy}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetOrderUnReadPharmacy_(idPharmacy: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetOrderUnReadPharmacy?idPharmacy=${idPharmacy}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetBankPhar_(idPhar: string, type: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/GetBank?ids=${idPhar}&type=${type}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  AddBankPhar_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/InsertBankOrUpdate`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  DeleteBankOrPhar_(idBank: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/bank/DeleteBank?idBank=${idBank}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateTrackingNo_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/receipt/TrackingNoUpdate`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateTransfer_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/receipt/TransferUpdateStatus`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetLastHistoryChatlist(idDoctor: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/chat_list/${idDoctor}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetHistoryChat(
    myMultiUser: string,
    getIDmyuser: string,
    FrienduserID: string
  ) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/get_chat_hist/${myMultiUser}/${getIDmyuser}/${FrienduserID}`;
      // console.log("API_URL ", API_URL);

      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPhamacyTotalIncome_(dateID: string, idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetIncomePhamarcy?type=${dateID}&idPhamarcy=${idUser}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  InsertAndUpdatePhamarcyConversation_(dateCallEnd: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/InsertAndUpdatePhamarcyConversation`;
      this.httpClient
        .post(API_URL, dateCallEnd, {
          headers: this.httpHeaders,
          responseType: "text",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UpdateLocationPhamacy_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/UpdateLatLngPharmacy`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetRaitingPhamacy_(converId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetPhamarcyRaiting?PharConvoID=${converId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPhamarcyIncomelastMonthCompareCurrentMonth_(converId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetPhamarcyIncomelastMonthCompareCurrentMonth?PharConvoID=${converId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPhamarcyTopTenMedicineAll_(status: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetPhamarcyTopTenMedicineAll?statusSelect=${status}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetPhamarcyTopTenMedicine_(idPharmacy: any, status: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/pharmacy/GetPhamarcyTopTenMedicine?pharConvoID=${idPharmacy}&statusSelect=${status}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
