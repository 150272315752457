<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">{{languagePharmacy.dashboard | translate}}</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a href="javascript:void(0)">
                <i class="fas fa-home"></i> {{languagePharmacy.home | translate}}</a>
            </li>
            <li class="breadcrumb-item active">{{languagePharmacy.dashboard | translate}}</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Total Report -->

    <div class="dashboard-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="card-header">
            <h3 class="text-center mt-2 pt-3 pb-3">
              <strong>{{languagePharmacy.totalreport | translate}}</strong>
            </h3>
          </div>
        </div>
      </div>
      <div *ngIf="!ttLoading">
        <div class="row pt-4">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="card p-4">
              <div class="d-flex">
                <div class="me-auto">
                  <h4 class="font-weight-light col-green">{{languagePharmacy.totalincome | translate}}</h4>
                  <p class="mb-2" style="font-size: 17px;">
                    {{languagePharmacy.totaldrugsalesrevenuetotaled | translate}}
                    <strong>{{ numMoneyAll | number }}</strong> {{languagePharmacy.baht | translate}}
                  </p>
                </div>
                <div class="ms-auto pt-3">
                  <h3>฿{{ numMoneyAll | number }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="card p-4">
              <div class="d-flex">
                <div class="me-auto">
                  <h4 class="font-weight-light col-orange">{{languagePharmacy.totalorder | translate}}</h4>
                  <p class="mb-2">
                    {{languagePharmacy.orderdrugsalesrevenuetotaled | translate}}
                    <strong>{{ countOrderAll | number }}</strong> {{languagePharmacy.order | translate}}
                  </p>
                </div>
                <div class="ms-auto pt-3">
                  <h3>{{ countOrderAll | number }} {{languagePharmacy.order | translate}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <div class="card-inner" *ngIf="rating != null">
                <li class="patient-group-list">
                  <div class="d-flex">
                    <h5>
                      {{languagePharmacy.rating | translate}}
                      <strong>{{datatranslate == "th" ? dateThai : dateEng}}</strong>
                    </h5>
                  </div>
                  <div class="ms-auto" style="padding-top: 8px">
                    <div class="stars">
                      <i [class]="
                          rating.raitingMonthScore1 == 0
                            ? 'far fa-star col-orange'
                            : rating.raitingMonthScore1 > 0 &&
                              rating.raitingMonthScore1 < 1
                            ? 'fas fa-star-half-alt  col-orange'
                            : 'fas fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore1 > 1 &&
                          rating.raitingMonthScore1 < 2
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore1 >= 2
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore1 > 2 &&
                          rating.raitingMonthScore1 < 3
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore1 >= 3
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore1 > 3 &&
                          rating.raitingMonthScore1 < 4
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore1 >= 4
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore1 > 4 &&
                          rating.raitingMonthScore1 < 5
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore1 >= 5
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <span class="font ps-2 fs-5">
                        ({{ rating.raitingMonthCount1 }} {{languagePharmacy.ratings | translate}})
                      </span>
                    </div>
                  </div>
                </li>
              </div>
            </div>
            <div class="card p-4">
              <div class="card-inner" *ngIf="rating != null">
                <li class="patient-group-list">
                  <div class="d-flex">
                    <h5>
                      {{languagePharmacy.rating | translate}}
                      <strong>{{datatranslate == "th" ? dateThai1 : dateEng1}}</strong>
                    </h5>
                  </div>
                  <div class="ms-auto" style="padding-top: 8px">
                    <div class="stars">
                      <i [class]="
                          rating.raitingMonthScore2 == 0
                            ? 'far fa-star col-orange'
                            : rating.raitingMonthScore2 > 0 &&
                              rating.raitingMonthScore2 < 1
                            ? 'fas fa-star-half-alt  col-orange'
                            : 'fas fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore2 > 1 &&
                          rating.raitingMonthScore2 < 2
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore2 >= 2
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore2 > 2 &&
                          rating.raitingMonthScore2 < 3
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore2 >= 3
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore2 > 3 &&
                          rating.raitingMonthScore2 < 4
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore2 >= 4
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <i [class]="
                          rating.raitingMonthScore2 > 4 &&
                          rating.raitingMonthScore2 < 5
                            ? 'fas fa-star-half-alt  col-orange'
                            : rating.raitingMonthScore2 >= 5
                            ? 'fas fa-star  col-orange'
                            : 'far fa-star  col-orange'
                        "></i>
                      <span class="font ps-2 fs-5">
                        ({{ rating.raitingMonthCount2 }} {{languagePharmacy.ratings | translate}})
                      </span>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-3" *ngIf="incomelastMonthCompareCurrentMonth != null">
              <div class="recent-report__chart">
                <apx-chart [series]="chartSemi1Data" [title]="chartSemi.title" [chart]="chartSemi.chart"
                  [plotOptions]="chartSemi.plotOptions" [labels]="chartSemi1Month" [fill]="chartSemi.fill"></apx-chart>
              </div>
              <div class="country-chart">
                <div class="d-flex justify-content-between mt-3 ps-2 pe-2">
                  <div class="chart-note">
                    <h5>
                      {{languagePharmacy.totalincome | translate}}
                      <span class="fw-bold">{{datatranslate == "th" ? dateThai : dateEng}}</span>
                    </h5>
                  </div>
                  <div class="card-content msl-4 mb-3">
                    <h5 class="m-b-0 d-flex">
                      ฿{{ incomelastMonthCompareCurrentMonth.totalPriceAll1 | number }}
                      <i *ngIf="
                          succesAll1 == 'succesAll' &&
                          succesAll2 == 'NotsuccesAll'
                        " class="material-icons col-green psr-2">trending_up</i>
                      <i *ngIf="
                          succesAll1 == 'NotsuccesAll' &&
                          succesAll2 == 'succesAll'
                        " class="material-icons col-orange psr-2">trending_down</i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-3" *ngIf="incomelastMonthCompareCurrentMonth != null">
              <div class="recent-report__chart">
                <apx-chart [series]="chartSemi2Data" [title]="chartSemi2.title" [chart]="chartSemi2.chart"
                  [plotOptions]="chartSemi2.plotOptions" [labels]="chartSemi2Month" [fill]="chartSemi2.fill">
                </apx-chart>
              </div>
              <div class="country-chart">
                <div class="d-flex justify-content-between mt-3 ps-2 pe-2">
                  <div class="chart-note">
                    <h5>
                      {{languagePharmacy.totalincome | translate}}
                      <span class="fw-bold">{{datatranslate == "th" ? dateThai1 : dateEng1}}</span>
                    </h5>
                  </div>
                  <div class="card-content msl-4 mb-3">
                    <h5 class="m-b-0 d-flex">
                      ฿{{ incomelastMonthCompareCurrentMonth.totalPriceAll2 | number }}
                      <i *ngIf="
                          succesAll1 == 'NotsuccesAll' &&
                          succesAll2 == 'succesAll'
                        " class="material-icons col-green psr-2">trending_up</i>
                      <i *ngIf="
                          succesAll1 == 'succesAll' &&
                          succesAll2 == 'NotsuccesAll'
                        " class="material-icons col-orange psr-2">trending_down</i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="ttLoading" class="card" style="background-color: white;height: 500px;">
        <div style="margin: auto;text-align: center;">
          <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
          <p>Loading...</p>
        </div>
      </div>
    </div>

    <!-- Latest income Report -->

    <div class="dashboard-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="card-header">
            <h3 class="text-center mt-2 pt-3 pb-3">
              <strong>{{languagePharmacy.latestincomereport | translate}}</strong>
            </h3>
          </div>
        </div>
      </div>
      <div *ngIf="!ttLoading">
        <div class="row pt-4">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <h2>฿{{ numMoneyDay | number }}</h2>
              <h4 class="font-weight-light col-green">{{languagePharmacy.totaltoday | translate}}</h4>
              <p class="mb-2" style="font-size: 17px;">
                {{languagePharmacy.totalsalesofthe | translate}} <Strong>{{datatranslate == "th" ? dateNowThai :
                  dateNowEng}}</Strong> {{languagePharmacy.are | translate}}
                <strong>{{ numMoneyDay | number }}</strong> {{languagePharmacy.bath | translate}}
              </p>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <h2>฿{{ numMoneyWeek | number }}</h2>
              <h4 class="font-weight-light col-blue">{{languagePharmacy.totalweek | translate}}</h4>
              <p class="mb-2" style="font-size: 17px;">
                {{languagePharmacy.totalsalesofthe | translate}} <strong>{{languagePharmacy.weekly |
                  translate}}</strong> {{languagePharmacy.are | translate}}
                <strong>{{ numMoneyWeek | number }}</strong>
                {{languagePharmacy.bath | translate}}
              </p>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <h2>฿{{ numMoneyMonth | number }}</h2>
              <h4 class="font-weight-light col-orange">{{languagePharmacy.totalmonth | translate}}</h4>
              <p class="mb-2" style="font-size: 17px;">
                {{languagePharmacy.totalsalesofthe | translate}}
                <strong>{{datatranslate == "th" ? dateThai : dateEng}}</strong> {{languagePharmacy.are | translate}}
                <strong>{{ numMoneyMonth | number }}</strong> {{languagePharmacy.bath | translate}}
              </p>
            </div>
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="card">
              <h5 class="text-center p-4">{{languagePharmacy.barcharttotalincome | translate}}</h5>
              <!-- <div class="col-12 text-center pb-4">
                <div class="preloader">
                  <div class="spinner-layer pl-black">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="p-3" style="text-align: center">
                <apx-chart [series]="barChartTotelIncome.series" [chart]="barChartTotelIncome.chart"
                  [dataLabels]="barChartTotelIncome.dataLabels" [plotOptions]="barChartTotelIncome.plotOptions"
                  [yaxis]="barChartTotelIncome.yaxis" [xaxis]="barChartTotelIncome.xaxis"
                  [fill]="barChartTotelIncome.fill" [title]="barChartTotelIncome.title"
                  [colors]="barChartTotelIncome.colors"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="ttLoading" class="card" style="background-color: white;height: 500px;">
        <div style="margin: auto;text-align: center;">
          <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
          <p>Loading...</p>
        </div>
      </div>
    </div>

    <!-- Case Pharmacy Report -->

    <div class="dashboard-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="card-header">
            <h3 class="text-center mt-2 pt-3 pb-3">
              <strong>{{languagePharmacy.recentorderreport | translate}}</strong>
            </h3>
          </div>
        </div>
      </div>
      <div *ngIf="!ttLoading">
        <div class="row pt-4">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <h2>{{ countOrderDay | number }} {{languagePharmacy.order | translate}}</h2>
              <h4 class="font-weight-light col-green">{{languagePharmacy.ordertoday | translate}}</h4>
              <p class="mb-2" style="font-size: 17px;">
                {{languagePharmacy.totalsalesofthe | translate}}
                <Strong>{{datatranslate == "th" ? dateNowThai : dateNowEng}}</Strong> {{languagePharmacy.are |
                translate}}
                <strong>{{ countOrderDay | number }}</strong> {{languagePharmacy.order | translate}}
              </p>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <h2>{{ countOrderWeek | number }} {{languagePharmacy.order | translate}}</h2>
              <h4 class="font-weight-light col-blue">{{languagePharmacy.orderweek | translate}}</h4>
              <p class="mb-2" style="font-size: 17px;">
                {{languagePharmacy.totalsalesofthe | translate}} <strong>{{languagePharmacy.weekly |
                  translate}}</strong> {{languagePharmacy.are | translate}}
                <strong>{{ countOrderWeek | number }}</strong> {{languagePharmacy.order | translate}}
              </p>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card p-4">
              <h2>{{ countOrderMonth | number }} {{languagePharmacy.order | translate}}</h2>
              <h4 class="font-weight-light col-orange">{{languagePharmacy.ordermonth | translate}}</h4>
              <p class="mb-2" style="font-size: 17px;">
                {{languagePharmacy.totalsalesofthe | translate}}
                <strong>{{datatranslate == "th" ? dateThai : dateEng}}</strong> {{languagePharmacy.are | translate}}
                <strong>{{ countOrderMonth | number }}</strong> {{languagePharmacy.order | translate}}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="card">
              <h5 class="text-center p-4">{{languagePharmacy.barchattotalorder | translate}}</h5>
              <!-- <div class="col-12 text-center pb-4">
                <div class="preloader">
                  <div class="spinner-layer pl-black">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="p-3" style="text-align: center">
                <apx-chart [series]="barChartOrderAll.series" [chart]="barChartOrderAll.chart"
                  [dataLabels]="barChartOrderAll.dataLabels" [plotOptions]="barChartOrderAll.plotOptions"
                  [yaxis]="barChartOrderAll.yaxis" [xaxis]="barChartOrderAll.xaxis" [fill]="barChartOrderAll.fill"
                  [title]="barChartOrderAll.title" [colors]="barChartOrderAll.colors"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="ttLoading" class="card" style="background-color: white;height: 500px;">
        <div style="margin: auto;text-align: center;">
          <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
          <p>Loading...</p>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>
              <strong>{{languagePharmacy.topmedicineitems | translate}}</strong>
            </h2>
            <!-- <button mat-button [matMenuTriggerFor]="menu" class="header-dropdown">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Action</button>
              <button mat-menu-item>Another action</button>
              <button mat-menu-item>Something else here</button>
            </mat-menu> -->
          </div>
          <div class="body" *ngIf="!ttLoading">
            <div class="recent-comment" style="position: relative; max-height: 330px;" [perfectScrollbar]>
              <div class="notice-board" *ngFor="let item of dataTopTenMore; let i=index">
                <div class="notice-board no-border">
                  <div class="table-img">
                    {{i+1}}<img class="notice-object" src="{{urlPath}}/{{item.img}}" alt="..."
                      onerror="this.src='assets/images/404/404error_1.jpg'">
                  </div>
                  <div class="notice-body">
                    <h6 class="notice-heading color-info col-green">{{item.data.dataMedicine.medC_Name}}</h6>
                    <p style="white-space: nowrap;width: 150px;overflow: hidden;text-overflow: ellipsis;">
                      {{item.data.dataMedicine.medC_Description}}</p>
                    <div class="comment-date">({{languagePharmacy.sell | translate}}<strong> {{item.data.dataQty}}
                      </strong>{{languagePharmacy.pieces | translate}})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="ttLoading" class="body" style="background-color: white;height: 300px;">
            <div style="margin: auto;text-align: center;">
              <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card">
          <div class="header">
            <h2>
              <strong>{{languagePharmacy.topunpopulardrugs | translate}}</strong>
            </h2>
            <!-- <button mat-button [matMenuTriggerFor]="menu" class="header-dropdown">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Action</button>
              <button mat-menu-item>Another action</button>
              <button mat-menu-item>Something else here</button>
            </mat-menu> -->
          </div>
          <div class="body" *ngIf="!ttLoading">
            <div class="recent-comment" style="position: relative; max-height: 330px;" [perfectScrollbar]>
              <div class="notice-board" *ngFor="let item of dataTopTenLess; let i=index ">
                <div class="notice-board no-border">
                  <div class="table-img">
                    {{i+1}}<img class="notice-object" src="{{urlPath}}/{{item.img}}" alt="..."
                      onerror="this.src='assets/images/404/404error_1.jpg'">
                  </div>
                  <div class="notice-body">
                    <h6 class="notice-heading color-info col-green">{{item.data.dataMedicine.medC_Name}}</h6>
                    <p style="white-space: nowrap;width: 150px;overflow: hidden;text-overflow: ellipsis;">
                      {{item.data.dataMedicine.medC_Description}}</p>
                    <div class="comment-date">({{languagePharmacy.sell | translate}}<strong> {{item.data.dataQty}}
                      </strong>{{languagePharmacy.pieces | translate}})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="ttLoading" class="body" style="background-color: white;height: 300px;">
            <div style="margin: auto;text-align: center;">
              <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start distributor Report-->
    <!-- <div class="dashboard-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="card-header">
            <h3 class="text-center mt-2 pt-3 pb-3">
              <strong>Package Report</strong>
            </h3>
          </div>
        </div>
      </div>
      <div *ngIf="!ttLoading">
        <div class="row pt-4">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="card p-4">
              <div class="d-flex">
                <div class="me-auto">
                  <h4 class="font-weight-light col-green">จำนวนแพ็กเกจที่มีคนซื้อ</h4>
                  <p class="mb-2">
                    จำนวนแพ็กเกจที่มีคนซื้อทั้งหมด 10 แพ็กเกจ
                  </p>
                </div>
                <div class="ms-auto pt-3">
                  <h3>10 แพ็กเกจ</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="card p-4">
              <div class="d-flex">
                <div class="me-auto">
                  <h4 class="font-weight-light col-green">จำนวนแพ็กเกจที่เหลือ</h4>
                  <p class="mb-2">
                    จำนวนแพ็กเกจที่เหลืออยู่ทั้งหมด 50 แพ็กเกจ
                  </p>
                </div>
                <div class="ms-auto pt-3">
                  <h3>50 แพ็กเกจ</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="row clearfix">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2>
                <strong>รายการแพ็กเกจ</strong>
              </h2>
            </div>
            <div class="body" *ngIf="!ttLoading">
              <div class="recent-comment" style="position: relative; max-height: 330px;" [perfectScrollbar]>
                <div class="notice-board" *ngFor="let item of dataTopTenMore; let i=index">
                  <div class="me-auto" (click)="OpenList()">

                    <div class="notice-body">
                      <div class="notice-board no-border">
                        <div class="table-img">
                        {{i+1}}<img class="notice-object" src="{{urlPath}}/{{item.img}}" alt="..."
                        onerror="this.src='assets/images/imgdoctor/nouser.svg'">
                        </div>
                        &nbsp;
                      <h6 class="notice-heading color-info col-orange"> แพ็กเกจส่วนลดร้านค้า 20 %</h6>
                      </div>
                      <div style="margin-top: 5px;">
                        <span class="badge rounded-pill" style="background-color: silver;">Sliver</span>&nbsp;
                        <span class="badge rounded-pill" style="background-color: gold;">Gold</span>&nbsp;
                        <span class="badge rounded-pill bg-primary">Platinum</span>&nbsp;
                      </div>
                    </div>
                  </div>
                  <div class="ms-auto pt-3">
                    <p class="msl-4 mb-2">
                    จำนวน 2 แพ็กเกจ
                  </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="ttLoading" class="body" style="background-color: white;height: 300px;">
              <div style="margin: auto;text-align: center;" >
                <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
                <p>Loading...</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2>
                <strong>รายชื่อผู้ซื้อแพ็กเกจ</strong>
              </h2>
            </div>
            <div class="body" *ngIf="!ttLoading">
              <div class="recent-comment" style="position: relative; max-height: 330px;" [perfectScrollbar]>
                <div class="notice-board" *ngFor="let item of dataTopTenLess; let i=index ">
                  <div class="notice-board no-border">
                    <div class="table-img">
                      {{i+1}}<img class="notice-object" src="{{urlPath}}/{{item.img}}" alt="..."
                        onerror="this.src='assets/images/imgdoctor/nouser.svg'">
                    </div>
                    <div class="notice-body">
                      <h6 class="notice-heading color-info col-orange">หัตถยา บำรุงสุข</h6>
                      <div class="comment-date">(จำนวน 2 แพ็กเกจ)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="ttLoading" class="body" style="background-color: white;height: 300px;">
              <div style="margin: auto;text-align: center;" >
                <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
                <p>Loading...</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      <div *ngIf="ttLoading" class="card" style="background-color: white;height: 500px;">
        <div style="margin: auto;text-align: center;" >
          <img src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" width="300">
          <p>Loading...</p>
        </div>
      </div>
    </div> -->
    <!-- End distributor Report-->

    <!-- Start Sub distributor Report-->

    <!-- End Sub distributor Report-->

  </div>
</section>