<div class="header">
    <h4>
        <strong>{{languageItemsAdminAllPharmacy.editPayment | translate}}</strong>
    </h4>
</div>
<div class="body">
    <form [formGroup]="doctorForm" (ngSubmit)="submit">
        <div class="row">
            <div class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mb-2">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>{{languageItemsAdminAllPharmacy.bankName | translate}}</mat-label>
                    <mat-select required [(value)]="selectedbankNamedoc" [(value)]="selectedBankNamedoc" (selectionChange)="onChangeBank(selectedbankNamedoc)">
                        <mat-option *ngFor="let bankname of banks" [value]="bankname.value">
                            <img [src]="bankname.viewImg" style="border-radius: 50%;width: 30px;height: 30px;object-fit: cover;" /> &nbsp;&nbsp;{{bankname.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{languageItemsAdminAllPharmacy.cardCode16Digits | translate}}</mat-label>
                    <input matInput #message maxlength="16" type="number" required formControlName="bankNo" (input)="onSearchChangeCard($event.target.value)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                    <mat-icon matSuffix>payment</mat-icon>
                    <mat-error *ngIf="doctorForm.get('bankNo').hasError('required')">
                        {{languageItemsAdminAllPharmacy.cardCodeIsRequired | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{languageItemsAdminAllPharmacy.accountName | translate}}</mat-label>
                    <input matInput required formControlName="AccountName" (input)="onSearchChangeName($event.target.value)">
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <mat-error *ngIf="doctorForm.get('AccountName').hasError('required')">
                        {{languageItemsAdminAllPharmacy.accountNameIsRequired | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 mb-2">
                <button mat-raised-button color="primary" [disabled]="!accId || !accName" style="background-color:#51B1A5;width: 80px;margin-left: 10px;" (click)="confirmUpdate()">{{languageItemsAdminAllPharmacy.add | translate}}</button>
            </div>
        </div>
    </form>
</div>