import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "src/app/common/global-constants";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "src/app/pharmacy/interface-pharmacy/language-item-pharmacy";
import { Drug } from "../../drug.model";

@Component({
  selector: "app-view-form-drug-list",
  templateUrl: "./view-form-drug-list.component.html",
  styleUrls: ["./view-form-drug-list.component.scss"],
})
export class ViewFormDrugListComponent implements OnInit {
  viewDrugListForm: FormGroup;
  druglists: Drug;
  isDetails: boolean;
  dialogTitle: string;
  appointmentsForm: any;
  action: string;
  appointments: any;
  imgDrugLists: Array<string> = [];
  imgDrugListsOld: string = "";
  imgF: string = "";
  noImg: boolean = true;
  urlPath = GlobalConstants.REST_API_TELE;
  languagePharmacy: any;

  constructor(
    public dialogRef: MatDialogRef<ViewFormDrugListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    if (data.druglists != undefined && data.druglists.medcImage != "") {
      this.imgDrugLists = data.druglists.medcImage.split(",");
      this.imgF = this.imgDrugLists[0];
      this.imgDrugListsOld = data.druglists.medcImage;
    }

    this.action = data.action;
    if (this.action === "details") {
      this.druglists = data.druglists;
      this.isDetails = true;
    } else {
      this.isDetails = false;
      this.dialogTitle = "New Appointments";
      this.druglists = new Drug({});
      this.appointmentsForm = this.createContactForm();
    }
  }
  createContactForm(): FormGroup {
    return this.fb.group({
      medcCaution: [this.druglists.medcCaution],
      medcDescription: [this.druglists.medcDescription],
      medcDiscount: [this.druglists.medcDiscount],
      medcExpirationDate: [this.druglists.medcExpirationDate],
      medcHowToUse: [this.druglists.medcHowToUse],
      medcImage: [this.druglists.medcImage],
      medcMedicalName: [this.druglists.medcHowToUse],
      medcName: [this.druglists.medcName],
      medcPrice: [this.druglists.medcPrice],
      medcQty: [this.druglists.medcQty],
      medcUnit: [this.druglists.medcUnit],
    });
  }
  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
  }

  imgClicked(img: string) {
    this.imgF = img;
  }
}
