<div class="form-group" style="margin-top: 10px;">
  <h4 style="margin-bottom: 20px;">
    <strong>{{languageItemsAdminAllPharmacy.locationsettings | translate}}</strong>
  </h4>
  <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
    placeholder="   {{languageItemsAdminAllPharmacy.search | translate}}" autocorrect="off" autocapitalize="off"
    spellcheck="off" type="text" #search>
</div>
<div class="row">
  <div class="col-xl-9">
    <h5>{{address}}</h5>
    <div>
      <i class="fas fa-map-marker-alt"></i>
      &nbsp;&nbsp; {{languageItemsAdminAllPharmacy.latitude | translate}}: <b>{{latitude}}</b>
      , {{languageItemsAdminAllPharmacy.longitude | translate}}: <b>{{longitude}}</b>
    </div>
    <br>
  </div>
  <div class="col-xl-3">
    <button mat-raised-button *ngIf="adminRole != 'AdminViewOnly'"
      style="background-color: #51B1A5;color: white;float: right;"
      (click)="openDialogUpdateLocation()">{{languageItemsAdminAllPharmacy.updatelocation | translate}}</button>
  </div>
</div>
<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
  <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
  </agm-marker>
</agm-map>
