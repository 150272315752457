<div class="addContainer">
  <div class="modalHeader" style="padding: 0px;">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="fw-bold p-t-5 font-17">{{languageItems.editadmin | translate}}</div>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form class="mt-2" [formGroup]="admin">
    <div class="row mt-4">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{languageItems.username | translate}}</mat-label>
          <input matInput formControlName="username"
            oninput="this.value = this.value.replace(/^\s+|\s+$|\s+(?=\s)/g, '');">
          <mat-icon matSuffix>account_circle</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{languageItems.enteryourpassword | translate}}
          </mat-label>
          <input matInput [type]="hidePassword1 ? 'password' : 'text'" minlength="6" formControlName="password"
            oninput="this.value = this.value.replace(/^\s+|\s+$|\s+(?=\s)/g, '');">
          <button mat-icon-button matSuffix (click)="hidePassword1 = !hidePassword1" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword1">
            <mat-icon>{{hidePassword1 ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{languageItems.confirmpassword | translate}}</mat-label>
          <input matInput [type]="hidePassword2 ? 'password' : 'text'" formControlName="passwordConfirm">
          <button mat-icon-button matSuffix (click)="hidePassword2 = !hidePassword2" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword2">
            <mat-icon>{{hidePassword2 ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <mat-dialog-actions align="end" style="margin-bottom: -10px;">
      <button mat-button mat-raised-button color="primary" (click)="putAdmin()" [disabled]="admin.invalid">Save</button>
      <button mat-button mat-raised-button color="warn" (click)="dialogRef.close()" tabindex="-1"
        class="me-3">Cancel</button>
    </mat-dialog-actions>
  </form>
</div>