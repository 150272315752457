import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "src/app/pharmacy/interface-pharmacy/language-item-pharmacy";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import { OrderDrugListModel } from "../../order-drug-list.model";

@Component({
  selector: "app-edit-code-dialog",
  templateUrl: "./edit-code-dialog.component.html",
  styleUrls: ["./edit-code-dialog.component.scss"],
})
export class EditCodeDialogComponent implements OnInit {
  loading = false;
  recpNo: any;
  shippingCompany: any;
  recpTrackingNo: any;
  orderForm: FormGroup;
  orderDrugList: OrderDrugListModel;
  selectedParcelTracking = "option1";
  languagePharmacy: any;
  constructor(
    public dialogRef: MatDialogRef<EditCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public pharmacyService: PharmacyService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    this.recpNo = data.recpT_No;
    this.recpTrackingNo = data.recpT_TrackingNo;
    if (
      data.recpT_ShippingCompany != null &&
      data.recpT_ShippingCompany != ""
    ) {
      this.shippingCompany = data.recpT_ShippingCompany;
    } else {
      this.shippingCompany = "บริษัทขนส่ง ไปรษณีย์ไทย";
    }

    this.orderDrugList = new OrderDrugListModel({});

    this.orderForm = this.createContactForm();
  }

  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);

  createContactForm(): FormGroup {
    return this.fb.group({
      trackingNo: [this.recpTrackingNo],
      shippingCompany: [this.shippingCompany],
    });
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
  }

  public async confirmUpdateTrackingNo(idNo: string): Promise<void> {
    this.loading = true;
    var dataTrackingSuccess: any;
    var dataUpdatePhamacy = {
      RECPT_No: idNo,
      RECPT_TrackingNo: this.orderForm.getRawValue().trackingNo,
      RECPT_ShippingCompany: this.orderForm.getRawValue().shippingCompany,
    };

    this.pharmacyService
      .UpdateTrackingNo_(dataUpdatePhamacy)
      .then((resTracking) => {
        dataTrackingSuccess = resTracking;

        if (dataTrackingSuccess.status == true) {
          this.dialogRef.close(dataUpdatePhamacy);

          this.loading = false;
        }
      });
  }
}
