import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { AppointmentsService } from "src/app/doctor/appointments/appointments.service";
import Swal from "sweetalert2";
import { LanguageHeader } from "../../interface-language/language-item-header";

@Component({
  selector: "app-time-start-dialog",
  templateUrl: "./time-start-dialog.component.html",
  styleUrls: ["./time-start-dialog.component.scss"],
})
export class TimeStartDialogComponent implements OnInit {
  id: string;
  errorEndDate: boolean = false;
  endDateAfter: boolean = false;
  workForm: FormGroup;
  dateStart: any;
  dateEnd: any;
  dataGetTimeWork: any;
  dataTimeWork: any;
  public min = new Date();
  public max;
  timeNow = new Date();
  roleUser: string;
  languageWorking: any;
  checkBank: boolean = true;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TimeStartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public workTimeService: AppointmentsService,
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    this.timeNow.setHours(23, 59);
    this.max = new Date(this.timeNow);

    this.dataGetTimeWork = data;
    if (this.dataGetTimeWork.statusUpdate == "update") {
      this.dateStart = new Date(this.dataGetTimeWork.worK_time_start);
      this.dateEnd = new Date(this.dataGetTimeWork.worK_time_end);
    } else {
      const myDate = new Date();
      myDate.setHours(myDate.getHours() + 3);
      this.dateStart = new Date();
      this.dateEnd = new Date(myDate);
    }

    this.workForm = this.createContactForm();
    var dataLocal = localStorage.getItem("currentUser");

    if (dataLocal != null) {
      var data_ = JSON.parse(dataLocal);
      this.id = data_.id;
      this.roleUser = data_.role;
    }
  }

  ngOnInit(): void {
    this.languageWorking = LanguageHeader[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
    this.getTimeWork(this.id);
    this.getBank();
  }

  createContactForm(): FormGroup {
    if (this.dataTimeWork != null) {
      this.dateStart = new Date(this.dataGetTimeWork.worK_time_start);
      this.dateEnd = new Date(this.dataGetTimeWork.worK_time_end);
    } else {
      const myDate = new Date();
      myDate.setHours(myDate.getHours() + 3);
      this.dateStart = new Date();
      this.dateEnd = new Date(myDate);
    }
    return this.fb.group({
      end: [this.dateEnd],
    });
  }
  getTimeWork(idDoc: any) {
    if (this.roleUser == "Doctor") {
      this.workTimeService.GetWorkByDrId_(idDoc).then((resAddTime) => {
        this.dataTimeWork = resAddTime;
      });
    } else if (this.roleUser == "Nutritionist") {
      this.workTimeService
        .GetWorkByNutritionistId_(idDoc)
        .then((resAddTime) => {
          this.dataTimeWork = resAddTime;
        });
    }
  }

  getBank() {
    var role: string;
    if (this.roleUser == "Doctor") {
      role = "doctor";
    } else if (this.roleUser == "Nutritionist") {
      role = "nutritionist";
    }
    this.workTimeService.GetBankDoctor_(this.id, role).then((res) => {
      var success: any = res;
      if (success.status == false) {
        this.checkBank = false;
      }
    });
  }

  AddTimeStart(status: string) {
    if (status == "doc") {
      var dateTimeNow = moment(this.dateStart).format("llll");
      var newEndDate = moment(this.workForm.getRawValue().end).format("llll");

      var dataAddWorkTime = {
        dR_Id: this.id,
        worK_time_start: dateTimeNow,
        worK_time_end: newEndDate,
        worK_Status: true,
      };

      if (this.workForm.getRawValue().end == null) {
        this.endDateAfter = false;
        this.errorEndDate = true;
      } else if (new Date() > new Date(this.workForm.getRawValue().end)) {
        this.errorEndDate = false;
        this.endDateAfter = true;
      } else {
        if (this.checkBank != false) {
          this.workTimeService.AddTime_(dataAddWorkTime).then((resAddTime) => {
            var dataResuleWorkTime = {
              dR_Id: dataAddWorkTime.dR_Id,
              worK_Id: resAddTime,
              worK_Status: dataAddWorkTime.worK_Status,
              worK_time_start: dataAddWorkTime.worK_time_start,
              worK_time_end: dataAddWorkTime.worK_time_end,
              status: true,
              type: "add",
              dataResule: dataAddWorkTime,
            };
            this.dialogRef.close(dataResuleWorkTime);
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "กรุณาติดต่อแอดมินเพื่อทำการเพิ่มบัญชีธนาคาร ก่อนเปิดรับงาน",
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
      }
    } else {
      var dateTimeNowNut = moment(this.dateStart).format("llll");
      var newEndDateNut = moment(this.workForm.getRawValue().end).format(
        "llll"
      );

      var dataAddWorkTimeNut = {
        nttnId: this.id,
        workTimeStart: dateTimeNowNut,
        workTimeEnd: newEndDateNut,
        workStatus: true,
      };

      var dataAddWorkTimeNutEnd = {
        nttN_Id: this.id,
        worK_time_start: dateTimeNowNut,
        worK_time_end: newEndDateNut,
        worK_Status: true,
      };

      if (this.workForm.getRawValue().end == null) {
        this.endDateAfter = false;
        this.errorEndDate = true;
      } else if (new Date() > new Date(this.workForm.getRawValue().end)) {
        this.errorEndDate = false;
        this.endDateAfter = true;
      } else {
        if (this.checkBank != false) {
          this.workTimeService
            .AddTimeNutritionist_(dataAddWorkTimeNut)
            .then((resAddTimeNut) => {
              var success: any = resAddTimeNut;
              if (success.msg == "Success Process") {
                var WorkTimeNutritionist = {
                  nttN_Id: dataAddWorkTimeNut.nttnId,
                  worK_Id: success.workId,
                  worK_Status: dataAddWorkTimeNut.workStatus,
                  worK_time_start: dataAddWorkTimeNut.workTimeStart,
                  worK_time_end: dataAddWorkTimeNut.workTimeEnd,
                  status: true,
                  type: "add",
                  dataResule: dataAddWorkTimeNutEnd,
                };
                this.dialogRef.close(WorkTimeNutritionist);
              }
            });
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "กรุณาติดต่อแอดมินเพื่อทำการเพิ่มบัญชีธนาคาร ก่อนเปิดรับงาน",
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
      }
    }
  }
  UpdateTimeStart() {
    if (this.roleUser == "Doctor") {
      var dateTimeNow = moment(this.dateStart).format("llll");
      var newEndDate = moment(this.workForm.getRawValue().end).format("llll");

      var dataUpdateWorkTime = {
        worK_Id: this.dataTimeWork.worK_Id,
        dR_Id: this.id,
        worK_time_start: dateTimeNow,
        worK_time_end: newEndDate,
        worK_Status: true,
      };

      if (this.workForm.getRawValue().end == null) {
        this.endDateAfter = false;
        this.errorEndDate = true;
      } else if (new Date() > new Date(this.workForm.getRawValue().end)) {
        this.errorEndDate = false;
        this.endDateAfter = true;
      } else {
        this.workTimeService
          .UpdateTime_(dataUpdateWorkTime)
          .then((resUpdateTime) => {
            var dataResuleWorkTime = {
              worK_time_start: dataUpdateWorkTime.worK_time_start,
              worK_time_end: dataUpdateWorkTime.worK_time_end,
              worK_Status: dataUpdateWorkTime.worK_Status,
              status: true,
              type: "update",
              dataResule: dataUpdateWorkTime,
            };
            this.dialogRef.close(dataResuleWorkTime);
          });
      }
    } else if (this.roleUser == "Nutritionist") {
      var dateTimeNow = moment(this.dateStart).format("llll");
      var newEndDate = moment(this.workForm.getRawValue().end).format("llll");

      var dataUpdateWorkTimeNut = {
        workId: this.dataTimeWork.worK_Id,
        nttnId: this.id,
        workTimeStart: dateTimeNow,
        workTimeEnd: newEndDate,
        workStatus: true,
      };
      var dataUpdateWorkTimeNutEnd = {
        worK_Id: this.dataTimeWork.worK_Id,
        nttN_Id: this.id,
        worK_time_start: dateTimeNow,
        worK_time_end: newEndDate,
        worK_Status: true,
      };
      if (this.workForm.getRawValue().end == null) {
        this.endDateAfter = false;
        this.errorEndDate = true;
      } else if (new Date() > new Date(this.workForm.getRawValue().end)) {
        this.errorEndDate = false;
        this.endDateAfter = true;
      } else {
        this.workTimeService
          .UpdateTimeNutritionist_(dataUpdateWorkTimeNut)
          .then((resUpdateTime) => {
            var dataResuleWorkTime = {
              worK_time_start: dataUpdateWorkTimeNut.workTimeStart,
              worK_time_end: dataUpdateWorkTimeNut.workTimeEnd,
              worK_Status: dataUpdateWorkTimeNut.workStatus,
              status: true,
              type: "update",
              dataResule: dataUpdateWorkTimeNutEnd,
            };
            this.dialogRef.close(dataResuleWorkTime);
          });
      }
    }
  }

  stopTimeWork() {
    this.workTimeService
      .DeleteWorkByDrId_(this.dataGetTimeWork.worK_Id)
      .then((resDeleteTime) => {
        this.dialogRef.close(false);
      });
  }
  stopTimeWorkNut() {
    this.workTimeService
      .DeleteWorkByNutritionistId_(this.dataGetTimeWork.worK_Id)
      .then((resDeleteTime) => {
        this.dialogRef.close(false);
      });
  }
}
