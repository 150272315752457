import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DoctorProfileService } from "src/app/admin/doctors/doctor-profile/doctor-profile.service";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { AppointmentsService } from "src/app/doctor/appointments/appointments.service";
import { LanguageHeader } from "../../interface-language/language-item-header";

@Component({
  selector: "app-chang-password-dialog",
  templateUrl: "./chang-password-dialog.component.html",
  styleUrls: ["./chang-password-dialog.component.scss"],
})
export class ChangPasswordDialogComponent implements OnInit {
  hideNewP = true;
  hideConP = true;
  id: string;
  userName: string;
  role: string;
  passNew: any;
  PassCon: any;
  languageWorking: any;

  passWord = new FormGroup({
    passwordNew: new FormControl(),
    passwordConF: new FormControl(),
  });
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ChangPasswordDialogComponent>,
    public doctorService: AppointmentsService,
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    var dataLocal = localStorage.getItem("currentUser");
    var data_ = JSON.parse(dataLocal);
    this.id = data_.id;
    this.role = data_.role;
    this.userName = data_.username;
  }

  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError("required")
      ? "Required field"
      : this.formControl.hasError("email")
      ? "Not a valid email"
      : "";
  }

  ngOnInit(): void {
    this.languageWorking = LanguageHeader[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
  }

  addNewPassword() {
    this.passNew = this.passWord.value.passwordNew;
    this.PassCon = this.passWord.value.passwordConF;
    if (this.role == "Doctor") {
      if (this.passNew == this.PassCon) {
        var dataPasswordDoctor = {
          DR_Id: this.id,
          DR_Username: this.userName,
          DR_Password: this.passNew,
        };
        this.doctorService
          .UpdateDoctorPass_(dataPasswordDoctor)
          .then((resAddDoctor) => {
            this.dialogRef.close(true);
          });
      }
    } else if (this.role == "Pharmacy") {
      if (this.passNew == this.PassCon) {
        var dataPasswordPharmacy = {
          PHAR_Id: this.id,
          PHAR_Username: this.userName,
          PHAR_Password: this.passNew,
        };

        this.doctorService
          .UpdatePharmacyPass_(dataPasswordPharmacy)
          .then((resAddPharmacy) => {
            // console.log("dataPasswordPharmacy",resAddPharmacy);
            this.dialogRef.close(true);
          });
      }
    } else if (this.role == "Nutritionist") {
      if (this.passNew == this.PassCon) {
        var dataPasswordNutritionist = {
          nttnId: this.id,
          nttnPassword: this.passNew,
        };
        this.doctorService
          .UpdateNutritionistPass_(dataPasswordNutritionist)
          .then((resAddPharmacy) => {
            // console.log("dataPasswordPharmacy",resAddPharmacy);
            this.dialogRef.close(true);
          });
      }
    }
  }

  logOut() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("onesignal-allow");
    localStorage.removeItem("doctorTimeWork");
    localStorage.removeItem("showDialogWorkTime");
    // localStorage.setItem("showDialogWorkTime", "0");
    this.router
      .navigate([`/authentication/signout/${this.id}`], { replaceUrl: true })
      .then(() => {
        window.location.reload();
      });
  }
}
