import { formatDate } from "@angular/common";
export class Drug {
  medcId: number;
  medcImage: string;
  medcName: string;
  medcMedicalName: string;
  medcDiscount: string;
  medcPrice: string;
  medcQty: string;
  medcUnit: string;
  medcDescription: string;
  medcCaution: string;
  medcHowToUse: string;
  medcExpirationDate: string;
  Medicines: string;
  medcatId: string;

  constructor(appointments) {
    {
      this.medcId = appointments.medcId || 0;
      this.medcImage =
        appointments.medcImage || "assets/images/user/usrbig1.jpg";
      this.medcName = appointments.medcName || "";
      this.medcMedicalName = appointments.medcMedicalName || "";
      this.medcDiscount = appointments.medcDiscount || "";
      this.medcPrice = appointments.medcPrice || "";
      this.medcQty = appointments.medcQty || "";
      this.medcUnit = appointments.medcUnit || "";
      this.medcDescription = appointments.medcDescription || "";
      this.medcCaution = appointments.medcCaution || "";
      this.medcHowToUse = appointments.medcHowToUse || "";
      this.medcExpirationDate = appointments.medcExpirationDate || "";
      this.Medicines = appointments.Medicines || "";
      this.medcatId = appointments.medcatId || "";
    }
  }
}
