import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./../authentication/page404/page404.component";

import { DrugListComponent } from "./drug-list/drug-list.component";
import { OrderListComponent } from "./order-list/order-list.component";
import { PharmacyChatComponent } from "./pharmacy-chat/pharmacy-chat.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SettingsComponent } from "./settings/settings.component";
import { PharmacyTotalIncomeComponent } from "./pharmacy-total-income/pharmacy-total-income.component";
import { PackageComponent } from "./package/package.component";

const routes: Routes = [
  {
    path: "dashboard/:idPhamacy",
    component: DashboardComponent,
  },
  {
    path: "drug-list/:idPhamacy",
    component: DrugListComponent,
  },
  {
    path: "order-drug-list/:idPhamacy",
    component: OrderListComponent,
  },
  {
    path: "pharmacy-chat/:idPhamacy",
    component: PharmacyChatComponent,
  },
  {
    path: "settings/:idPhamacy",
    component: SettingsComponent,
  },
  {
    path: "total-income/:idPhamacy",
    component: PharmacyTotalIncomeComponent,
  },
  {
    path: "package/:idPhamacy",
    component: PackageComponent,
  },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PharmacyRoutingModule { }
