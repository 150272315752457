import { formatDate } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "src/app/common/global-constants";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "src/app/pharmacy/interface-pharmacy/language-item-pharmacy";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import Swal from "sweetalert2";
import { Drug } from "../../drug.model";

interface Medicine {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-form-drug-list",
  templateUrl: "./form-drug-list.component.html",
  styleUrls: ["./form-drug-list.component.scss"],
})
export class FormDrugListComponent implements OnInit {
  urlPath = GlobalConstants.REST_API_TELE;
  action: string;
  dialogTitle: string;
  drugListForm: FormGroup;
  duplicateUsername = false;

  noImg: boolean = true;
  drugs: Drug;
  medicines: Medicine[] = [];
  dataMedicinePharmacys: any;
  idPharmacy: string;
  imgDrugLists: Array<string> = [];
  imgDrugListsOld: string = "";
  selectmedicine: number = 0;

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];

  checkIsSelectImg: boolean = false;
  imgOld: any;
  languagePharmacy: any;
  checkBank: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<FormDrugListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public drugService: PharmacyService,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    // console.log("data ", data);
    this.translate.use(localStorage.getItem("lang"));
    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);

    this.idPharmacy = dataLocalJSON.id;

    //set img
    if (data.druglists != undefined && data.druglists.medcImage != "") {
      this.imgDrugLists = data.druglists.medcImage.split(",");
      this.imgDrugListsOld = data.druglists.medcImage;
    }

    this.action = data.action;
    if (this.action === "edit") {
      this.dialogTitle = data.druglists;
      this.drugs = data.druglists;
      this.selectmedicine = data.druglists.medcatId;
    } else {
      this.dialogTitle = "New Drug";
      this.drugs = new Drug({});
    }
    this.drugListForm = this.createContactForm();
  }
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  getErrorMessage() {
    return this.formControl.hasError("required")
      ? "Required field"
      : this.formControl.hasError("email")
      ? "Not a valid email"
      : "";
  }
  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
    });
    this.dataMedicine();
    this.getBank();
    if (this.action === "edit") {
      // this.medicineID = this.data.pharmacys.idProvince;
    }
  }

  createContactForm(): FormGroup {
    return this.fb.group({
      img: [this.drugs.medcImage],
      Name: [this.drugs.medcName],
      MedicalName: [this.drugs.medcMedicalName],
      Discount: [this.drugs.medcDiscount],
      Price: [this.drugs.medcPrice],
      Qty: [this.drugs.medcQty],
      Unit: [this.drugs.medcUnit],
      Description: [this.drugs.medcDescription],
      Caution: [this.drugs.medcCaution],
      HowToUse: [this.drugs.medcHowToUse],
      ExpirationDate: [this.drugs.medcExpirationDate],
      Medicines: [this.drugs.medcatId],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getBank() {
    this.drugService.GetBankPhar_(this.idPharmacy, "pharmacy").then((res) => {
      var success: any = res;
      if (success.status == false) {
        this.checkBank = false;
      }
    });
  }
  public async confirmAdd(): Promise<void> {
    // console.log('ข้อมูลที่ได้-->', this.drugListForm.getRawValue());
    // this.doctorsService.addDoctors(this.doctorsForm.getRawValue());
    var dataAddDrugSuccess: any;

    var img: any = "";

    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append("FormFiles", this.selectedFiles[i]);
        formData.append("TypeUpload", "drug");

        await this.drugService
          .UploadIMGProfileDrug_(formData)
          .then((resUploadIMG) => {
            if (img != "") {
              img = img + `,${resUploadIMG}`;
            } else {
              img = resUploadIMG;
            }
          });
      }
    }
    // console.log(">: ",img.split(','));
    // console.log("img --> ", img);

    var dataAddDrug = {
      PHAR_Id: this.idPharmacy,
      MEDC_Image: img,
      MEDCAT_Id: this.drugListForm.getRawValue().Medicines,
      MEDC_Name: this.drugListForm.getRawValue().Name,
      MEDC_MedicalName: this.drugListForm.getRawValue().MedicalName,
      MEDC_Discount: this.drugListForm.getRawValue().Discount,
      MEDC_Price: this.drugListForm.getRawValue().Price,
      MEDC_Qty: this.drugListForm.getRawValue().Qty,
      MEDC_Unit: this.drugListForm.getRawValue().Unit,
      MEDC_Description: this.drugListForm.getRawValue().Description,
      MEDC_Caution: this.drugListForm.getRawValue().Caution,
      MEDC_HowToUse: this.drugListForm.getRawValue().HowToUse,
      MEDC_ExpirationDate: formatDate(
        this.drugListForm.getRawValue().ExpirationDate,
        "MM/dd/yyyy",
        this.locale
      ),
    };

    if (this.checkBank != false) {
      this.drugService.AddDrug_(dataAddDrug).then((resAddDoctor) => {
        dataAddDrugSuccess = resAddDoctor;
        if (dataAddDrugSuccess.msg == "Insert Success") {
          var addDataSuccess = {
            pharId: this.idPharmacy,
            medcId: this.drugListForm.getRawValue().Medicines,
            medcImage: img,
            medcName: this.drugListForm.getRawValue().Name,
            medcMedicalName: this.drugListForm.getRawValue().MedicalName,
            medcDiscount: this.drugListForm.getRawValue().Discount,
            medcPrice: this.drugListForm.getRawValue().Price,
            medcQty: this.drugListForm.getRawValue().Qty,
            medcUnit: this.drugListForm.getRawValue().Unit,
            medcDescription: this.drugListForm.getRawValue().Description,
            medcCaution: this.drugListForm.getRawValue().Caution,
            medcHowToUse: this.drugListForm.getRawValue().HowToUse,
            medcExpirationDate: formatDate(
              this.drugListForm.getRawValue().ExpirationDate,
              "MM/dd/yyyy",
              this.locale
            ),
          };

          this.dialogRef.close(addDataSuccess);
        }
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "กรุณาติดต่อแอดมินเพื่อทำการเพิ่มบัญชีธนาคาร ก่อนเพิ่มสินค้า",
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }
  public async confirmUpdate(): Promise<void> {
    var dataAddDrugSuccess: any;
    const formData = new FormData();
    var img: any = "";
    img = this.imgDrugListsOld;
    // console.log("img ---------> ", img);

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append("FormFiles", this.selectedFiles[i]);
        formData.append("TypeUpload", "drug");

        await this.drugService
          .UploadIMGProfileDrug_(formData)
          .then((resUploadIMG) => {
            if (img != "") {
              img = img + `,${resUploadIMG}`;
            } else {
              img = resUploadIMG;
            }
          });
      }
    }

    var dataUpdateDrug = {
      MEDC_Id: this.data.druglists.medcId,
      PHAR_Id: this.idPharmacy,
      MEDC_Image: img,
      CheckSelectImg: this.checkIsSelectImg,
      MEDCAT_Id: this.drugListForm.getRawValue().Medicines,
      MEDC_Name: this.drugListForm.getRawValue().Name,
      MEDC_MedicalName: this.drugListForm.getRawValue().MedicalName,
      MEDC_Discount: this.drugListForm.getRawValue().Discount,
      MEDC_Price: this.drugListForm.getRawValue().Price,
      MEDC_Qty: this.drugListForm.getRawValue().Qty,
      MEDC_Unit: this.drugListForm.getRawValue().Unit,
      MEDC_Description: this.drugListForm.getRawValue().Description,
      MEDC_Caution: this.drugListForm.getRawValue().Caution,
      MEDC_HowToUse: this.drugListForm.getRawValue().HowToUse,
      MEDC_ExpirationDate: formatDate(
        this.drugListForm.getRawValue().ExpirationDate,
        "MM/dd/yyyy",
        this.locale
      ),
      // console.log("dataUpdatePharmacys ", dataUpdatePharmacys);
    };
    // console.log("dataUpdateDrug ", dataUpdateDrug);

    this.drugService.UpdateDrugList_(dataUpdateDrug).then((resAddDrug) => {
      dataAddDrugSuccess = resAddDrug;
      // console.log("dataAddDrugSuccess ", dataAddDrugSuccess);

      if (dataAddDrugSuccess.status == true) {
        var updateDataSuccess = {
          pharId: dataUpdateDrug.PHAR_Id,
          medcId: dataUpdateDrug.MEDC_Id,
          medcName: dataUpdateDrug.MEDC_Name,
          medcMedicalName: dataUpdateDrug.MEDC_MedicalName,
          medcDiscount: dataUpdateDrug.MEDC_Discount,
          medcPrice: dataUpdateDrug.MEDC_Price,
          medcQty: dataUpdateDrug.MEDC_Qty,
          medcUnit: dataUpdateDrug.MEDC_Unit,
          medcDescription: dataUpdateDrug.MEDC_Description,
          medcCaution: dataUpdateDrug.MEDC_Caution,
          medcHowToUse: dataUpdateDrug.MEDC_HowToUse,
          medcExpirationDate: this.drugListForm.getRawValue().ExpirationDate,
          medcImage: dataUpdateDrug.MEDC_Image,
          medcatId: dataUpdateDrug.MEDCAT_Id,
        };

        this.dialogRef.close(updateDataSuccess);
      }
    });
  }

  @ViewChild("fileInput")
  myInputVariable: ElementRef;

  onClickClearImg() {
    this.myInputVariable.nativeElement.value = "";

    this.noImg = true;
    this.message = [];
    this.progressInfos = [];
    this.previews = [];
    this.selectedFiles = null;
    this.imgDrugListsOld = "";
    this.imgDrugLists = null;
    this.checkIsSelectImg = true;
  }

  dataMedicine() {
    this.drugService.GetCategoryMedicine_().then((resdataMedicine) => {
      this.dataMedicinePharmacys = resdataMedicine;
      // console.log("dataMedicinePharmacys",this.dataMedicinePharmacys);
      for (let i = 0; i < this.dataMedicinePharmacys.length; i++) {
        this.medicines.push({
          value: this.dataMedicinePharmacys[i].medcaT_Id,
          viewValue: `${this.dataMedicinePharmacys[i].medcaT_NameTH} , ${this.dataMedicinePharmacys[i].medcaT_NameEN}`,
        });
      }
    });
  }

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    // console.log("event ", this.selectedFiles.length);
    // console.log("event", this.selectedFiles);

    if (this.selectedFiles != null) {
      this.checkIsSelectImg = true;
      this.noImg = false;
      this.message = [];
      this.progressInfos = [];
      this.previews = [];
    }

    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }
}
