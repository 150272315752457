<div class="addContainer">
  <div class="table-responsive">
    <div class="contact-details">
      <div class="modalHeader" style="margin-bottom: 20px;">
        <div class="contact-details-heading">
          <div class="contact-details-name mb-2">{{languagePharmacy.transferstatus | translate}}</div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 mb-2" style="text-align: center;">
              <img style="object-fit: cover;max-height: 550px; min-height: 200px; max-width:330px"
                src="{{REST_API_BANK}}/{{slipImage}}" onerror="this.src='assets/images/404/404error_2.png'" />
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 mb-2" style="display: flex;justify-content: center;">
              <div class="col-xs-2 v-divider"></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-2">
              <h4 style="color: rgb(18, 109, 0);text-align: center;">{{languagePharmacy.amounttobepaid | translate}}
              </h4>
              <p style="font-size: 20px;text-align: center;">
                <b>฿&nbsp;&nbsp;{{amount}}.0</b>
              </p>

              <h5>{{languagePharmacy.allbankaccounts | translate}}</h5>
              <div class="row" *ngFor="let dataBankOrder of dataBankByIdPharAll">
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารกรุงเทพ'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/bk.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารกรุงไทย'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/ktb-bank-logo-big.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารกรุงศรีอยุธยา'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/ks.png" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารกสิกรไทย'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/logo-kbank.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารเกียรตินาคิน'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/kkn.png" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารซีไอเอ็มบี'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/cmb.png" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารทหารไทย'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/tmb_logo.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารทิสโก้'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/tisco.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารไทยพาณิชย์'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/scb.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารธนชาต'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/tnc.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารนครหลวงไทย'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/nkl.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารยูโอบี'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/uob.jpg" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารสแตนดาร์ดชาร์เตอร์ด'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/std.png" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารไอซีบีซี'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/icbc.png" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
                <li class="report-list" *ngIf="dataBankOrder.banK_Name == 'ธนาคารออมสิน'">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="text-align: center;">
                    <img src="assets/images/bank/download.png" style="width: 70px;" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="media-title font-12">{{languagePharmacy.accountnumber | translate}} :
                      <b>{{dataBankOrder.banK_No}}</b>
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.bank | translate}} :
                      {{dataBankOrder.banK_Name}}
                    </div>
                    <div class="media-title font-12">{{languagePharmacy.accountname | translate}} :
                      {{dataBankOrder.banK_AccountName}}
                    </div>
                  </div>
                </li>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <button mat-raised-button type="submit" class="example-full-width" color="primary"
                    style="height: 55px;"
                    (click)="confirmUpdateTransfer(dataTransfer.element.dataReceipt.recpT_No)">{{languagePharmacy.transfersuccessfully
                    | translate}}</button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <button mat-raised-button type="submit" class="example-full-width" (click)="viewChangeTransfer(true)"
                    style="height: 55px;background-color: rgb(197, 8, 8);color: white;">{{languagePharmacy.failedtransfer
                    | translate}}</button>
                </div>
                <div
                  *ngIf="dataTransfer.element.dataReceipt.recpT_TransferStutus != null && dataTransfer.element.dataReceipt.recpT_TransferStutus != true ">
                  <p style="padding-top: 20px;color: red;"> <b>* {{languagePharmacy.note | translate}}
                    </b>{{dataTransfer.element.dataReceipt.recpT_TransferNote}}</p>
                </div>
              </div>
              <div class="row" *ngIf="failedTransfer == true">
                <div class="col-xl-10 col-lg-8 col-md-8 col-sm-8 col-8">
                  <form [formGroup]="paymentForm">
                    <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 30px;">
                      <mat-label>{{languagePharmacy.note | translate}}</mat-label>
                      <textarea matInput formControlName="transferNote" required></textarea>
                      <!-- <textarea matInput [(value)]="dataTransfer.element.dataReceipt.recpT_TransferNote" formControlName="transferNote" required></textarea> -->
                    </mat-form-field>
                  </form>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4" style="padding-top: 30px;">
                  <button mat-raised-button color="primary" [disabled]="!paymentForm.valid" [type]="submit"
                    (click)="confirmUpdateTransfer(dataTransfer.element.dataReceipt.recpT_No)">{{languagePharmacy.record
                    | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>